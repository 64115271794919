import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ComposeSubscriberService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getMailListings(userId, accessToken, csrfToken) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'newsletter_listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  composeNewMailSend(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("mail_subject", formData.mailSubject);
    fd.append("mail_message", formData.mailBody);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'newsletter_add', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
