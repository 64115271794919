import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  createBankDetails(userId, accessToken, csrfToken, vendorId, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("bank_name", formData.bankName);
    fd.append("branch_name", formData.branchName);
    fd.append("ifsc_code", formData.ifscCode);
    fd.append("account_number", formData.accountNumber);
    fd.append("signature_source_image", formData.signatureImage.source_img);
    fd.append("signature_cropped_image", formData.signatureImage.cropped_img);
    fd.append("signature_crop_data", JSON.stringify(formData.signatureImage.crop_data));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-bank-details-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editBankDetailsPageData(userId, accessToken, csrfToken, gymOwnerId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", gymOwnerId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-bank-details-edit-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editBankDetails(userId, accessToken, csrfToken, vendorId, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("bank_name", formData.bankName);
    fd.append("branch_name", formData.branchName);
    fd.append("ifsc_code", formData.ifscCode);
    fd.append("account_number", formData.accountNumber);
    fd.append("signature_source_image", formData.signatureImage.source_img);
    fd.append("signature_cropped_image", formData.signatureImage.cropped_img);
    fd.append("signature_crop_data", JSON.stringify(formData.signatureImage.crop_data));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-bank-details-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
