import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateCompetitionService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  editCompetition(userId, accessToken, csrfToken, competitionId, addEditFormgroup) {
    // console.log(addFormData.pageDescription);
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("competition_id", competitionId);
    fd.append("category_id", addEditFormgroup.category);
    fd.append("title", addEditFormgroup.title);
    fd.append("description", addEditFormgroup.description);
    fd.append("todo_list", addEditFormgroup.toDoList);
    fd.append("selection_criteria", addEditFormgroup.selectionCriteria);
    fd.append("is_published", addEditFormgroup.isPublish);
    fd.append("image_data", JSON.stringify(addEditFormgroup.competitionImage));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'competition-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editPageDetails(userId, accessToken, csrfToken, competitionId) {
    let fd = new FormData();

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("competition_id", competitionId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'competition-edit-page-details', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getPageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'competition-create-page-details', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  create(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("category_id", formData.category);
    fd.append("title", formData.title);
    fd.append("description", formData.description);
    fd.append("todo_list", formData.toDoList);
    fd.append("selection_criteria", formData.selectionCriteria);
    fd.append("image_data", JSON.stringify(formData.competitionImage));
    fd.append("is_published", formData.isPublish);    
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'competition-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
