import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountsManagementService } from './accounts-management.service'
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { resolve } from 'url';

@Component({
  selector: 'app-accounts-management',
  templateUrl: './accounts-management.component.html',
  styleUrls: ['./accounts-management.component.scss']
})
export class AccountsManagementComponent implements OnInit {
  
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  // public totalData: number = 0;
  public deductionAmnt: number = 0;
  public actualAmntToReturn: number = 0;
  public searchCriteriaChanged: boolean = true;

  public totalData: number = 1;

  public accountManagementsListingData: any = [];
  public accountsManagementModalData: any = [];
  public searchFormGroup: FormGroup = this.formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'publishStatus': ['0', [Validators.required]]
  });
  public editPaymentStatusFormGroup: FormGroup = this.formBuilder.group({  
    'requestId': ['0', [Validators.required]],
    'paymentStatus': [ 0, [Validators.required]],
    'bankTransactionId': ['', [this.confirmTransIdValidation]],
    'confirmBankTransactionId': ['', [this.confirmTransIdValidation]],
  });

  constructor(private _accountManagementsService: AccountsManagementService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService) { }

  confirmTransIdValidation(control: FormControl) {
    if (typeof control.parent !== 'undefined') {
      if (control.value == '') {
        return { errorMessage: 'This field is mandatory' };
      }
      else if (control.parent.controls['bankTransactionId'].value != control.parent.controls['confirmBankTransactionId'].value) {
        // control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
        return { errorMessage: 'This field is mandatory' };
        // return null;
      }else if (control.parent.controls['bankTransactionId'].value == control.parent.controls['confirmBankTransactionId'].value) {
        // control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
        control.parent.controls['confirmBankTransactionId'].setErrors(null);
        control.parent.controls['bankTransactionId'].setErrors(null);
        return null;
      }
      else {
        control.parent.controls['confirmAccountNumber'].setErrors(null);
        return null;
      }
    }
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      let userTitles = JSON.parse(localStorage.getItem("userTitles"));
      // this.userTitle = userTitles;
      this.getAccountManagementsListingDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  changePaidStatusFunc() {
    this._emitter.emit('showLoader');
    this._accountManagementsService.changePaidStatus(this._userId, this._accessToken, this._csrfToken, this.editPaymentStatusFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          // msg: this.appVariables.applicationStatusChangedSuccess
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this.accountManagementsListingData = resp.balance_listings.data;
        this.totalData = resp.balance_listings.data.total;
        this.dataPerPage = resp.balance_listings.data.data_per_page;        

        this.numberOfPages = (resp.balance_listings.data.total % resp.balance_listings.data.data_per_page != 0) ? parseInt((resp.balance_listings.data.total / resp.balance_listings.data.data_per_page).toString()) + 1 : resp.balance_listings.data.total / resp.balance_listings.data.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        console.log("resp-",resp)
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.paymentStatusChangedSuccess
        });
        this.modalRef.hide();
        // alert("Payment details submitted successful and invoice sent to the vendor.")
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.paymentStatusChangedError
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getAccountManagementsListingDataFunc() {
    this._emitter.emit('showLoader');
    this._accountManagementsService.getAccountManagementsData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber )
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if (resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if (resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if (resp.status == 204) {
        this.accountManagementsListingData = [];
      }
      else if (resp.status == 200) {
        this.accountManagementsListingData = resp.data;
        this.totalData = resp.total;
        this.dataPerPage = resp.data_per_page;        

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        //console.log(this.pageData);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if (err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  adminDeductionAmnt(totalAmt,deductionPercentage){
   let deductionBalance = ((totalAmt*deductionPercentage)/100)
   return deductionBalance;
  }

  accountManagementEditData(template: TemplateRef<any>) {
    // this.editApplicationFormGroup.controls["applicationId"].setValue(this.applicationDetailsModalData.application_id);
    // this.editApplicationFormGroup.controls["paymentStatus"].setValue(this.applicationDetailsModalData.approve_status);
    this.editPaymentStatusFormGroup.controls["requestId"].setValue(this.accountsManagementModalData.request_id);
    this.deductionAmnt = this.adminDeductionAmnt(this.accountsManagementModalData.requested_to_encash,this.accountsManagementModalData.admin_deduction_percentage)
    this.actualAmntToReturn = this.accountsManagementModalData.return_to_bank_amount - this.deductionAmnt;
    // alert(this.deductionAmnt);
    
    this.modalRef = this._modalService.show(template);
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.searchFormGroup.controls['verifyStatus'].setValue('0');
    this.searchFormGroup.controls['publishStatus'].setValue('0');
    this.getAccountManagementsListingDataFunc();
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getAccountManagementsListingDataFunc();
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getAccountManagementsListingDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber + 1;
    this.getAccountManagementsListingDataFunc();
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    // this.getListingsDataFunc();
  }

}
