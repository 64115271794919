import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageDiscountCodeService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  getPageData(userId, accessToken, csrfToken, searchForm, currentPageNumber) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", '');
    fd.append("page_no", currentPageNumber);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'admin-discount-listing', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createDiscount(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("discount_percentage", formData.discountPercentage);
    fd.append("start_date", this.formatDate(formData.startDate));
    fd.append("end_date", this.formatDate(formData.endDate));
    fd.append("is_published", formData.isPublished);    
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'admin-discount-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editDiscount(userId, accessToken, csrfToken, formData, editId) {
    let fd = new FormData();    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("discount_id", editId);
    fd.append("discount_percentage", formData.discountPercentage);
    fd.append("start_date", this.formatDate(formData.startDate));
    fd.append("end_date", this.formatDate(formData.endDate));
    fd.append("is_published", formData.isPublished);   
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'admin-discount-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteDiscount(userId, accessToken, csrfToken, deleteId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("discount_id", deleteId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'admin-discount-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
