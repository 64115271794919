import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppVariables } from '../../../app.variables';
import {MyVideosService} from './my-videos.service';

@Component({
  selector: 'app-my-videos',
  templateUrl: './my-videos.component.html',
  styleUrls: ['./my-videos.component.scss']
})
export class MyVideosComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public pageData: any =[];
  public numberOfPages: number = 0;
  public deleteTranerVideoId: string = '';
  public paginationArr: any = [];
  public currentPageNumber: number = 1;
  public dataPerPage: number = 10;
  public searchText: string = '';
  public videoDeleteId :string = '';
  public detailsModalData: any = {};
  public detailsModalDataVideoUrl: any;
  public videoName = '';
  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
  });
  public videoThumnail = this.appVariables.noImageUrl;
  constructor(private _adminVideoService:MyVideosService,private _formBuilder: FormBuilder,private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter,private _modalService: BsModalService,public appVariables: AppVariables, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getadminVideoListData();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }
  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.getadminVideoListData();
  }
  deleteVideo() {
    this._adminVideoService.getVideoDelete(this._userId,this._accessToken,this._csrfToken,this.videoDeleteId,this.searchFormGroup.value.search,this.currentPageNumber).subscribe(resp=>{
      if(resp.status == 200) {
        this.modalRef.hide();
        this._emitter.emit('showAlert', {
          type: 1,
          msg: "Video Deleted Successfully!"
        });
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      } else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    })
  }
  getadminVideoListData() {
    this._emitter.emit('showLoader');
    this._adminVideoService.getPageData(this._userId, this._accessToken, this._csrfToken,this.searchFormGroup.value.search,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('hideLoader');
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  } 
  toFixed(value: string, upto = 2) {
    return parseFloat(value).toFixed(upto);
  }
  detailsModal(template: TemplateRef<any>,videoName) {
    this._adminVideoService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{
      
      this.videoThumnail = data[0].thumbnail_small;
      // console.log(data);
    });
    this.detailsModalDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.detailsModalData.video_url.split('/')[3]);
    this.videoName = videoName;
    this.modalRef = this._modalService.show(template);
    // this._myUploadedVideosService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{ 
    //   console.log(data);
    // });
    // this.videoImg(template);
  }

  // deleteConfirmed() {
  //   this._emitter.emit('showLoader');
  //   this._adminVideoService.deleteTrainerVideo(this._userId, this._accessToken, this._csrfToken, this.deleteTranerVideoId,this.searchText,this.dataPerPage,this.currentPageNumber)
  //   .subscribe((resp) => {
  //     // console.log(resp);
  //     this._emitter.emit('hideLoader');
  //     this.modalRef.hide();

  //     if(resp.status == 401) {
  //       this._router.navigateByUrl('/login');
  //     }
  //     else if(resp.status == 201) {
  //       this._emitter.emit('showAlert', {
  //         type: 2,
  //         msg: this.appVariables.someErrorOccurred
  //       });
  //     }
  //     else if(resp.status == 204) {
  //       this._emitter.emit('showAlert', {
  //         type: 1,
  //         msg: this.appVariables.customerDeletedSuccess
  //       });
  //       this.pageData = [];
  //     }
  //     else if(resp.status == 200) {
  //       this._emitter.emit('showAlert', {
  //         type: 1,
  //         msg: this.appVariables.customerDeletedSuccess
  //       });
  //       this.pageData = resp.data;
  //       this.dataPerPage = resp.data_per_page;
  //       this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
  //       this.paginationArr = this.generatePaginationArr(this.numberOfPages);
  //     }
  //     else {
  //       this._emitter.emit('showAlert', {
  //         type: 2,
  //         msg: this.appVariables.someErrorOccurred
  //       });
  //     }
  //   }, err => {
  //     if(err.status == 401) {
  //       this._router.navigateByUrl('/login');
  //     }
  //     this._emitter.emit('hideLoader');
  //     this._emitter.emit('showAlert', {
  //       type: 2,
  //       msg: this.appVariables.someErrorOccurred
  //     });
  //   });
  // }
  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getadminVideoListData();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getadminVideoListData();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getadminVideoListData();
  }


}
