import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ManageCitiesService } from './manage-cities.service'
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { resolve } from 'url';
// import { ConsoleReporter } from 'jasmine';
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-manage-cities',
  templateUrl: './manage-cities.component.html',
  styleUrls: ['./manage-cities.component.scss']
})
export class ManageCitiesComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public userTitle: any = [];
  public pageData: any = [];
  public cityData: any = [];
  public cityEditId: string = '';

  public currentStateId: string = '';
  public currentCityId: string = '';
  private _geocoder: any = new google.maps.Geocoder;
  public mapObj: any;
  public markerObj: any = [];
  public launchedCityListings: any = [];
  public cityDeleteId: string = '';
  public cityId: string = '';
  @ViewChild('sortableElem') sortableElem: ElementRef;
  @ViewChild('map') map: ElementRef;

  public defaultMapLatLng = {
    lat: 22.573927138612436,
    lng: 88.43258701427374
  };

  public createEditCitiesFormGroup: FormGroup = this.formBuilder.group({
    'state': ['', [Validators.required]],
    'city': ['', [Validators.required]],
    'productLaunch': [false, [Validators.required]],
    'latitude': ['0', [Validators.required]],
    'longitude': ['0', [Validators.required]]
  });

  constructor(private _manageCitiesService: ManageCitiesService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService) { }

  isActiveStatusChanged(activeStatus){
    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(activeStatus);    
  }

  createEditFormSubmit() {
    if (this.cityEditId != '') {
      this.editCities();
    }
    else {
      this.editCity();
    }
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      let userTitles = JSON.parse(localStorage.getItem("userTitles"));
      this.userTitle = userTitles;
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  editCity() {
    this._emitter.emit('showLoader');
    this._manageCitiesService.editCity(this._userId, this._accessToken, this._csrfToken, this.createEditCitiesFormGroup.value)
      .subscribe((resp) => {
        //console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.cityEditedSuccess
          });
          //console.log(resp);
          this.launchedCityListings = resp.data.city_launched_listings;
          // this.resetForm();
          this.cityEditId = '';
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  editCities() {
    this._emitter.emit('showLoader');
    this._manageCitiesService.editCityFunc(this._userId, this._accessToken, this._csrfToken, this.cityEditId, this.createEditCitiesFormGroup.value)
      .subscribe((resp) => {
        //console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.cityEditedSuccess
          });
          //console.log(resp);
          this.launchedCityListings = resp.data.city_launched_listings;
          //this.resetForm();
          this.cityEditId = '';
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  resetForm() {
    this.cityEditId = '';
    this.createEditCitiesFormGroup.controls['state'].setValue('');
    this.createEditCitiesFormGroup.controls['city'].setValue('');
    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(true);
    this.createEditCitiesFormGroup.controls['latitude'].setValue('');
    this.createEditCitiesFormGroup.controls['longitude'].setValue('');
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._manageCitiesService.getPageDataFunc(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if (resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if (resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if (resp.status == 204) {
        this.pageData = [];
      }
      else if (resp.status == 200) {
        this.pageData = resp.data;
        ////console.log(this.pageData);
        this.launchedCityListings = resp.data.city_launched_listings;
        //console.log(this.launchedCityListings);
        this.createEditCitiesFormGroup.controls['state'].setValue(this.pageData.state_listings[0].state_id);

        this.getUserCurrentLocation().then((position) => {
          this.getStateCityPinFromLatLng('administrative_area_level_1', position).then(stateName => {
            this.createEditCitiesFormGroup.controls['state'].setValue(this.pageData.state_listings[0].state_id);

            let position = {
              lat: this.createEditCitiesFormGroup.controls['latitude'].setValue(''),
              lng: this.createEditCitiesFormGroup.controls['longitude'].setValue('')
            };

            this.onStateChangeEvent(position);
          });
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if (err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getUserCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        }, () => {
          resolve(this.defaultMapLatLng);
        });
      } else {
        // Browser doesn't support Geolocation
        resolve(this.defaultMapLatLng);
      }
    });
  }

  onCityChangeEvent() {
    for (let data of this.launchedCityListings) {
      //console.log(this.createEditCitiesFormGroup.controls['city'].value);
      //console.log(data.city_id);
      if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat != null && data.city_lng != null && data.product_launched_status === true) {
        // console.log( 'Launchstatus' + data.product_launched_status);
        // console.log('Trues' + data.city_lat);
        // console.log('Trues' + data.city_lng);
        //this.resetForm();
        this.createEditCitiesFormGroup.controls['latitude'].setValue(data.city_lat);
        this.createEditCitiesFormGroup.controls['longitude'].setValue(data.city_lng);
        this.createEditCitiesFormGroup.controls['productLaunch'].setValue(data.product_launched_status);
        this.createEditCitiesFormGroup.controls['state'].setValue(data.state_id);
        this.createEditCitiesFormGroup.controls['city'].setValue(data.city_id);
        var position = {
          lat: data.city_lat,
          lng: data.city_lng
        };
        this.initMap(position);
        this.onStateChangeEvent(position);
      }
      else {
        //console.log('Else PART');
        // this.createEditCitiesFormGroup.controls['productLaunch'].setValue(false);
        this.getLatLngFromStateCityPin();
      }
    }
  }

  onStateChangeEvent(position) {
    //console.log('position:' + position);
    this._emitter.emit('showLoader');
    this._manageCitiesService.getCityFromState(this._userId, this._accessToken, this._csrfToken, this.createEditCitiesFormGroup.controls['state'].value)
      .subscribe((resp) => {
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.cityData = [];
        }
        else if (resp.status == 200) {
          this.cityData = resp.data;
          // console.log(this.cityData);
          // console.log(this.createEditCitiesFormGroup.controls['city'].value);
          if (this.cityEditId == '') {
            this.createEditCitiesFormGroup.controls['city'].setValue(this.cityData[0].city_id);
          }
          if (typeof position.lat !== 'undefined') {
            //console.log('121');
            this.getStateCityPinFromLatLng('administrative_area_level_2', position).then(cityName => {
              for (let data of this.cityData) {
                if (data.city_name.toLowerCase() === cityName.toString().toLowerCase()) {
                  this.createEditCitiesFormGroup.controls['city'].setValue(data.city_id);
                  this.currentCityId = data.city_id;
                  //console.log('currentcityID' + this.currentCityId);
                  break;
                }
                else if(this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat != null && data.city_lng != null && data.product_launched_status === true){
                  //console.log(this.createEditCitiesFormGroup.controls['productLaunch'].setValue(true));
                }
              }
              this.initMap(position);
            });
          }
          else if (this.cityEditId == '') {
            //console.log('Enter this function');
            this.getUserCurrentLocation().then((position) => {
              this.getStateCityPinFromLatLng('administrative_area_level_1', position).then(stateName => {
                for (let data of this.cityData) {
                  //console.log(this.createEditCitiesFormGroup.controls['city'].value);
                  //console.log(data.city_id);
                  if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat != null && data.city_lng != null && data.product_launched_status === true) {
                    var position = {
                      lat: data.city_lat,
                      lng: data.city_lng
                    };
                    this.initMap(position);
                  }
                  else if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat == null && data.city_lng == null && data.product_launched_status === false) {
                    //console.log('False');
                    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(data.product_launched_status);
                    this.getLatLngFromStateCityPin();
                  }
                  else if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat != null && data.city_lng != null && data.product_launched_status === false) {                    
                    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(false);
                    this.getLatLngFromStateCityPin();
                    // this.getUserCurrentLocation();
                  }
                }
              });
            });
          }

          else if (this.cityEditId != '') {
            //console.log('Enter this function 2nd');
            this.getUserCurrentLocation().then((position) => {
              this.getStateCityPinFromLatLng('administrative_area_level_1', position).then(stateName => {
                this.createEditCitiesFormGroup.controls['city'].setValue(this.cityData[0].city_id);

                for (let data of this.cityData) {
                  if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat != null && data.city_lng != null && data.product_launched_status === true) {
                    //console.log('lat' + data.city_lat);
                    //console.log('lng' + data.city_lng);
                    //console.log('True');
                    //console.log(data.product_launched_status);
                    this.createEditCitiesFormGroup.controls['latitude'].setValue(data.city_lat);
                    this.createEditCitiesFormGroup.controls['longitude'].setValue(data.city_lng);
                    //console.log(this.createEditCitiesFormGroup.controls['productLaunch'].setValue(data.product_launched_status));
                    var position = {
                      lat: data.city_lat,
                      lng: data.city_lng
                    };
                    this.initMap(position);
                  }
                  else if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat != null && data.city_lng != null && data.product_launched_status === false) {
                    // console.log('False');
                    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(data.product_launched_status);
                    this.getLatLngFromStateCityPin();
                  }
                  else if (this.createEditCitiesFormGroup.controls['city'].value == data.city_id && data.city_lat == null && data.city_lng == null && data.product_launched_status === false) {
                    //console.log('False1');
                    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(data.product_launched_status);
                    this.getLatLngFromStateCityPin();
                  }
                }
              });
            });
          }
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  setEditData(data) {
    this.currentStateId = data.state_id;
    this.cityEditId = data.city_id;

    this.createEditCitiesFormGroup.controls['state'].setValue(this.currentStateId);
    this.createEditCitiesFormGroup.controls['city'].setValue(data.city_id);
    this.createEditCitiesFormGroup.controls['productLaunch'].setValue(data.product_launched_status);
    this.createEditCitiesFormGroup.controls['latitude'].setValue(data.city_lat);
    this.createEditCitiesFormGroup.controls['longitude'].setValue(data.city_lng);
    var position = {
      lat: data.city_lat,
      lng: data.city_lng
    };
    this.initMap(position);
    this.onStateChangeEvent(position);
  }

  deleteAllMarkers() {
    for (let marker of this.markerObj) {
      marker.setMap(null);
    }
    this.markerObj = [];
  }

  setMapCenter(position) {
    this.deleteAllMarkers();
    this.mapObj.setCenter(position);
    this.markerObj.push(new google.maps.Marker({ position: position, map: this.mapObj }));
    this.createEditCitiesFormGroup.controls['latitude'].setValue(position['lat']);
    this.createEditCitiesFormGroup.controls['longitude'].setValue(position['lng']);
  }

  initMap(position) {
    this.mapObj = new google.maps.Map(this.map.nativeElement, {
      center: position,
      zoom: 10,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    });

    this.setMapCenter(position);

    this.mapObj.addListener('dragend', () => {
      let currentPos = this.mapObj.getCenter();
      this.setMapCenter({ lat: currentPos.lat(), lng: currentPos.lng() });
    });
  }

  getLatLngFromStateCityPin() {
    let address = '';

    if (this.createEditCitiesFormGroup.controls['state'].value != '') {
      for (let data of this.pageData.state_listings) {
        if (data.state_id == this.createEditCitiesFormGroup.controls['state'].value) {
          address = data.state_name;
          break;
        }
      }
    }
    if (this.createEditCitiesFormGroup.controls['city'].value != '') {
      for (let data of this.cityData) {
        if (data.city_id == this.createEditCitiesFormGroup.controls['city'].value) {
          address += ',' + data.city_name;
          break;
        }
      }
    }

    this._geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        let position = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        };
        if (typeof this.mapObj === 'undefined') {
          this.initMap(position);
        }
        this.setMapCenter(position);
      } else {
        // console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  getStateCityPinFromLatLng(addressType, position) {
    return new Promise((resolve, reject) => {
      this._geocoder.geocode({ 'location': position }, (results, status) => {
        if (status === 'OK') {
          for (let data of results[0].address_components) {
            if (data.types.indexOf(addressType) >= 0) {
              resolve(data.long_name);
              break;
            }
          }
        } else {
          // console.log('Geocode was not successful for the following reason: ' + status);
          resolve(this.pageData.state_listings[0].state_name);
        }
      });
    });
  }
}
