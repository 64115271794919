import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributeCreateService } from './product-attribute-create.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';

@Component({
  selector: 'app-product-attribute-create',
  templateUrl: './product-attribute-create.component.html',
  styleUrls: ['./product-attribute-create.component.css']
})
export class ProductAttributeCreateComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  formSubmit = false;

  public productAttrObj: any = {
    attributeId: null,
    attributeValue: '',
    attributeCode: '',
    isPublished: true,
  };
  public productAttrArr: any = [
    {
      attributeId: null,
      attributeValue: '',
      attributeCode: '',
      isPublished: true,
    }
  ];
  public productAttributeEditId: string = '';

  public addEditProductAttributeFormGroup: FormGroup = this._formBuilder.group({
    'productAttributeName': ['', [Validators.required]],
    'isPublished': [true, [Validators.required]]
  });

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _productAttributeService: ProductAttributeCreateService,  private _activatedRoute: ActivatedRoute, private _router: Router, @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this._activatedRoute.params.subscribe((params) => {
        console.log(params,'params');
        if(typeof params.productAttribiteId !== 'undefined' && params.productAttribiteId != '' && params.productAttribiteId != null) {
          this.productAttributeEditId = params.productAttribiteId;
          this.getEditProductAttributegetData();
        }
      })
      // this.getSiteSettingList();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  formSubmitted() {
    const isAttributeEmpty = this.productAttrArr.some(item => !item.attributeValue);

    // If any attributeValue is empty, prevent form submission
    if (isAttributeEmpty) {
      // alert('Attribute Value is required for all items.');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: "Attribute value is required!"
      });
      return false; // Prevent form submission
    }
    if(this.productAttributeEditId != '') {
      this.editProductAttributeFunc();
    }
    else {
      this.createProductAttributeFunc();
    }
  }

  getEditProductAttributegetData() {
    this._emitter.emit('showLoader');
    this._productAttributeService.editProductAttributePageData(this._userId, this._accessToken, this._csrfToken, this.productAttributeEditId)
    .subscribe((resp) => {
      console.log("attr resp",resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.productAttrArr = [];
        this.addEditProductAttributeFormGroup.controls['productAttributeName'].setValue(resp.data.attribute_name);
        this.addEditProductAttributeFormGroup.controls['isPublished'].setValue(resp.data.active_status);
        if(resp.data.sub_attribute_data.length > 0) {
          for(let data of resp.data.sub_attribute_data) {
            this.productAttrArr.push({
              attributeId: data.sub_attribute_id,
              attributeValue: data.sub_attribute_value,
              attributeCode: data.sub_attribute_code == null ? '' : data.sub_attribute_code,
              isPublished: data.is_published
            });
          }
        }
        else {
          this.productAttrArr.push(Object.assign({}, this.productAttrObj));
        }
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createProductAttributeFunc() {
    this.formSubmit = true;
    if (this.addEditProductAttributeFormGroup.invalid) {
      // The form is invalid, and the input field is empty.
      // You can display an error message or take appropriate action.
      this._emitter.emit('showAlert', {
        type: 2,
        msg: 'Form is invalid. Please fill in all required fields.'
      });
      console.log('Form is invalid. Please fill in all required fields.');
      return;
    }
    this._emitter.emit('showLoader');

    this._productAttributeService.createProductAttribute(this._userId, this._accessToken, this._csrfToken, this.addEditProductAttributeFormGroup.value, this.productAttrArr)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        // this.siteSettingListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.attributeCreateSuccess
        });
        // this.siteSettingListings = resp.data;
        // this.resetForm();
        this._router.navigateByUrl('/product-attribute-list');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editProductAttributeFunc() {
    this._emitter.emit('showLoader');
    this._productAttributeService.editProductAttribute(this._userId, this._accessToken, this._csrfToken, this.addEditProductAttributeFormGroup.value, this.productAttrArr, this.productAttributeEditId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        // this.siteSettingListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.attributeEditSuccess
        });
        this._router.navigateByUrl('/product-attribute-list');
        // this.siteSettingListings = resp.data;
        // this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  addProductVarientClicked() {
    this.productAttrArr.push(Object.assign({}, this.productAttrObj));
  }

  
  removeProductVarientClicked(index) {
    console.log("this====",this.productAttrArr[index].attributeId );

    this._productAttributeService.deletProductSubAttribute(this._userId, this._accessToken, this._csrfToken, this.productAttrArr[index].attributeId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        // this.siteSettingListings = [];
        this.productAttrArr.splice(index, 1);
        // this._emitter.emit('showAlert', {
        //   type: 2,
        //   msg: this.appVariables.someErrorOccurred
        // });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: 'Sub atttribute removed succesfully'
        });
        this.productAttrArr.splice(index, 1);
        // this.siteSettingListings = resp.data;
        // this.resetForm();
        // this._router.navigateByUrl('/product-attribute-list');
      }
      else if (resp.status==202){
        this._emitter.emit('showAlert', {
          type: 2,
          msg: 'Sub attribute is issued with a product and cannot remove'
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      // this._emitter.emit('hideLoader');
      // this._emitter.emit('showAlert', {
      //   type: 2,
      //   msg: this.appVariables.someErrorOccurred
      // });
    });
    
    // this.productAttrArr.splice(index, 1);
  }

  resetForm() {
    this.productAttributeEditId = '';
    this.addEditProductAttributeFormGroup.reset({
      isPublished: true
    });
  }

  close(){
    // console.log("vlocse");
    this._router.navigateByUrl('/product-attribute-list');
    
  }

}
