import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductAttributeCreateService {

  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  createProductAttribute(userId, accessToken, csrfToken, formData, productAttributeData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_attribute_name", formData.productAttributeName);
    fd.append("is_publish", formData.isPublished);
    fd.append("product_attribute", JSON.stringify(productAttributeData));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-attribute-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editProductAttributePageData(userId, accessToken, csrfToken, productAttributeId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("attribute_id", productAttributeId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-attribute-edit-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editProductAttribute(userId, accessToken, csrfToken, formData, productAttributeData, productAttributeId) {
    let fd = new FormData();
    console.log(productAttributeData,'productAttributeData');
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_attribute_name", formData.productAttributeName);
    fd.append("is_publish", formData.isPublished);
    fd.append("product_attribute_id", productAttributeId);
    fd.append("product_attribute", JSON.stringify(productAttributeData));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-attribute-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }




  // sub attribute changes
  deletProductSubAttribute(userId, accessToken, csrfToken, productAttributeData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    // fd.append("product_attribute_name", formData.productAttributeName);
    // fd.append("is_publish", formData.isPublished);
    fd.append("sub_attributekey", productAttributeData);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-sub-attribute-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
