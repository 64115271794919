// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // apiBaseUrl: 'http://localhost:5000/admin-api/',
  // baseUrl: 'http://localhost:5000/',

  // apiBaseUrl: 'https://www.developer-beta.com:5000/admin-api/',
  // baseUrl: 'https://www.developer-beta.com:5000/',
  
  /*--BETA--*/  
  // apiBaseUrl: 'https://sumatra-beta-backend.sumatra.in/admin-api/',
  // baseUrl: 'https://sumatra-beta-backend.sumatra.in/',

  // apiBaseUrl: 'https://sumatra.in:5000/admin-api/',
  // baseUrl: 'https://sumatra.in:5000/',

  /*--LIVE--*/
  apiBaseUrl: 'https://sumatra-backend.sumatra.in/admin-api/',
  baseUrl: 'https://sumatra-backend.sumatra.in/',
  
  // siteProfileBaseUrl: 'https://www.developer-beta.com/sumatra/site-portal/#/',
  production: false
};
