import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GstService } from './gst.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  styleUrls: ['./gst.component.css']
})
export class GstComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public addEditGstFormGroup: FormGroup = this._formBuilder.group({
    'gstTotalValue': ['', [Validators.required, Validators.pattern(/\d/)]],
    'cgstValue': ['', [Validators.required, Validators.pattern(/\d/)]],
    'sgstValue': ['', [Validators.required, Validators.pattern(/\d/)]],
    'igstValue': ['', [Validators.required, Validators.pattern(/\d/)]],
    'isPublished': [true, [Validators.required]]
  });
  public gstListings: any = [];
  public gstEditId: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _gstService: GstService, private _router: Router, @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getGstList();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getGstList() {
    this._emitter.emit('showLoader');
    this._gstService.getGstList(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.gstListings = [];
      }
      else if(resp.status == 200) {
        this.gstListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  modalClose() {
    this.gstEditId = '';
    this.modalRef.hide();
  }

  formSubmitted() {
    if(this.gstEditId != '') {
      this.editGstFunc();
    }
    else {
      this.createGstFunc();
    }
  }

  createGstFunc() {
    this._emitter.emit('showLoader');
    this._gstService.createGst(this._userId, this._accessToken, this._csrfToken, this.addEditGstFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.gstListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gstCreateSuccess
        });
        this.gstListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editGstFunc() {
    this._emitter.emit('showLoader');
    this._gstService.editGst(this._userId, this._accessToken, this._csrfToken, this.gstEditId, this.addEditGstFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.gstListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gstEditedSuccess
        });
        this.gstListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteGstFunc() {
    this._emitter.emit('showLoader');
    this._gstService.deleteGst(this._userId, this._accessToken, this._csrfToken, this.gstEditId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.gstListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gstDeletedSuccess
        });
        this.gstListings = resp.data;
        this.modalRef.hide()
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.gstEditId = data.gst_id;
    this.addEditGstFormGroup.controls['gstTotalValue'].setValue(data.gst_total_percentage);
    this.addEditGstFormGroup.controls['cgstValue'].setValue(data.cgst_percentage);
    this.addEditGstFormGroup.controls['sgstValue'].setValue(data.sgst_percentage);
    this.addEditGstFormGroup.controls['igstValue'].setValue(data.igst_percentage);
    this.addEditGstFormGroup.controls['isPublished'].setValue(data.active_status);
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.gstEditId = '';
    this.addEditGstFormGroup.reset({
      isPublished: true
    });
  }

}
