// import { Component, Inject, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter as EventEmitterCore } from '@angular/core';
// import { EventEmitter } from 'events';
// import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { AppVariables } from '../../../../../app.variables';
// // import { AppConfigs } from '../../../../../app.configs';
// import { ProductImageService } from './product-image.service';
// import { environment } from '../../../../../../environments/environment';
// declare var $: any;

// @Component({
//   selector: 'app-product-image',
//   templateUrl: './product-image.component.html',
//   styleUrls: ['./product-image.component.scss']
// })
// export class ProductImageComponent implements OnInit {

//   baseUrl = environment.baseUrl;
  
//   private _userId: string = '';
//   private _accessToken: string = '';
//   private _csrfToken: string = '';
//   public createOrEdit: number = 0; // 0 => Create, 1 => Edit
//   @ViewChild('sortableElem') sortableElem: ElementRef;
//   public editImage: boolean = false;
//   public editImageIndex: number = -1;
//   public productImagesChangedEvent: string = '';
//   public vendorId: string = '';
//   public text = ' Best Resolution Size: 1000 X 1000px';
//   public productEditId: string = '';

//   public productImagesArr: any = [
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//     {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     },
//   ];

//   public productImagesCroppedResult: any = {
//     sourceImage: '',
//     croppedImage: '',
//     listImage: '',
//     thumbImage: '',
//     croppedData: {
//       x: 0,
//       y: 0,
//       w: 0,
//       h: 0
//     }
//   };
  
//   @Input() productId: string = '';
//   @Output() responseOutputObj = new EventEmitterCore<any>();

//   public productCreateEditForm: FormGroup = this.formBuilder.group({
//     'productImagesArr': [this.productImagesArr, []]
//   });

//   constructor(@Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _productImageService: ProductImageService) { }

//   initJqueryUiSortable() {
//     $(this.sortableElem.nativeElement).sortable({
//       placeholder: "ui-state-highlight",
//       axis: "x",
//       containment: "parent",
//       update: (event, ui) => {
//         let updatedOrderingArr = [];
//         $('.ui-state-default').each((index, elem) => {
//           updatedOrderingArr.push($(elem).attr('data-index'));
//         });
//         let newImgArr = [];
//         for (let i of updatedOrderingArr) {
//           newImgArr.push(this.productImagesArr[i]);
//         }
//         this._ngZone.run(() => {
//           this.productImagesArr = newImgArr;

//           let newArr = [];
//           for (let data of this.productImagesArr) {
//             if (data.croppedImage != '') {
//               newArr.push(data);
//             }
//           }
//           this.productCreateEditForm.controls['productImagesArr'].setValue(newArr);
//         })
//       }
//     });
//   }

//   ngOnInit() {
//     try {
//       let userDetails = JSON.parse(localStorage.getItem("userDetails"));
//       this._userId = userDetails.user_id;
//       this._accessToken = userDetails.access_token;
//       this._csrfToken = localStorage.getItem("_CSRF");
//       setTimeout(() => {
//         this.initJqueryUiSortable();
//       }, 2000);

//       this._activatedRoute.params.subscribe((params) => {
//         this.vendorId = params.vendorId;
//         if(typeof params.productId !== 'undefined' && params.productId != '' && params.productId != null) {
//           this.getEditPageData();
//           this.productId = params.productId;
//           this.createOrEdit = 1; // if edit
//         }
//         else {
//           this.createOrEdit = 0;
//           // this.getCreateDataFunc();
//         }
//       });
//     } catch (error) {
//       console.log(error)
//       this._router.navigateByUrl('/login');
//       this._emitter.emit('hideLoader');
//     }
//   }

//   getDataUri(sourceImageFullPath, croppedImageFullPath = '', listImageFullPath = '', thumbImageFullPath = '') {
//     return new Promise((resolve, reject) => {
//       let canvas = document.createElement("canvas"),
//         ctx = canvas.getContext("2d"),
//         img = new Image(),
//         data = {
//           croppedImageUrl: '',
//           sourceImageUrl: '',
//           listImageUrl: '',
//           thumbImageUrl: ''
//         },
//         imgType = 'source';

//       img.crossOrigin = "anonymous";
//       img.onload = () => {
//         canvas.width = img.width;
//         canvas.height = img.height;
//         ctx.drawImage(img, 0, 0);
//         if (imgType == 'source') {
//           data.sourceImageUrl = canvas.toDataURL('image/'+sourceImageFullPath.split('.')[sourceImageFullPath.split('.').length - 1]);
//           if (croppedImageFullPath != '') {
//             imgType = 'crop';
//             img.src = croppedImageFullPath;
//           }
//           else {
//             resolve(data);
//           }
//         }
//         else if (imgType == 'crop') {
//           imgType = '';
//           data.croppedImageUrl = canvas.toDataURL('image/'+croppedImageFullPath.split('.')[croppedImageFullPath.split('.').length - 1]);
//           if(listImageFullPath != '') {
//             imgType = 'list';
//             img.src = listImageFullPath;
//           }
//           else {
//             resolve(data);
//           }
//         }
//         else if (imgType == 'list') {
//           imgType = '';
//           data.listImageUrl = canvas.toDataURL('image/'+listImageFullPath.split('.')[listImageFullPath.split('.').length - 1]);
//           if(thumbImageFullPath != '') {
//             imgType = 'thumb';
//             img.src = thumbImageFullPath;
//           }
//           else {
//             resolve(data);
//           }
//         }
//         else if (imgType == 'thumb') {
//           imgType = '';
//           data.thumbImageUrl = canvas.toDataURL('image/'+thumbImageFullPath.split('.')[thumbImageFullPath.split('.').length - 1]);
//           resolve(data);
//         }
//       };
//       img.src = sourceImageFullPath;
//     });
//   }

//   convertProductEditImagesToUri(editPageData, index) {
//     this.getDataUri(this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].source_image_name, this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].cropped_zoom_image_name, this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].cropped_listing_image_name,
//     this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].cropped_thumbnail_image_name).then((resp) => {
//       this.productImagesArr[index] = {
//         sourceImage: resp['sourceImageUrl'],
//         croppedImage: resp['croppedImageUrl'],
//         listImage: resp['listImageUrl'],
//         thumbImage: resp['thumbImageUrl'],
//         croppedData: {
//           x: editPageData[index].cropped_data.x,
//           y: editPageData[index].cropped_data.y,
//           w: editPageData[index].cropped_data.w,
//           h: editPageData[index].cropped_data.h
//         }
//       }
//       index++;
//       if(index >= editPageData.length) {
//         this.productCreateEditForm.controls['productImagesArr'].setValue(this.productImagesArr);
//       }
//       else {
//         this.convertProductEditImagesToUri(editPageData, index);
//       }
//     });
//   }

//   getEditPageData() {
//     this._emitter.emit('showLoader');
//     this._productImageService.productImagesEditGetData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
//     .subscribe((resp) => {
//       // console.log(resp);
//       this._emitter.emit('hideLoader');

//       if(resp.status == 401) {
//         this._router.navigateByUrl('/login');
//       }
//       else if(resp.status == 200) {
//         if(resp.data.length > 0) {
//           this.convertProductEditImagesToUri(resp.data, 0);
//         }
//       }
//       else {
//         this._emitter.emit('showAlert', {
//           type: 2,
//           msg: this.appVariables.someErrorOccurred
//         });
//       }
//     }, err => {
//       if(err.status == 401) {
//         this._router.navigateByUrl('/login');
//       }
//       this._emitter.emit('hideLoader');
//       this._emitter.emit('showAlert', {
//         type: 2,
//         msg: this.appVariables.someErrorOccurred
//       });
//     });
//   }

//   startProductImagesEdit(productImgObj, i) {
//     this.editImage = true;
//     this.editImageIndex = i;

//     this.productImagesCroppedResult = {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     };

//     // setTimeout(() => {
//       this.productImagesChangedEvent = 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAQAAAAe/WZNAAAADklEQVR42mNkgAJGDAYAAFEABCaLYqoAAAAASUVORK5CYII=';
//       setTimeout(() => {
//         this.productImagesChangedEvent = productImgObj.sourceImage;
//       }, 700);
//     // }, 200);
//     $(this.sortableElem.nativeElement).sortable("disable");
//   }

//   productImagesFileUpload(evnt) {
//     let _this = evnt.target;
//     if (_this.files && _this.files[0]) {
//       let fr = new FileReader();
//       if(_this.files[0].type.indexOf('image/') < 0) {
//         this._emitter.emit('showAlert', {
//           type: 2,
//           msg: this.appVariables.invalidImageFormat
//         });
//         return false;
//       }

//       fr.onload = (evt) => {
//         this.productImagesChangedEvent = evt.target['result'].toString();
//       }
//       fr.readAsDataURL(_this.files[0]);
//     }
//   }

//   productImagesCroppedOutput(croppedResult) {
//     let listCanvas = document.createElement('canvas'),
//         listCtx = listCanvas.getContext("2d"),
//         img = new Image();
    
//     listCanvas.width = 500;
//     listCanvas.height = 500;

//     img.onload = () => {
//       listCtx.drawImage(img, 0, 0, listCanvas.width, listCanvas.height);
//       let listImage = listCanvas.toDataURL('image/jpeg', 0.7);

//       let thumbCanvas = document.createElement('canvas'),
//           thumbCtx = thumbCanvas.getContext("2d");
      
//       thumbCanvas.width = 200;
//       thumbCanvas.height = 200;

//       thumbCtx.drawImage(img, 0, 0, thumbCanvas.width, thumbCanvas.height);
//       let thumbImage = thumbCanvas.toDataURL('image/jpeg', 0.7);

//       this.productImagesCroppedResult = {
//         sourceImage: croppedResult.sourceImage,
//         croppedImage: croppedResult.croppedImage,
//         listImage: listImage,
//         thumbImage: thumbImage,
//         croppedData: {
//           x: croppedResult.croppedData.x,
//           y: croppedResult.croppedData.y,
//           w: croppedResult.croppedData.w,
//           h: croppedResult.croppedData.h
//         }
//       };
//     };
//     img.src = croppedResult.croppedImage;
//   }

//   productImagesUploadDoneClicked() {
//     this.productImagesArr[this.editImageIndex] = this.productImagesCroppedResult;
//     let newArr = [];
//     for (let data of this.productImagesArr) {
//       if (data.croppedImage != '') {
//         newArr.push(data);
//       }
//     }
//     this.productCreateEditForm.controls['productImagesArr'].setValue(newArr);
//     this.editImage = false;
//     this.editImageIndex = -1;
//     $(this.sortableElem.nativeElement).sortable("enable");
//   }

//   productImagesDelete(index) {
//     this.productImagesArr[index] = {
//       sourceImage: '',
//       croppedImage: '',
//       listImage: '',
//       thumbImage: '',
//       croppedData: {
//         x: 0,
//         y: 0,
//         w: 0,
//         h: 0
//       }
//     };
//     this.productCreateEditForm.controls['productImagesArr'].setValue(this.productImagesArr);
//   }

//   productImagesFormSubmitted() {
//     let flag = 0;
//     for(let image of this.productImagesArr) {
//       if(image.croppedImage != '') {
//         flag = 1;
//         break;
//       }
//     }

//     if(flag == 0) {
//       this._emitter.emit('showAlert', {
//         type: 2,
//         msg: this.appVariables.productImagesAtLeastOneImageAlertText
//       });
//       return false;
//     }

//     if(this.createOrEdit == 0) {
//       this.productImagesCreate();
//     }
//     else {
//       this.productImagesEdit();
//     }
//   }

//   productImagesCreate() {
//     this._emitter.emit('showLoader');
//     // console.log('productImagesCreate', this._userId, this._accessToken, this._csrfToken, this.productId, this.productCreateEditForm.value)
//     this._productImageService.productImagesCreate(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.productCreateEditForm.value)
//     .subscribe((resp) => {
//       // console.log(resp);
//       this._emitter.emit('hideLoader');

//       if(resp.status == 401) {
//         this._router.navigateByUrl('/login');
//       }
//       else if(resp.status == 200) {
//         this._emitter.emit('showAlert', {
//           type: 1,
//           msg: this.appVariables.productImagesCreateSuccess
//         });
//         this.responseOutputObj.emit(resp);
//       }
//       else {
//         this._emitter.emit('showAlert', {
//           type: 2,
//           msg: this.appVariables.someErrorOccurred
//         });
//       }
//     }, err => {
//       if(err.status == 401) {
//         this._router.navigateByUrl('/login');
//       }
//       this._emitter.emit('hideLoader');
//       this._emitter.emit('showAlert', {
//         type: 2,
//         msg: this.appVariables.someErrorOccurred
//       });
//     });
//   }

//   productImagesEdit() {
//     this._emitter.emit('showLoader');
//     this._productImageService.productImagesEdit(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.productCreateEditForm.value)
//     .subscribe((resp) => {
//       // console.log(resp);
//       this._emitter.emit('hideLoader');

//       if(resp.status == 401) {
//         this._router.navigateByUrl('/login');
//       }
//       else if(resp.status == 200) {
//         this._emitter.emit('showAlert', {
//           type: 1,
//           msg: this.appVariables.productImagesEditSuccess
//         });
//         resp.product_id = this.productId;
//         this.responseOutputObj.emit(resp);
//       }
//       else {
//         this._emitter.emit('showAlert', {
//           type: 2,
//           msg: this.appVariables.someErrorOccurred
//         });
//       }
//     }, err => {
//       if(err.status == 401) {
//         this._router.navigateByUrl('/login');
//       }
//       this._emitter.emit('hideLoader');
//       this._emitter.emit('showAlert', {
//         type: 2,
//         msg: this.appVariables.someErrorOccurred
//       });
//     });
//   }

// }

import { Component, Inject, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter as EventEmitterCore } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppVariables } from '../../../../../app.variables';
// import { AppConfigs } from '../../../../../app.configs';
import { ProductImageService } from './product-image.service';
import { environment } from '../../../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent implements OnInit {

  baseUrl = environment.baseUrl;
  
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  @ViewChild('sortableElem') sortableElem: ElementRef;
  public editImage: boolean = false;
  public editImageIndex: number = -1;
  public productImagesChangedEvent: string = '';
  public vendorId: string = '';
  public text = ' Best Resolution Size: 1000 X 1000px';
  public productEditId: string = '';

  public productImagesArr: any = [
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
    {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    },
  ];

  public productImagesCroppedResult: any = {
    sourceImage: '',
    croppedImage: '',
    listImage: '',
    thumbImage: '',
    croppedData: {
      x: 0,
      y: 0,
      w: 0,
      h: 0
    }
  };
  
  @Input() productId: string = '';
  @Output() responseOutputObj = new EventEmitterCore<any>();

  public productCreateEditForm: FormGroup = this.formBuilder.group({
    'productImagesArr': [this.productImagesArr, []]
  });

  constructor(@Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _productImageService: ProductImageService) { }

  initJqueryUiSortable() {
    $(this.sortableElem.nativeElement).sortable({
      placeholder: "ui-state-highlight",
      axis: "x",
      containment: "parent",
      update: (event, ui) => {
        let updatedOrderingArr = [];
        $('.ui-state-default').each((index, elem) => {
          updatedOrderingArr.push($(elem).attr('data-index'));
        });
        let newImgArr = [];
        for (let i of updatedOrderingArr) {
          newImgArr.push(this.productImagesArr[i]);
        }
        this._ngZone.run(() => {
          this.productImagesArr = newImgArr;

          let newArr = [];
          for (let data of this.productImagesArr) {
            if (data.croppedImage != '') {
              newArr.push(data);
            }
          }
          this.productCreateEditForm.controls['productImagesArr'].setValue(newArr);
        })
      }
    });
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      setTimeout(() => {
        this.initJqueryUiSortable();
      }, 2000);

      this._activatedRoute.params.subscribe((params) => {
        this.vendorId = params.vendorId;
        if(typeof params.productId !== 'undefined' && params.productId != '' && params.productId != null) {
          this.getEditPageData();
          this.productId = params.productId;
          this.createOrEdit = 1; // if edit
        }
        else {
          this.createOrEdit = 0;
          // this.getCreateDataFunc();
        }
      });
    } catch (error) {
      console.log(error)
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getDataUri(sourceImageFullPath, croppedImageFullPath = '', listImageFullPath = '', thumbImageFullPath = '') {
    return new Promise((resolve, reject) => {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          croppedImageUrl: '',
          sourceImageUrl: '',
          listImageUrl: '',
          thumbImageUrl: ''
        },
        imgType = 'source';

      img.crossOrigin = "anonymous";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        if (imgType == 'source') {
          data.sourceImageUrl = canvas.toDataURL('image/'+sourceImageFullPath.split('.')[sourceImageFullPath.split('.').length - 1]);
          if (croppedImageFullPath != '') {
            imgType = 'crop';
            img.src = croppedImageFullPath;
          }
          else {
            resolve(data);
          }
        }
        else if (imgType == 'crop') {
          imgType = '';
          data.croppedImageUrl = canvas.toDataURL('image/'+croppedImageFullPath.split('.')[croppedImageFullPath.split('.').length - 1]);
          if(listImageFullPath != '') {
            imgType = 'list';
            img.src = listImageFullPath;
          }
          else {
            resolve(data);
          }
        }
        else if (imgType == 'list') {
          imgType = '';
          data.listImageUrl = canvas.toDataURL('image/'+listImageFullPath.split('.')[listImageFullPath.split('.').length - 1]);
          if(thumbImageFullPath != '') {
            imgType = 'thumb';
            img.src = thumbImageFullPath;
          }
          else {
            resolve(data);
          }
        }
        else if (imgType == 'thumb') {
          imgType = '';
          data.thumbImageUrl = canvas.toDataURL('image/'+thumbImageFullPath.split('.')[thumbImageFullPath.split('.').length - 1]);
          resolve(data);
        }
      };
      img.src = sourceImageFullPath;
    });
  }

  convertProductEditImagesToUri(editPageData, index) {
    this.getDataUri(this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].source_image_name, this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].cropped_zoom_image_name, this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].cropped_listing_image_name,
    this.baseUrl + 'images/uploads/products/' + this.productId + '/' + editPageData[index].cropped_thumbnail_image_name).then((resp) => {
      this.productImagesArr[index] = {
        sourceImage: resp['sourceImageUrl'],
        croppedImage: resp['croppedImageUrl'],
        listImage: resp['listImageUrl'],
        thumbImage: resp['thumbImageUrl'],
        croppedData: {
          x: editPageData[index].cropped_data.x,
          y: editPageData[index].cropped_data.y,
          w: editPageData[index].cropped_data.w,
          h: editPageData[index].cropped_data.h
        }
      }
      index++;
      if(index >= editPageData.length) {
        this.productCreateEditForm.controls['productImagesArr'].setValue(this.productImagesArr);
      }
      else {
        this.convertProductEditImagesToUri(editPageData, index);
      }
    });
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._productImageService.productImagesEditGetData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
    .subscribe((resp) => {
       console.log("-->300",resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        if(resp.data.length > 0) {
          this.convertProductEditImagesToUri(resp.data, 0);
        }
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  startProductImagesEdit(productImgObj, i) {
    this.editImage = true;
    this.editImageIndex = i;

    this.productImagesCroppedResult = {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    };

     setTimeout(() => {
      this.productImagesChangedEvent = 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAQAAAAe/WZNAAAADklEQVR42mNkgAJGDAYAAFEABCaLYqoAAAAASUVORK5CYII=';
      setTimeout(() => {
        this.productImagesChangedEvent = productImgObj.sourceImage;
      }, 700);
     }, 200);
    $(this.sortableElem.nativeElement).sortable("disable");
  }

  productImagesFileUpload(evnt) {
    let _this = evnt.target;
    if (_this.files && _this.files[0]) {
      let fr = new FileReader();
      if(_this.files[0].type.indexOf('image/') < 0) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.invalidImageFormat
        });
        return false;
      }

      fr.onload = (evt) => {
        this.productImagesChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }
  }

  productImagesCroppedOutput(croppedResult) {
    let listCanvas = document.createElement('canvas'),
        listCtx = listCanvas.getContext("2d"),
        img = new Image();
    
    listCanvas.width = 500;
    listCanvas.height = 500;

    img.onload = () => {
      listCtx.drawImage(img, 0, 0, listCanvas.width, listCanvas.height);
      let listImage = listCanvas.toDataURL('image/jpeg', 0.7);

      let thumbCanvas = document.createElement('canvas'),
          thumbCtx = thumbCanvas.getContext("2d");
      
      thumbCanvas.width = 200;
      thumbCanvas.height = 200;

      thumbCtx.drawImage(img, 0, 0, thumbCanvas.width, thumbCanvas.height);
      let thumbImage = thumbCanvas.toDataURL('image/jpeg', 0.7);

      this.productImagesCroppedResult = {
        sourceImage: croppedResult.sourceImage,
        croppedImage: croppedResult.croppedImage,
        listImage: listImage,
        thumbImage: thumbImage,
        croppedData: {
          x: croppedResult.croppedData.x,
          y: croppedResult.croppedData.y,
          w: croppedResult.croppedData.w,
          h: croppedResult.croppedData.h
        }
      };
    };
    img.src = croppedResult.croppedImage;
  }

  productImagesUploadDoneClicked() {
    this.productImagesArr[this.editImageIndex] = this.productImagesCroppedResult;
    let newArr = [];
    for (let data of this.productImagesArr) {
      if (data.croppedImage != '') {
        newArr.push(data);
      }
    }
    this.productCreateEditForm.controls['productImagesArr'].setValue(newArr);
    this.editImage = false;
    this.editImageIndex = -1;
    $(this.sortableElem.nativeElement).sortable("enable");
  }

  productImagesDelete(index) {
    this.productImagesArr[index] = {
      sourceImage: '',
      croppedImage: '',
      listImage: '',
      thumbImage: '',
      croppedData: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    };
    this.productCreateEditForm.controls['productImagesArr'].setValue(this.productImagesArr);
  }

  productImagesFormSubmitted() {
    let flag = 0;
    for(let image of this.productImagesArr) {
      if(image.croppedImage != '') {
        flag = 1;
        break;
      }
    }

    if(flag == 0) {
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.productImagesAtLeastOneImageAlertText
      });
      return false;
    }

    if(this.createOrEdit == 0) {
      this.productImagesCreate();
    }
    else {
      this.productImagesEdit();
    }
  }

  productImagesCreate() {
    this._emitter.emit('showLoader');
    // console.log('productImagesCreate', this._userId, this._accessToken, this._csrfToken, this.productId, this.productCreateEditForm.value)
    this._productImageService.productImagesCreate(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.productCreateEditForm.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productImagesCreateSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  productImagesEdit() {
    this._emitter.emit('showLoader');
    this._productImageService.productImagesEdit(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.productCreateEditForm.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productImagesEditSuccess
        });
        resp.product_id = this.productId;
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}




