import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AddEditCmsService } from './add-edit-cms.service';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-edit-cms',
  templateUrl: './add-edit-cms.component.html',
  styleUrls: ['./add-edit-cms.component.scss']
})
export class AddEditCmsComponent implements OnInit {
  baseUrl = environment.baseUrl;

  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public bannerNoImageUrl = 'assets/img/no-img-5x1.jpg';
  public bannerSectionBlankDataObj: any = {
    imgData: {
      croppedImageUrl: this.bannerNoImageUrl,
      sourceImageUrl: '',
      cropData: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    },
    imgAltText: '',
    caption1: '',
    caption2: '',
    redirectUrl: ''
  };
  public addEditCmsForm: FormGroup = this.formBuilder.group({
    'pageTitle': ['', [Validators.required]],
    'pageDescription': ['', [Validators.required]],
    'pageShortDescription': ['', []],
    'metaTitle': ['', [Validators.required]],
    'metaDescription': ['', []],
    'metaKeyword': ['', []],
    'seoUrl': ['', []],
    'isPublish': [true, []],
    'bannerSection': [[this.bannerSectionBlankDataObj], []]
  });
  public bannerSection: any = [JSON.parse(JSON.stringify(this.bannerSectionBlankDataObj))];
  public config: any = {
    height: 300
  };
  public cmsPageId: string = '';
  public imageChangedEvent: any = [];

  constructor(public formBuilder: FormBuilder,private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _addEditCmsService: AddEditCmsService, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }

    this._activatedRoute.params.subscribe(param => {
      if(typeof param.cmsId !== 'undefined' && param.cmsId != null) {
        this.cmsPageId = param.cmsId;
        this.getCmsData();
      }
    });
  }

  fileUpload(evnt, index) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent[index] = evt.target['result'];
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  croppedOutput(croppedResult, index) {
    this.bannerSection[index].imgData.croppedImageUrl = croppedResult.croppedImage;
    this.bannerSection[index].imgData.sourceImageUrl = croppedResult.sourceImage;
    this.bannerSection[index].imgData.cropData.x = croppedResult.croppedData.x;
    this.bannerSection[index].imgData.cropData.y = croppedResult.croppedData.y;
    this.bannerSection[index].imgData.cropData.w = croppedResult.croppedData.w;
    this.bannerSection[index].imgData.cropData.h = croppedResult.croppedData.h;
  }

  getDataUri(bannerData, i) {
    this.bannerSection.push({
      imgData: {
        croppedImageUrl: this.baseUrl + 'images/uploads/cms-pages/' + bannerData[i].cropped_image_name,
        sourceImageUrl: this.baseUrl + 'images/uploads/cms-pages/' + bannerData[i].source_image_name,
        cropData: {
          x: bannerData[i].cropped_data.x,
          y: bannerData[i].cropped_data.y,
          w: bannerData[i].cropped_data.w,
          h: bannerData[i].cropped_data.h
        }
      },
      imgAltText: bannerData[i].image_alt,
      caption1: bannerData[i].image_caption_1,
      caption2: bannerData[i].image_caption_2,
      redirectUrl: bannerData[i].redirect_url
    });
    
    let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image();
    
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      setTimeout(() => {
        this.imageChangedEvent[i] = canvas.toDataURL();
        i++;
        if(i < bannerData.length) {
          this.getDataUri(bannerData, i);
        }
      }, 200);
    };
    img.src = this.baseUrl + 'images/uploads/cms-pages/' + bannerData[i].source_image_name;
  }

  getCmsData() {
    this._emitter.emit('showLoader');
    this._addEditCmsService.cmsPageDetails(this._userId, this._accessToken, this._csrfToken, this.cmsPageId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/cms-management/cms-create');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/cms-management/cms-create');
      }
      else if(resp.status == 200 && resp.data != '') {
        let data = resp.data;
        this.addEditCmsForm.controls['pageTitle'].setValue(data.page_name);
        this.addEditCmsForm.controls['pageDescription'].setValue(data.page_description);
        if(data.page_short_description != null){
          this.addEditCmsForm.controls['pageShortDescription'].setValue(data.page_short_description);
        }
        this.addEditCmsForm.controls['metaTitle'].setValue(data.meta_title);
        if(data.meta_description != null){
        this.addEditCmsForm.controls['metaDescription'].setValue(data.meta_description);
        }
        if(data.meta_keyword != null){
        this.addEditCmsForm.controls['metaKeyword'].setValue(data.meta_keyword);
        }
        this.addEditCmsForm.controls['seoUrl'].setValue(data.seo_url);
        this.addEditCmsForm.controls['isPublish'].setValue(data.active_status);
        this.addEditCmsForm.controls['bannerSection'].setValue(data.banner_data);
        this.bannerSection = data.banner_data;
        if(data.banner_data != null && data.banner_data.length > 0) {
          this.bannerSection = [];
          this.getDataUri(data.banner_data, 0);
        }
        else {
          this.bannerSection = [JSON.parse(JSON.stringify(this.bannerSectionBlankDataObj))];
        }
      }
      else if(resp.status == 200 && resp.data == null) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/cms-management/cms-create');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/cms-management/cms-create');
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  // createCmsPageFunction() {
  //   this._emitter.emit('showLoader');
  //   this._addEditCmsService.createCmsPage(this._userId, this._accessToken, this._csrfToken, this.addEditCmsForm.value)
  //   .subscribe((resp) => {
  //     console.log(resp);
  //     this._emitter.emit('hideLoader');

  //     if(resp.status == 401) {
  //       this._router.navigateByUrl('/login');
  //     }
  //     else if(resp.status == 201) {
  //       this._emitter.emit('showAlert', {
  //         type: 2,
  //         msg: this.appVariables.someErrorOccurred
  //       });
  //     }
  //     else if(resp.status == 200) {
  //       this._emitter.emit('showAlert', {
  //         type: 1,
  //         msg: this.appVariables.cmsPageCreateSuccess
  //       });
  //       this._router.navigateByUrl('/cms-management/cms-listings');
  //     }
  //     else {
  //       this._emitter.emit('showAlert', {
  //         type: 2,
  //         msg: this.appVariables.someErrorOccurred
  //       });
  //     }
  //   }, (err) => {
  //     this._emitter.emit('hideLoader');
  //     this._router.navigateByUrl('/login');
  //     this._emitter.emit('showAlert', {
  //       type: 2,
  //       msg: this.appVariables.someErrorOccurred
  //     });
  //   });
  // }

  editCmsPageFunction() {
    this._emitter.emit('showLoader');
    this.addEditCmsForm.controls['bannerSection'].setValue(this.bannerSection);
    
    this._addEditCmsService.editCmsPage(this._userId, this._accessToken, this._csrfToken, this.cmsPageId, this.addEditCmsForm.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.cmsPageUpdatedSuccess
        });
        this._router.navigateByUrl('/cms-management/cms-listings');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  formSubmitted() {
    let newArr = [];
    for(let i=0; i<this.bannerSection.length; i++) {
      if(this.bannerSection[i].imgData.cropData.x != '') {
        newArr.push(JSON.parse(JSON.stringify(this.bannerSection[i])));
      }
    }
    this.addEditCmsForm.controls['bannerSection'].setValue(newArr);
    // console.log(this.addEditCmsForm.get('bannerSection').value);

    if(this.cmsPageId == '') { // Create CMS Page
      // this.createCmsPageFunction();
    }
    else { // Edit CMS Page
      this.editCmsPageFunction();
    }
  }

  addBannerClicked(index) {
    let bannerVal = [...this.bannerSection];
    bannerVal.splice(index+1, 0, JSON.parse(JSON.stringify(this.bannerSectionBlankDataObj)));
    this.bannerSection = bannerVal;

    let imageChangedEvent = [...this.imageChangedEvent];
    imageChangedEvent.splice(index+1, 0, '');
    this.imageChangedEvent = imageChangedEvent;
  }

  removeBannerClicked(index) {
    let bannerVal = [...this.bannerSection];
    bannerVal.splice(index, 1);
    this.bannerSection = bannerVal;

    let imageChangedEvent = [...this.imageChangedEvent];
    imageChangedEvent.splice(index, 1);
    this.imageChangedEvent = imageChangedEvent;
  }

  replaceDoubleQuotes(str) {
    if(typeof str !== 'undefined' && str != null) {
      return str.replace(/\"/g, '');
    }
    else {
      return str;
    }
  }

}
