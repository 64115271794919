import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class TrainerPurchaseService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken, searchForm, currentPageNumber) {
    let fd = new FormData();    

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text",searchForm.searchText);
    fd.append("page_no", currentPageNumber);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-purchase-history-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
