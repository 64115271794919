import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppVariables } from '../../../app.variables';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { CreateEditProductService } from './create-edit-product.service';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { filter, map } from "rxjs/operators";

@Component({
  selector: 'app-create-edit-product',
  templateUrl: './create-edit-product.component.html',
  styleUrls: ['./create-edit-product.component.scss']
})
export class CreateEditProductComponent implements OnInit {

  @ViewChild('productTabs') productTabs: TabsetComponent;

  modalRef: BsModalRef;
  public showQrCodeLink: boolean = false;
  public getQrCodePath: string = '';
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public userTitle: any = [];
  public productId: string = '';
  public vendorId: string = '';
  public activeTab: number = 0;
  public tabActivationStatus: any = [0, 0, 0, 0];
  // public tabActivationStatus: any = [0, 0, 0, 0, 0, 0];
  public adminVerificationStatus: boolean = false;
  public publishStatus: boolean = true;
  public verifiedAt: string = 'N/A';
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public idSendData = {
    productId: this.productId,
    // productId :this.productId,
    // branchId : this.productId
  }
  public allid: any;
  public productSlugUrl: string = '';

  previousUrl: string = null;
  currentUrl: string = null;

  // Doughnut
  public doughnutChartData: number[] = [0, 100];
  public doughnutChartType: string = 'doughnut';
  public doughnutChartOptions: any = {
    tooltips: {
      enabled: false
    }
  };
  public doughnutChartColors: any = [{
    backgroundColor: ['#009666', '#9e9e9e'],
    borderColor: ['#009666', '#9e9e9e'],
    pointBackgroundColor: ['#009666', '#9e9e9e']
  }];

  constructor(@Inject(EventEmitter) private _emitter: EventEmitter, private _modalService: BsModalService, private _router: Router, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute, private _createEditProductService: CreateEditProductService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.userTitle = JSON.parse(localStorage.getItem("userTitles"));

      this._activatedRoute.params.subscribe((param) => {
        this.vendorId = param.vendorId;
        if (typeof param.productId !== 'undefined') {
          this.createOrEdit = 1;
          this.productId = param.productId;
          this.getEditData();
          this.showQrCodeLink = true;
        }
        this._emitter.on('idSendData', () => { // Emitting from app.component.ts
          this.allid = this.productId;
        });
      });

      // this._router.events
      //   .pipe(filter(event => event instanceof NavigationEnd))
      //   .subscribe((event: NavigationEnd) => {
      //     this.previousUrl = this.currentUrl;
      //     this.currentUrl = event.url;

      //     if (this.previousUrl != null
      //       && this._router.url.split('/')[1] == `vendor-product-management`
      //       && this.previousUrl == '/product-full-listings') {
      //       localStorage.setItem("previous_url", this.previousUrl)
      //     }
      //   });

    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  qrCodeModal(template: TemplateRef<any>) {
    this.getQrCodePath = this.appVariables.uploadImageBasePath + "products/" + this.productId + "/product-branch/" + this.productId + "/" + this.productId + "-qrcode.jpeg?t=" + (new Date().getTime());
    this.modalRef = this._modalService.show(template);
  }

  isActiveStatusChanged() {
    this._emitter.emit('showLoader');
    this._createEditProductService.updatePublishStatus(this._userId, this._accessToken, this._csrfToken, this.productId, this.publishStatus)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
          this.productId = resp.product_id;
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.productAdminPublishStatusUpdateSuccess
          });
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  getEditData() {
    this._emitter.emit('showLoader');
    this._createEditProductService.getEditPageData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          this.adminVerificationStatus = resp.data.verification_status;
          this.publishStatus = resp.data.active_status;
          this.productSlugUrl = resp.data.seo_url;
          this.doughnutChartData = [resp.data.setup_complete_percentage, 100 - resp.data.setup_complete_percentage];

          this.tabActivationStatus[0] = !resp.data.product_images_status ? 0 : 1;
          this.tabActivationStatus[1] = !resp.data.product_attribute_status ? 0 : 1;
          this.tabActivationStatus[2] = !resp.data.product_feature_status ? 0 : 1;

          this.productTabs.tabs[0].disabled = false;
          this.productTabs.tabs[1].disabled = false;
          this.productTabs.tabs[2].disabled = false;
          this.productTabs.tabs[3].disabled = false;
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  basicDetailsTabOutput(resp) {
    console.log('basicDetailsTabOutput', resp);
    if (resp.status == 200) {
      this.showQrCodeLink = true;
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];

      // if(this.productId == '') {
      //   this.productTabs.tabs[0].disabled = true;
      //   this.productTabs.tabs[1].disabled = false;
      // }

      if (this.createOrEdit == 0) {
        this.productTabs.tabs[0].disabled = true;
        this.productTabs.tabs[1].disabled = false;
        this.productTabs.tabs[2].disabled = true;
        this.productTabs.tabs[3].disabled = true;
      }

      this.productId = resp.product_id;
      this.productSlugUrl = resp.seo_url;
      this.activeTab = 1;
      this.productTabs.tabs[1].active = true;
      window.scrollTo(0, 0);
    }
  }

  productImagesTabOutput(resp) {
    // console.log(resp);
    if (resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];

      // this.productTabs.tabs[2].disabled = false;

      if (this.createOrEdit == 0) {
        this.productTabs.tabs[0].disabled = true;
        this.productTabs.tabs[1].disabled = true;
        this.productTabs.tabs[2].disabled = false;
        this.productTabs.tabs[3].disabled = true;
      }

      this.productId = resp.product_id;
      this.activeTab = 2;
      this.productTabs.tabs[2].active = true;
      this.tabActivationStatus[0] = 1;
      window.scrollTo(0, 0);
    }
  }

  productVarientsTabOutput(resp) {
    // console.log(resp);
    if (resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];

      if (this.createOrEdit == 0) {
        this.productTabs.tabs[0].disabled = true;
        this.productTabs.tabs[1].disabled = true;
        this.productTabs.tabs[2].disabled = true;
        this.productTabs.tabs[3].disabled = false;
      }

      this.productId = resp.product_id;
      // this.productTabs.tabs[3].disabled = false;
      this.activeTab = 3;
      this.productTabs.tabs[3].active = true;
      this.tabActivationStatus[1] = 2;
      window.scrollTo(0, 0);
    }
  }

  productFeaturesTabOutput(resp) {
    // console.log(resp);
    if (resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];
      // this.productId = resp.product_id;
      this.tabActivationStatus[2] = 3;
      // window.scrollTo(0, 0);
    }
  }

  tabOnSelect(tabIndex) {
    this.activeTab = tabIndex;
    // console.log(this.activeTab);
  }

  backToList() {
    let previous_url = localStorage.getItem("previous_url");
    if (previous_url != null && previous_url == "/product-full-listings") {
      this._router.navigateByUrl("/product-full-listings").then((resp: any) => {
        localStorage.setItem("previous_url", "");
      });
    } else {
      this._router.navigateByUrl("/vendor-product-management/product-listings/" + this.vendorId).then((resp: any) => {
        localStorage.setItem("previous_url", "");
      });
    }
  }

  ngOnDestroy() {
    localStorage.setItem("previous_url", "");
  }
}
