import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CouponService {

  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getCouponList(userId, accessToken, csrfToken, dataPerPage, pageNo) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("data_per_page", dataPerPage);
    fd.append("page_no", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'coupon-list', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createCoupon(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("discount_percentage", formData.discountPercentage);
    fd.append("flat_discount", formData.flatDiscount);
    fd.append("coupon_name", formData.couponName);
    fd.append("coupon_code", formData.couponCode);
    fd.append("start_date", formData.startDate);
    fd.append("end_date", formData.endDate);
    fd.append("active_status", formData.activeStatus);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'coupon-add', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editCoupon(userId, accessToken, csrfToken, couponEditId, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("coupon_id", couponEditId);
    fd.append("discount_percentage", formData.discountPercentage);
    fd.append("flat_discount", formData.flatDiscount);
    fd.append("coupon_name", formData.couponName);
    fd.append("coupon_code", formData.couponCode);
    fd.append("start_date", formData.startDate);
    fd.append("end_date", formData.endDate);
    fd.append("active_status", formData.activeStatus);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'coupon-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteCoupon(userId, accessToken, csrfToken, couponEditId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("coupon_id", couponEditId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'coupon-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
