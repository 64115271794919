import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { AppVariables } from '../../../app.variables';
import { Router, ActivatedRoute } from '@angular/router';
import { EventEmitter } from 'events';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnquiryDetailsService } from './enquiry-details.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-enquiry-details',
  templateUrl: './enquiry-details.component.html',
  styleUrls: ['./enquiry-details.component.scss']
})
export class EnquiryDetailsComponent implements OnInit {
  baseUrl = environment.baseUrl;
  modalRef: BsModalRef;
  public userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public config: any = {
    height: 300,
    toolbar: [
      // [groupName, [list of button]]
      // ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol']],
      // ['insert', ['table', 'link', 'hr']]
    ]
  };
  public composeMailForm: FormGroup = this._formBuilder.group({
    'mailBody': ['', [Validators.required]]
  });
  public feedbackId: string = '';
  public getMailThreadSubject: string = '';
  public senderGymId: string = '';
  public getMailThreadsListingsData: any = [];

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _activatedRoute: ActivatedRoute, private _enquiryDetailsService: EnquiryDetailsService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this.userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this._activatedRoute.params.subscribe((param) => {
        this.feedbackId = param.feedbackId;
        this.getMailThreadsListings();
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }
 
  openModal(composeMail: TemplateRef<any>) {
    this.modalRef = this._modalService.show(composeMail, {
      ignoreBackdropClick: true
    });
  }

  getTimeDifference(duration) {
    let currentTime = new Date().getTime(),
        mailTime = new Date(duration).getTime(),
        diff = currentTime - mailTime,
        years = parseInt(parseFloat((diff / (1000 * 60 * 60 * 24 * 365)).toString()).toFixed(2).toString()),
        months = years == 0 ? parseInt(parseFloat((diff / (1000 * 60 * 60 * 24 * 30)).toString()).toFixed(2).toString()) : parseInt(((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)).toString()),
        days = months == 0 ? parseInt(parseFloat((diff / (1000 * 60 * 60 * 24)).toString()).toFixed(2).toString()) : parseInt(((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)).toString()),
        hours = days == 0 ? parseInt(parseFloat((diff / (1000 * 60 * 60)).toString()).toFixed(2).toString()) : parseInt(((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString()),
        minutes = hours == 0 ? parseInt(parseFloat((diff / (1000 * 60)).toString()).toFixed(2).toString()) : parseInt(((diff % (1000 * 60 * 60)) / (1000 * 60)).toString()),
        seconds = minutes == 0 ? parseInt(parseFloat((diff / 1000).toString()).toFixed(2).toString()) : parseInt(((diff % (1000 * 60)) / 1000).toString()),
        diffText = '';

    if(years != 0) {
      diffText = years + (years == 1 ? ' Year' : ' Years');
    }
    else if(months != 0) {
      diffText = months + (months == 1 ? ' Month' : ' Months');
    }
    else if(days != 0) {
      diffText = days + (days == 1 ? ' Day' : ' Days');
    }
    else if(hours != 0) {
      diffText = hours + (hours == 1 ? ' Hour' : ' Hours');
    }
    else if(minutes != 0) {
      diffText = minutes + (minutes == 1 ? ' Minute' : ' Minutes');
    }
    else if(seconds != 0) {
      diffText = seconds + (seconds == 1 ? ' Second' : ' Seconds');
    }
    else {
      diffText = '0 Second';
    }

    return diffText;
  }

  getMailThreadsListings() {
    this._emitter.emit('showLoader');
    this._enquiryDetailsService.getMailThreadListings(this.userId, this._accessToken, this._csrfToken, this.feedbackId)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');
      this.composeMailForm.reset();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._router.navigateByUrl('/enquiry-to-admin-listings');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
      }
      else if(resp.status == 200) {
        this.getMailThreadSubject = resp.data.thread_subject;
        this.senderGymId = resp.data.vendor_id;
        this.getMailThreadsListingsData = resp.data.mail_threads;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this.composeMailForm.reset();
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  composeMailSubmit() {
    if(!this.composeMailForm.valid) {
      return false;
    }

    this._emitter.emit('showLoader');
    this._enquiryDetailsService.composeMailSend(this.userId, this._accessToken, this._csrfToken, this.feedbackId, this.composeMailForm.value)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');
      this.modalRef.hide();
      this.composeMailForm.reset();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.feedbackEnquiryToVendorComposeMailSentSuccess
        });
        this.getMailThreadSubject = resp.data.thread_subject;
        this.getMailThreadsListingsData = resp.data.mail_threads;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this.modalRef.hide();
      this.composeMailForm.reset();
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
