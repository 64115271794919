import { Component, Inject, OnInit, Input, Output, EventEmitter as EventEmitterCore } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BankDetailsService } from './bank-details.service';
import { AppVariables } from '../../../../../app.variables';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {
  baseUrl = environment.baseUrl;

  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public cnfAccountNumber: string = '';
  public signatureImageChangedEvent: string = '';
  public text = 'Best Resolution Size: 3099 X 790px';
  // public bankDetailsFormGroup: FormGroup = this._formBuilder.group({
  //   'bankName': ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
  //   'branchName': ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
  //   'ifscCode': ['', [Validators.required, Validators.pattern(/^[A-Za-z]{4}\d{7}$/)]],
  //   'accountNumber': ['', [Validators.required, Validators.pattern(/^\d{9,18}$/)]],
  //   'confirmAccountNumber': ['', [this.confirmPasswordMatchValidation]],
  // });

  public bankDetailsFormGroup: FormGroup = this._formBuilder.group({
    'bankName': ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
    'branchName': ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
    'ifscCode': ['', [Validators.required, Validators.pattern(/^[A-Za-z]{4}\d{7}$/)]],
    'accountNumber': ['', [this.confirmAccNoValidation]],
    'confirmAccountNumber': ['', [this.confirmAccNoValidation]],
    'signatureImage': [{
      source_img: '',
      cropped_img: '',
      crop_data: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    }, []]
  });

  @Input()vendorId: string;
  @Output() responseOutputObj = new EventEmitterCore<any>();

  constructor(@Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, private _formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute, private _bankDetailsService: BankDetailsService, public appVariables: AppVariables) { }

  confirmAccNoValidation(control: FormControl) {
    if (typeof control.parent !== 'undefined') {
      if (control.value == '' || !(/^\d{9,18}$/.test(control.value))) {
        return { errorMessage: 'This field is mandatory' };
      }
      else if (control.parent.controls['accountNumber'].value != control.parent.controls['confirmAccountNumber'].value) {
        // console.log(1)
        // control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
        return { errorMessage: 'This field is mandatory' };
        // return null;
      }else if (control.parent.controls['accountNumber'].value == control.parent.controls['confirmAccountNumber'].value) {
        // console.log(2)
        // control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
        control.parent.controls['confirmAccountNumber'].setErrors(null);
        control.parent.controls['accountNumber'].setErrors(null);
        return null;
      }
      else {
        // console.log(3)
        control.parent.controls['confirmAccountNumber'].setErrors(null);
        return null;
      }
    }
  }

  // accountMatchValidation(control: FormControl) {    
  //   if (typeof control.parent !== 'undefined') {
  //     if (control.value == '' || !(/^\d{9,18}$/.test(control.value))) {
  //       return { errorMessage: 'This field is mandatory' };
  //     }
  //     else if (control.parent.controls['accountNumber'].value != control.parent.controls['confirmAccountNumber'].value) {

  //       control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
  //       let cnf = control.parent.controls['confirmAccountNumber'].value
  //       console.log(control.parent.controls['accountNumber'].value+"::"+control.parent.controls['confirmAccountNumber'].value+"::"+cnf)
  //       if(cnf != ''){
  //         if(cnf != control.parent.controls['accountNumber'].value){
  //           return { errorMessage: 'This field is mandatory' };
  //         }
  //       }else{
  //         control.parent.controls['accountNumber'].setErrors(null);
  //         return null;
  //       }        
        
        
  //       // return { errorMessage: 'This field is mandatory' };
  //     }
  //     else {
  //       control.parent.controls['confirmAccountNumber'].setErrors(null);
  //       return null;
  //     }
  //   }
  // }
  // confirmAccountMatchValidation(control: FormControl) {
  //   if (typeof control.parent !== 'undefined') {
  //     if (control.value == '') {
  //       return { errorMessage: 'This field is mandatory' };
  //     }
  //     else if (control.parent.controls['accountNumber'].value != control.parent.controls['confirmAccountNumber'].value) {
  //       return { errorMessage: 'This field is mandatory' };
  //     }
  //     else {
  //       control.parent.controls['confirmAccountNumber'].setErrors(null);
  //       return null;
  //     }
  //   }
  // }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this._activatedRoute.params.subscribe((params) => {
        if(typeof params.vendorId !== 'undefined' && params.vendorId != null && params.vendorId != '') {
          this.createOrEdit = 1;
          this.getEditPageData();
        }
        else {
          this.createOrEdit = 0;
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getDataUri(sourceImageFullPath, croppedImageFullPath = '') {
    return new Promise((resolve, reject) => {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          croppedImageUrl: '',
          sourceImageUrl: ''
        },
        imgType = 'source';

      img.crossOrigin = "anonymous";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        if (imgType == 'source') {
          data.sourceImageUrl = canvas.toDataURL('image/'+sourceImageFullPath.split('.')[sourceImageFullPath.split('.').length - 1]);
          if (croppedImageFullPath != '') {
            imgType = 'crop';
            img.src = croppedImageFullPath;
          }
          else {
            resolve(data);
          }
        }
        else if (imgType == 'crop') {
          imgType = '';
          data.croppedImageUrl = canvas.toDataURL('image/'+croppedImageFullPath.split('.')[croppedImageFullPath.split('.').length - 1]);
          resolve(data);
        }
      };
      img.src = sourceImageFullPath;
    });
  }

  signatureCroppedOutput(croppedResult) {
    this.bankDetailsFormGroup.controls['signatureImage'].setValue({
      source_img: croppedResult.sourceImage,
      cropped_img: croppedResult.croppedImage,
      crop_data: {
        x: croppedResult.croppedData.x,
        y: croppedResult.croppedData.y,
        w: croppedResult.croppedData.w,
        h: croppedResult.croppedData.h
      }
    });
  }

  signatureFileUpload(evnt) {
    let _this = evnt.target;
    if (_this.files && _this.files[0]) {
      let fr = new FileReader();
      if(_this.files[0].type.indexOf('image/jpeg') < 0) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.invalidImageFormat
        });
        return false;
      }

      fr.onload = (evt) => {
        this.signatureImageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._bankDetailsService.editBankDetailsPageData(this._userId, this._accessToken, this._csrfToken, this.vendorId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
      else if(resp.status == 200) {
        if(resp.data != null) {
          this.bankDetailsFormGroup.controls['bankName'].setValue(resp.data.bank_name);
          this.bankDetailsFormGroup.controls['branchName'].setValue(resp.data.branch_name);
          this.bankDetailsFormGroup.controls['ifscCode'].setValue(resp.data.ifsc_code);
          this.bankDetailsFormGroup.controls['accountNumber'].setValue(resp.data.account_number);

          let sourceImgUrl = resp.data == null || resp.data.signature_image_source == null ? '' : this.baseUrl + 'images/uploads/vendor/' + this.vendorId + '/' + resp.data.signature_image_source,
          croppedImgUrl = resp.data == null || resp.data.signature_image_cropped == null ? '' : this.baseUrl + 'images/uploads/vendor/' + this.vendorId + '/' + resp.data.signature_image_cropped;
          
          this.getDataUri(sourceImgUrl, croppedImgUrl).then((res) => {
            this.bankDetailsFormGroup.controls['signatureImage'].setValue({
              source_img: res['sourceImageUrl'],
              cropped_img: res['croppedImageUrl'],
              crop_data: {
                x: resp.data != null && resp.data.signature_image_crop_data != null ? resp.data.signature_image_crop_data.x : 0.1,
                y: resp.data != null && resp.data.signature_image_crop_data != null ? resp.data.signature_image_crop_data.y : 0.1,
                w: resp.data != null && resp.data.signature_image_crop_data != null ? resp.data.signature_image_crop_data.w : 0.8,
                h: resp.data != null && resp.data.signature_image_crop_data != null ? resp.data.signature_image_crop_data.h : 0.8
              }
            });
            setTimeout(() => {
              this.signatureImageChangedEvent = res['sourceImageUrl'].toString();
            }, 200);
          });
        }
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  bankDetailsSubmitted() {
    if(this.createOrEdit == 0) {
      this.createBankDetailsFunc();
    }
    else {
      this.editBankDetailsFunc();
    }
  }

  createBankDetailsFunc() {
    this._emitter.emit('showLoader');
    this._bankDetailsService.createBankDetails(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.bankDetailsFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorCreateBankDetailsSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editBankDetailsFunc() {
    this._emitter.emit('showLoader');
    this._bankDetailsService.editBankDetails(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.bankDetailsFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorUpdateBankDetailsSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
