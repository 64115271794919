import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {
  modalRef: BsModalRef;
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient, private _modalService: BsModalService,) { }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  csvCreate(userId, accessToken, csrfToken, searchText, isPublished, currentPageNumber, startDate,endDate) {
    let fd = new FormData();    

    // fd.append("user_id", userId);
    // fd.append("access_token", accessToken);
    // fd.append("order_key",searchForm.orderId);
    // fd.append("gymowner_key",searchForm.gymOwnerId);
    // fd.append("gymbranch_key",searchForm.gymBranchId);
    // fd.append("start_date",searchForm.startDate == null ? '' : this.formatDate(searchForm.startDate));
    // fd.append("end_date",searchForm.endDate == null? '' : this.formatDate(searchForm.endDate));
    // fd.append("page_no", currentPageNumber);

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", searchText);
    fd.append("is_published", isPublished);
    fd.append("page_no", currentPageNumber);
    fd.append("startdate", startDate);
    fd.append("enddate", endDate);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'order-history-csv-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getPageData(userId, accessToken, csrfToken, searchForm, currentPageNumber) {
    let fd = new FormData();    

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("order_key",searchForm.orderId);
    fd.append("gymowner_key",searchForm.gymOwnerId);
    fd.append("gymbranch_key",searchForm.gymBranchId);
    fd.append("start_date",searchForm.startDate == null ? '' : this.formatDate(searchForm.startDate));
    fd.append("end_date",searchForm.endDate == null? '' : this.formatDate(searchForm.endDate));
    fd.append("page_no", currentPageNumber);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'order-history-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  edit(userId, accessToken, csrfToken, formData, searchForm, currentPageNumber) {    
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("order_id",formData.orderId);
    fd.append("product_tax_code",formData.productTaxCode);
    fd.append("principal_amount",formData.principalAmount);
    fd.append("principal_amount_basic",formData.principalAmountBasic);
    fd.append("order_key_to_search",searchForm.orderId);
    fd.append("gym_owner_id",searchForm.gymOwnerId);
    fd.append("gym_branch_id",searchForm.gymBranchId);
    fd.append("start_date",searchForm.startDate == null ? '' : this.formatDate(searchForm.startDate));
    fd.append("end_date",searchForm.endDate == null? '' : this.formatDate(searchForm.endDate));
    fd.append("page_no", currentPageNumber);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'order-history-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  orderHistoryList(userId, accessToken, csrfToken, searchText, isPublished, currentPageNumber, startDate,endDate) {
    let fd = new FormData();    

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", searchText);
    fd.append("is_published", isPublished);
    fd.append("page_no", currentPageNumber);
    fd.append("startdate", startDate);
    fd.append("enddate", endDate);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'customer-order-history-list', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  orderVenderList(userId, accessToken, csrfToken, vendorName) {
    let fd = new FormData();    

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_name", vendorName);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'admin_vendor_list', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }


  orderStatusUpdate(userId,accessToken,csrfToken,orderId, awbCode,sType,statusNote){
    let fd = new FormData();   
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("csrf_token", csrfToken);

    fd.append("order_id", orderId);
    fd.append("awb_code", awbCode);
    fd.append("type", sType);
    fd.append("statusnote", statusNote);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'admin-order-status-update', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));

  }

}
