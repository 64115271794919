import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderCancleManagementService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }
  orderCancelList(userId, accessToken, csrfToken, searchText, isPublished, currentPageNumber) {
    let fd = new FormData();    

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", searchText);
    fd.append("is_published", isPublished);
    fd.append("page_no", currentPageNumber);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'order-cancellation-request-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  changePaidStatus(userId, accessToken, csrfToken, editForm) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("order_id", editForm.requestId);
    fd.append("transaction_id", editForm.bankTransactionId);
    fd.append("return_amount", editForm.returnAmount);
    // fd.append("transaction_id", editForm.confirmBankTransactionId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'order-cancellation-request-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
