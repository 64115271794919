import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrainerVideosListingService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getTrainerVideosList(userId,accessToken,csrfToken,searchText,dataPerPage,pageNo) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", searchText);
    fd.append("data_per_page", dataPerPage);
    fd.append("pageno", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-videos-listing', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));

  }

  deleteTrainerVideo(userId, accessToken, csrfToken, videoKey,searchText,dataPerPage,pageNo) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("video_key", videoKey);
    fd.append("search_text", searchText);
    fd.append("data_per_page", dataPerPage);
    fd.append("pageno", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-videos-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  activeStatusEdit(userId, accessToken, csrfToken, trainerVideoId, activeStatus,searchText,dataPerPage,pageNo) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("video_key", trainerVideoId);
    fd.append("active_status", activeStatus);
    fd.append("data_per_page", dataPerPage);
    fd.append("search_text", searchText);
    fd.append("pageno", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-videos-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
