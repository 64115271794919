import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CmsListingsService } from './cms-listings.service';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';
import { AppVariables } from '../../../app.variables';

@Component({
  selector: 'app-cms-listings',
  templateUrl: './cms-listings.component.html',
  styleUrls: ['./cms-listings.component.scss']
})
export class CmsListingsComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public cmsListingsData: any = [];
  public deleteCmsPageId: string = '';

  constructor(private _modalService: BsModalService, private _cmsListingsService: CmsListingsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables) { }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._cmsListingsService.getCmsListingsData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.cmsListingsData = [];
      }
      else if(resp.status == 200) {
        this.cmsListingsData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  createArrayFromNumber(num: Number) {
    let arr = [];
    for(let i=1; i<=num; i++) {
      arr.push(i);
    }
    return arr;
  }

}
