import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TestimonialListingsService } from './testimonial-listings.service';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';
import { AppVariables } from '../../../app.variables';

@Component({
  selector: 'app-testimonial-listings',
  templateUrl: './testimonial-listings.component.html',
  styleUrls: ['./testimonial-listings.component.scss']
})
export class TestimonialListingsComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public testimonialListingsData: any = [];
  public deleteTestimonialId: string = '';

  constructor(private _modalService: BsModalService, private _testimonialListingsService: TestimonialListingsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this.getListingData();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  createArrayFromNumber(num: Number) {
    let arr = [];
    for(let i=1; i<=num; i++) {
      arr.push(i);
    }
    return arr;
  }

  getListingData() {
    this._emitter.emit('showLoader');
    this._testimonialListingsService.testimonialListingsData(this._userId, this._accessToken, this._csrfToken, 1)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.testimonialListingsData = [];
      }
      else if(resp.status == 200) {
        this.testimonialListingsData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isActiveStatusChanged(testimonialId, activeStatus) {
    this._emitter.emit('showLoader');
    this._testimonialListingsService.activeStatusEdit(this._userId, this._accessToken, this._csrfToken, testimonialId, activeStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.testimonialListingsData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.testimonialListingsActiveStatusChangedSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteTestimonial() {
    this._emitter.emit('showLoader');
    this._testimonialListingsService.deleteTestimonial(this._userId, this._accessToken, this._csrfToken, this.deleteTestimonialId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.testimonialListingsDeletedSuccess
        });
        this.testimonialListingsData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.testimonialListingsDeletedSuccess
        });
        this.testimonialListingsData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
