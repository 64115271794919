import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddEditCmsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  createCmsPage(userId, accessToken, csrfToken, addFormData) {
    let fd = new FormData();
    // console.log(addFormData)
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("page_title", addFormData.pageTitle);
    fd.append("page_data", addFormData.pageDescription);
    fd.append("page_meta_title", addFormData.metaTitle);
    fd.append("page_meta_description", addFormData.metaDescription);
    fd.append("page_meta_keyword", addFormData.metaKeyword);
    fd.append("page_is_publish", addFormData.isPublish);
    fd.append("page_banner_section", JSON.stringify(addFormData.bannerSection));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'cmspage-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  cmsPageDetails(userId, accessToken, csrfToken, pageId) {
    let fd = new FormData();

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("page_id", pageId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'cmspage-details', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editCmsPage(userId, accessToken, csrfToken, pageId, addFormData) {
    // console.log(addFormData.pageDescription);
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("page_id", pageId);
    fd.append("page_name", addFormData.pageTitle);
    fd.append("page_description", addFormData.pageDescription != null ? addFormData.pageDescription.replace(/'/g, "\\'") : addFormData.pageDescription);
    fd.append("page_short_description", addFormData.pageShortDescription != null ? addFormData.pageShortDescription.replace(/'/g, "\\'") : addFormData.pageShortDescription);
    fd.append("meta_title", addFormData.metaTitle);
    fd.append("meta_description", addFormData.metaDescription);
    fd.append("meta_keyword", addFormData.metaKeyword);
    fd.append("is_publish", addFormData.isPublish);
    fd.append("page_banner_section", JSON.stringify(addFormData.bannerSection));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'cmspage-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
