import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AdminVideoListingService } from './admin-video-listing.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
// import { EmbedVideoService } from 'ngx-embed-video';
declare var $: any;

@Component({
  selector: 'app-admin-video-listing',
  templateUrl: './admin-video-listing.component.html',
  styleUrls: ['./admin-video-listing.component.scss']
})
export class AdminVideoListingComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;
  public competitionDeleteId: string = '';
  public detailsModalData: any = {};
  public detailsModalDataVideoUrl: any;
  
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  public pageData: any = [];
  public videoThumnail = this.appVariables.noImageUrl;
  public videoDeleteId :string = '';

  public videoName = '';
  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
  });

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _myUploadedVideosService: AdminVideoListingService, public sanitizer: DomSanitizer/*, public embedService: EmbedVideoService*/, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    this.getPageDataFunc();
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._myUploadedVideosService.getPageData(this._userId, this._accessToken, this._csrfToken,this.searchFormGroup.value.search, this.currentPageNumber)
    .subscribe((resp) => {
      console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }      
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });        
      }
      else if(resp.status == 200 && resp.data != '') {
        this.pageData = resp.data;
        
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        console.log(this.pageData);
        

      }      
    }, (err) => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }else if(err.status == 400){
        this._emitter.emit('showAlert', {
          type: 2,
          msg: err.error.message
        });
      }else{        
        this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
      }
      
    });
  }

  toFixed(value: string, upto = 2) {
    return parseFloat(value).toFixed(upto);
  }
  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  
  
  detailsModal(template: TemplateRef<any>,videoName) {
    this._myUploadedVideosService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{
      
      this.videoThumnail = data[0].thumbnail_small;
      // console.log(data);
    });
    this.detailsModalDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.detailsModalData.video_url.split('/')[3]);
    this.videoName = videoName;
    this.modalRef = this._modalService.show(template);
    // this._myUploadedVideosService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{ 
    //   console.log(data);
    // });
    // this.videoImg(template);
  }
  deleteVideo() {
    this._myUploadedVideosService.getVideoDelete(this._userId,this._accessToken,this._csrfToken,this.videoDeleteId,this.searchFormGroup.value.search,this.currentPageNumber).subscribe(resp=>{
      if(resp.status == 200) {
        this.modalRef.hide();
        this._emitter.emit('showAlert', {
          type: 1,
          msg: "Video Deleted Successfully!"
        });
        this.pageData = resp.data;
        
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      } else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    })
  }



  
  videoImg(template: TemplateRef<any>){
    this._myUploadedVideosService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{ 
      this.videoThumnail = data[0].thumbnail_small;
      this.videoName = data[0].title;
    });
    return this.videoThumnail;
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
  
  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getPageDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getPageDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getPageDataFunc();
  }
  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.getPageDataFunc();
  }


}
