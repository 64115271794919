import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { OrderCancellationRequestsService } from './order-cancellation-requests.service';

@Component({
  selector: 'app-order-cancellation-requests',
  templateUrl: './order-cancellation-requests.component.html',
  styleUrls: ['./order-cancellation-requests.component.scss']
})
export class OrderCancellationRequestsComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public cancellationListingData: any = [];
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;
  public reviewDeleteId: string = '';
  public cancellationDetailsModalData: any = [];
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;
  public cancelSubmitButtonStatus: number = 0;
  public searchFormGroup: FormGroup = this.formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'approveStatus': ['', [Validators.required]]
  });
  public editCancellationFormGroup: FormGroup = this.formBuilder.group({  
    'cancellationId': ['0', [Validators.required]],
    'approveStatus': ['0', [Validators.required]]
  });
  
  constructor(private _modalService: BsModalService,public formBuilder: FormBuilder,private _orderCancellationService: OrderCancellationRequestsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getCancellationListingsFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getCancellationListingsFunc() {
    this._emitter.emit('showLoader');
    this._orderCancellationService.getCancellationRequest(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.cancellationListingData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editCancellationStatusFunc() {
    this._emitter.emit('showLoader');
    this._orderCancellationService.changeCancellationApproveStatus(this._userId, this._accessToken, this._csrfToken, this.editCancellationFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 400) {
        // this._emitter.emit('showAlert', {
        //   type: 2,
        //   msg: this.appVariables.applicationStatusChangedSuccess
        // });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.orderCancellationStatusChangedSuccess
        });
        this.cancellationListingData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        this.modalRef.hide();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.searchFormGroup.controls['verifyStatus'].setValue('');
    this.searchFormGroup.controls['approveStatus'].setValue('');
    this.getCancellationListingsFunc();
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
  
  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  applicationEditData(template: TemplateRef<any>) {
        
    let cancellationStatusDefaultValue = '';
    if(this.cancellationDetailsModalData.approved_status == 1){
      this.editCancellationFormGroup.controls["approveStatus"].setValue(1);
      this.cancelSubmitButtonStatus = 1;
    }else{
      this.cancelSubmitButtonStatus = 0;
    }
    this.editCancellationFormGroup.controls["cancellationId"].setValue(this.cancellationDetailsModalData.cancellation_id);
    // this.editCancellationFormGroup.controls["approveStatus"].setValue(this.cancellationDetailsModalData.approve_status);
    this.modalRef = this._modalService.show(template);
  }

  reviewsDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getCancellationListingsFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getCancellationListingsFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getCancellationListingsFunc();
  }

}
