import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteReviewsService } from './site-reviews.service';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from './../../app.variables';

@Component({
  selector: 'app-site-reviews',
  templateUrl: './site-reviews.component.html',
  styleUrls: ['./site-reviews.component.scss']
})
export class SiteReviewsComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public reviewsListingData: any = [];
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;
  public reviewDeleteId: string = '';
  public editModalData: any = {};

  // public dataPerPage: number = 1;
  public totalData: number = 0;
  // public currentPageNumber: number = 1;

  public searchFormGroup: FormGroup = this.formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'publishStatus': ['0', [Validators.required]]
  });
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  constructor(private _modalService: BsModalService,public formBuilder: FormBuilder,private _siteReviewsListingsService: SiteReviewsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute) { }
  
  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getReviewsListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  getReviewsListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._siteReviewsListingsService.getReviewsListingData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.reviewsListingData = resp.data;
        this.totalData = resp.total;
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isActiveStatusChanged(reviewsId, activeStatus) {
    this._emitter.emit('showLoader');
    this._siteReviewsListingsService.editPublishStatus(this._userId, this._accessToken, this._csrfToken, reviewsId, activeStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymReviewPublishStatusUpdateSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.searchFormGroup.controls['verifyStatus'].setValue('0');
    this.searchFormGroup.controls['publishStatus'].setValue('0');
    this.getReviewsListingsDataFunc();
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }
  
  reviewsEditModal(template: TemplateRef<any>) {
    // console.log("Call::"+this.editModalData);
    this.modalRef = this._modalService.show(template);
  }

  reviewsDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getReviewsListingsDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getReviewsListingsDataFunc();
  }

  deleteConfirmed() {
    this._emitter.emit('showLoader');
    this._siteReviewsListingsService.deleteReview(this._userId, this._accessToken, this._csrfToken, this.reviewDeleteId, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymReviewDeleteSuccessMessage
        });

        this.reviewsListingData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        this.modalRef.hide();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    // this.getListingsDataFunc();
  }

}
