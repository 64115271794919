import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxMsdImageCropperComponent } from './ngx-msd-image-editor/msd-image-cropper.component';

import { LoginService } from './views/login/login.service';
// import { AppConfigs } from './app.configs';
import { AppVariables } from './app.variables';
import { EventEmitter } from 'events';
import { AuthService } from './auth.service';
import { PackageManagementsComponent } from './views/package-managements/package-managements.component';
import { PackageManagementsService } from './views/package-managements/package-managements.service';
import { SiteSettingsComponent } from './views/site-settings/site-settings.component';
import { SiteSettingsService } from './views/site-settings/site-settings.service';
import { SecuritySettingsComponent } from './views/security-settings/security-settings.component';
import { SecuritySettingsService } from './views/security-settings/security-settings.service';
import { CmsListingsComponent } from './views/cms-managements/cms-listings/cms-listings.component';
import { CmsListingsService } from './views/cms-managements/cms-listings/cms-listings.service';
import { AddEditCmsService } from './views/cms-managements/add-edit-cms/add-edit-cms.service';
import { AddEditCmsComponent } from './views/cms-managements/add-edit-cms/add-edit-cms.component';
import { VendorListingsComponent } from './views/vendor-management/vendor-listings/vendor-listings.component';
import { CreateEditVendorComponent } from './views/vendor-management/create-edit-vendor/create-edit-vendor.component';
import { CreateEditVendorService } from './views/vendor-management/create-edit-vendor/create-edit-vendor.service';
import { VendorListingsService } from './views/vendor-management/vendor-listings/vendor-listings.service';
import { GymAmenitiesComponent } from './views/gym-amenities/gym-amenities.component';
import { GymServicesComponent } from './views/gym-services/gym-services.component';
import { GymAmenitiesService } from './views/gym-amenities/gym-amenities.service';
import { GymServicesService } from './views/gym-services/gym-services.service';
import { EnquiryListingsComponent } from './views/vendor-enquiries/enquiry-listings/enquiry-listings.component';
import { EnquiryDetailsComponent } from './views/vendor-enquiries/enquiry-details/enquiry-details.component';
import { EnquiryListingsService } from './views/vendor-enquiries/enquiry-listings/enquiry-listings.service';
import { EnquiryDetailsService } from './views/vendor-enquiries/enquiry-details/enquiry-details.service';
import { GymServicePackagesComponent } from './views/vendor-management/gym-service-packages/gym-service-packages.component';
import { GymServicePackagesService } from './views/vendor-management/gym-service-packages/gym-service-packages.service';
import { HomePartialComponent } from './views/cms-managements/home-partial/home-partial.component';
import { ManageCitiesComponent } from './views/manage-cities/manage-cities.component';
import { ManageCitiesService } from './views/manage-cities/manage-cities.service';
import { PersonalDetailsComponent } from './views/vendor-management/create-edit-vendor/tab-partials/personal-details/personal-details.component';
import { PersonalDetailsService } from './views/vendor-management/create-edit-vendor/tab-partials/personal-details/personal-details.service';
import { CompanyDetailsComponent } from './views/vendor-management/create-edit-vendor/tab-partials/company-details/company-details.component';
import { CompanyDetailsService } from './views/vendor-management/create-edit-vendor/tab-partials/company-details/company-details.service';
import { ProfileImageComponent } from './views/vendor-management/create-edit-vendor/tab-partials/profile-image/profile-image.component';
import { ProfileImageService } from './views/vendor-management/create-edit-vendor/tab-partials/profile-image/profile-image.service';
import { BankDetailsComponent } from './views/vendor-management/create-edit-vendor/tab-partials/bank-details/bank-details.component';
import { BankDetailsService } from './views/vendor-management/create-edit-vendor/tab-partials/bank-details/bank-details.service';


import { CreateEditProductComponent } from './views/vendor-product-management/create-edit-product/create-edit-product.component';
import { BasicDetailsComponent } from './views/vendor-product-management/create-edit-product/tab-partials/basic-details/basic-details.component';
import { ProductImageComponent } from './views/vendor-product-management/create-edit-product/tab-partials/product-image/product-image.component';


import { FeaturesComponent } from './views/vendor-product-management/create-edit-product/tab-partials/features/features.component';
import { VarientComponent } from './views/vendor-product-management/create-edit-product/tab-partials/varient/varient.component';


import { BasicDetailsService } from './views/vendor-product-management/create-edit-product/tab-partials/basic-details/basic-details.service';
import { ProductImageService } from './views/vendor-product-management/create-edit-product/tab-partials/product-image/product-image.service';
import { FeaturesService } from './views/vendor-product-management/create-edit-product/tab-partials/features/features.service';
import { VarientService } from './views/vendor-product-management/create-edit-product/tab-partials/varient/varient.service';
import { CreateEditProductService } from './views/vendor-product-management/create-edit-product/create-edit-product.service';

import { VendorProductListingsComponent } from './views/vendor-product-management/vendor-product-listings/vendor-product-listings.component';
import { VendorProductListingsService } from './views/vendor-product-management/vendor-product-listings/vendor-product-listings.service';
import { TestimonialListingsComponent } from './views/testimonials/testimonial-listings/testimonial-listings.component';
import { CreateTestimonialComponent } from './views/testimonials/create-testimonial/create-testimonial.component';
import { CreateTestimonialService } from './views/testimonials/create-testimonial/create-testimonial.service';
import { TestimonialListingsService } from './views/testimonials/testimonial-listings/testimonial-listings.service';
import { FaqComponent } from './views/cms-managements/faq/faq.component';
import { FaqService } from './views/cms-managements/faq/faq.service';
import { UserApplicationsComponent } from './views/user-applications/user-applications.component';
import { UserApplicationsService } from './views/user-applications/user-applications.service';
import { AccountsManagementComponent } from './views/accounts-management/accounts-management.component';
import { CustomerManagementComponent } from './views/customer-management/customer-management.component';
import { CustomerManagementService } from './views/customer-management/customer-management.service';
import { OrderCancellationRequestsComponent } from './views/order-cancellation-requests/order-cancellation-requests.component';
import { FullGymListingsComponent } from './views/full-gym-listings/full-gym-listings.component';
import { FullGymListingsService } from './views/full-gym-listings/full-gym-listings.service';
import { SiteReviewsComponent } from './views/site-reviews/site-reviews.component';
import { SiteReviewsService } from './views/site-reviews/site-reviews.service';
import { ManageReferralCodeComponent } from './views/manage-referral-code/manage-referral-code.component';
import { ManageDiscountCodeComponent } from './views/manage-discount-code/manage-discount-code.component';
import { ReplacePipe } from './replace.pipe';
import { FitcoinPurchaseComponent } from './views/fitcoin-purchase/fitcoin-purchase.component';
import { ManageReferralCodeService } from './views/manage-referral-code/manage-referral-code.service';
import { ManageDiscountCodeService } from './views/manage-discount-code/manage-discount-code.service';
import { FitcoinPurchaseService } from './views/fitcoin-purchase/fitcoin-purchase.service';
import { FitcoinPurchaseHistoryComponent } from './views/fitcoin-purchase-history/fitcoin-purchase-history.component';
import { FitcoinPurchaseHistoryService } from './views/fitcoin-purchase-history/fitcoin-purchase-history.service';
import { ProductTaxCodeComponent } from './views/product-tax-code/product-tax-code.component';
import { ProductTaxCodeService } from './views/product-tax-code/product-tax-code.service';
import { OrderHistoryComponent } from './views/order-history/order-history.component';
import { OrderHistoryService } from './views/order-history/order-history.service';
import { CompetitionCategoryComponent } from './views/competition-management/competition-category/competition-category.component';
import { CompetitionCategoryService } from './views/competition-management/competition-category/competition-category.service';
import { CreateCompetitionComponent } from './views/competition-management/create-competition/create-competition.component';
import { CompetitionListingComponent } from './views/competition-management/competition-listing/competition-listing.component';
import { ApplicationsComponent } from './views/competition-management/applications/applications.component';
import { CreateCompetitionService } from './views/competition-management/create-competition/create-competition.service';
import { CategoriesComponent } from './views/video-management/categories/categories.component';
import { SubCategoriesComponent } from './views/video-management/sub-categories/sub-categories.component';
import { TrainerSubscriptionsComponent } from './views/video-management/trainer-subscriptions/trainer-subscriptions.component';
import { CustomerPackagesComponent } from './views/video-management/customer-packages/customer-packages.component';
import { TrainerManagementComponent } from './views/trainer-management/trainer-management.component';
import { CategoriesService } from './views/video-management/categories/categories.service';
import { SubCategoriesService } from './views/video-management/sub-categories/sub-categories.service';
import { TrainerSubscriptionsService } from './views/video-management/trainer-subscriptions/trainer-subscriptions.service';
import { CustomerPackagesService } from './views/video-management/customer-packages/customer-packages.service';
import { TrainerManagementService } from './views/trainer-management/trainer-management.service';
import { TrainerAccountsManagementComponent } from './views/trainer-accounts-management/trainer-accounts-management.component';
import { TrainerAccountsManagementService } from './views/trainer-accounts-management/trainer-accounts-management.service';
import { TrainerPurchaseHistoryComponent } from './views/trainer-purchase-history/trainer-purchase-history.component';
import { LiveVideosCategoriesComponent } from './views/live-videos/live-videos-categories/live-videos-categories.component';
import { TrainerVideosComponent } from './views/live-videos/trainer-videos/trainer-videos.component';
import { MyVideosComponent } from './views/live-videos/my-videos/my-videos.component';
import { UploadVideosComponent } from './views/live-videos/upload-videos/upload-videos.component';
import { TrainerVideosListingComponent } from './views/video-management/trainer-videos-listing/trainer-videos-listing.component';
import { AdminVideoUploadComponent } from './views/video-management/admin-video-upload/admin-video-upload.component';
import { AdminVideoListingComponent } from './views/video-management/admin-video-listing/admin-video-listing.component';
import { ComplainVideosComponent } from './views/live-videos/complain-videos/complain-videos.component';
import { AdvertisementsComponent } from './views/video-management/advertisements/advertisement.component';
import { AdvertisementsService } from './views/video-management/advertisements/advertisement.service';
import { NgxMsdPaginationComponent } from './ngx-msd-pagination/ngx-msd-pagination.component';
import { ProductRelatedFeaturesComponent } from './views/product-related-features/product-related-features.component';
import { ProductRelatedFeaturesService } from './views/product-related-features/product-related-features.service';
import { VendorFullProductComponent } from './views/vendor-full-product/vendor-full-product.component';
import { VendorFullProductService } from './views/vendor-full-product/vendor-full-product.service';
import { GstComponent } from './views/gst/gst.component';
import { ProductAttributeListComponent } from './views/product-attribute/product-attribute-list/product-attribute-list.component';
import { ProductAttributeCreateComponent } from './views/product-attribute/product-attribute-create/product-attribute-create.component';
import { CouponComponent } from './views/coupon/coupon.component';
import { CatalougeEnquiryListingsComponent } from './views/vendor-product-management/catalouge-enquiry-listings/catalouge-enquiry-listings.component';
import { ComposeSubscriberComponent } from './views/compose-subscriber/compose-subscriber.component';
import { ProductDealsComponent } from './views/product-deals/product-deals.component';
import { OrderCancleManagementComponent } from './views/order-cancle-management/order-cancle-management.component';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NgxSummernoteModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    BrowserAnimationsModule
    
  ],
  exports:[NgxMsdPaginationComponent],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    NgxMsdImageCropperComponent,
    NgxMsdPaginationComponent,
    SiteSettingsComponent,
    PackageManagementsComponent,
    SecuritySettingsComponent,
    CmsListingsComponent,
    AddEditCmsComponent,
    VendorListingsComponent,
    CreateEditVendorComponent,
    GymAmenitiesComponent,
    GymServicesComponent,
    EnquiryListingsComponent,
    EnquiryDetailsComponent,
    GymServicePackagesComponent,
    HomePartialComponent,
    ManageCitiesComponent,
    PersonalDetailsComponent,
    CompanyDetailsComponent,
    ProfileImageComponent,
    BankDetailsComponent,
    CreateEditProductComponent,
    BasicDetailsComponent,
    ProductImageComponent,
    FeaturesComponent,
    VarientComponent,
    VendorProductListingsComponent,
    TestimonialListingsComponent,
    CreateTestimonialComponent,
    FaqComponent,
    UserApplicationsComponent,
    AccountsManagementComponent,
    CustomerManagementComponent,
    OrderCancellationRequestsComponent,
    FullGymListingsComponent,
    SiteReviewsComponent,
    ManageReferralCodeComponent,
    ReplacePipe,
    ManageDiscountCodeComponent,
    FitcoinPurchaseComponent,
    FitcoinPurchaseHistoryComponent,
    ProductTaxCodeComponent,
    OrderHistoryComponent,
    CompetitionCategoryComponent,
    CreateCompetitionComponent,
    CompetitionListingComponent,
    ApplicationsComponent,
    CategoriesComponent,
    SubCategoriesComponent,
    AdvertisementsComponent,
    TrainerSubscriptionsComponent,
    CustomerPackagesComponent,
    TrainerManagementComponent,
    TrainerAccountsManagementComponent,
    TrainerPurchaseHistoryComponent,
    LiveVideosCategoriesComponent,
    TrainerVideosComponent,
    MyVideosComponent,
    UploadVideosComponent,
    TrainerVideosListingComponent,
    AdminVideoUploadComponent,
    AdminVideoListingComponent,
    ComplainVideosComponent,
    ProductRelatedFeaturesComponent,
    VendorFullProductComponent,
    GstComponent,
    ProductAttributeListComponent,
    ProductAttributeCreateComponent,
    CouponComponent,
    CatalougeEnquiryListingsComponent,
    ComposeSubscriberComponent,
    ProductDealsComponent,
    OrderCancleManagementComponent
  ],
  providers: [
    DatePipe,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
      
    },
    // AppConfigs,
    AppVariables,
    EventEmitter,
    AuthService,
    LoginService,
    PackageManagementsService,
    SiteSettingsService ,
    SecuritySettingsService,
    CmsListingsService,
    AddEditCmsService,
    CreateEditVendorService,
    VendorListingsService,
    GymAmenitiesService,
    GymServicesService,
    EnquiryListingsService,
    EnquiryDetailsService,
    GymServicePackagesService,
    ManageCitiesService,
    PersonalDetailsService,
    CompanyDetailsService,
    ProfileImageService,
    BankDetailsService,
    BasicDetailsService,
    ProductImageService,
    FeaturesService,
    VarientService,
    VendorProductListingsService,
    CreateTestimonialService,
    TestimonialListingsService,
    FaqService,
    UserApplicationsService,
    CustomerManagementService,
    FullGymListingsService,
    SiteReviewsService,
    ManageReferralCodeService,
    ManageDiscountCodeService,
    FitcoinPurchaseService,
    FitcoinPurchaseHistoryService,
    ProductTaxCodeService,
    OrderHistoryService,
    CompetitionCategoryService,
    CreateCompetitionService,
    CategoriesService,
    SubCategoriesService,
    AdvertisementsService,
    TrainerSubscriptionsService,
    CustomerPackagesService,
    TrainerManagementService,
    TrainerAccountsManagementService,
    ProductRelatedFeaturesService,
    VendorFullProductService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
