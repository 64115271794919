import { Component, Inject, OnInit, Input, Output, EventEmitter as EventEmitterCore, TemplateRef } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyDetailsService } from './company-details.service';
import { AppVariables } from '../../../../../app.variables';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public countryListings: any = [];
  public stateListings: any = [];
  public cityListings: any = [];
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public gstinImageChangedEvent: string = this.appVariables.noImageUrl;
  public panImageChangedEvent: string = this.appVariables.noImageUrl;
  public addressImageChangedEvent: string = this.appVariables.noImageUrl;
  public imageUploadType: number = 0; // 1 => gstin, 2 => pan, 3 => address proof 
  public supportiveImageGstinBtnStatus: boolean = true;
  public supportiveImagePanBtnStatus: boolean = true;
  public supportiveImageAddressBtnStatus: boolean = true;
  public modalTitle: string = '';
  public modalImageUrl: string = '';
  public text = ' Best Resolution Size: 274 X 184px';

  public companyDetailsFormGroup: FormGroup = this._formBuilder.group({
    'addressCountry': ['', [Validators.required]],
    'companyName': ['', [Validators.required]],
    'gstinNumber': ['', 
    // [Validators.pattern(/\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Z]{1}[A-Za-z\d]{1}/)]
  ],
    'panNumber': ['', 
    // [Validators.pattern(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/)]
  ],
    'contactNumber': ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
    'addressStreet': ['', [Validators.required]],
    'addressState': ['', [Validators.required]],
    'addressCity': ['', [Validators.required]],
    'addressPin': ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
    'gstinImage': [this.appVariables.noImageUrl, []],
    'panCardImage': [this.appVariables.noImageUrl, []],
    'addressProofImage': [this.appVariables.noImageUrl, []]
  });

  @Input() vendorId: string;
  @Output() responseOutputObj = new EventEmitterCore<any>();

  constructor(private _modalService: BsModalService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, private _formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute, private _companyDetailsService: CompanyDetailsService, public appVariables: AppVariables) { 
    // console.log(this.vendorId);
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this._activatedRoute.params.subscribe((params) => {
        if (typeof params.vendorId !== 'undefined' && params.vendorId != null && params.vendorId != '') {
          this.vendorId = params.vendorId
          this.createOrEdit = 1;
          this.getEditPageData();
          this.companyDetailsFormGroup.controls['gstinImage'].setValue(this.gstinImageChangedEvent);
          this.companyDetailsFormGroup.controls['panCardImage'].setValue(this.panImageChangedEvent);
          this.companyDetailsFormGroup.controls['addressProofImage'].setValue(this.addressImageChangedEvent);
        }
        else {
          this.createOrEdit = 0;
          this.getCreatePageData();
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getStateList() {
    this._emitter.emit('showLoader');
    this._companyDetailsService.stateList(this._userId, this._accessToken, this._csrfToken, this.companyDetailsFormGroup.get('addressCountry').value)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          // console.log(resp);
          this.stateListings = resp.data;
          // this.companyDetailsFormGroup.controls['addressState'].setValue(this.stateListings[0].state_id);
          // this.stateChangedEvent();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }


  getCityList() {
    this._emitter.emit('showLoader');
    this._companyDetailsService.cityList(this._userId, this._accessToken, this._csrfToken, this.companyDetailsFormGroup.get('addressState').value)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');
        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          console.log(resp);
          this.cityListings = resp.data;
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }


  imgDetailsModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  imageFileUpload(evnt) {
    let _this = evnt.target;
    if (_this.files && _this.files[0]) {
      let fr = new FileReader();
      if (_this.files[0].type.indexOf('image/') < 0) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.invalidImageFormat
        });
        return false;
      }
      fr.onload = (evt) => {
        if (this.imageUploadType == 1) {
          this.gstinImageChangedEvent = evt.target['result'].toString();
          this.companyDetailsFormGroup.controls['gstinImage'].setValue(this.gstinImageChangedEvent);
        }
        else if (this.imageUploadType == 2) {
          this.panImageChangedEvent = evt.target['result'].toString();
          this.companyDetailsFormGroup.controls['panCardImage'].setValue(this.panImageChangedEvent);
        }
        else if (this.imageUploadType == 3) {
          this.addressImageChangedEvent = evt.target['result'].toString();
          this.companyDetailsFormGroup.controls['addressProofImage'].setValue(this.addressImageChangedEvent);
        }
        else {
          alert("please upload proper Documents.")
        }

        const invalid = [];
        const controls = this.companyDetailsFormGroup.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push({ name, value: controls[name].value });
          }
        }
        // console.log(invalid);
      }
      fr.readAsDataURL(_this.files[0]);
    }
  }

  getCreatePageData() {
    this._emitter.emit('showLoader');
    this._companyDetailsService.getCompanyDetailsCreatePageData(this._userId, this._accessToken, this._csrfToken)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          // this.responseOutputObj.emit(resp);
          this.countryListings = resp.data.country_listings;
          this.companyDetailsFormGroup.controls['addressCountry'].setValue(this.countryListings[0].country_id);

          // this.stateListings = resp.data.state_listings;
          // this.companyDetailsFormGroup.controls['addressState'].setValue(this.stateListings[0].state_id);
          // this.stateChangedEvent();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._companyDetailsService.getEditData(this._userId, this._accessToken, this._csrfToken, this.vendorId)
      .subscribe((resp) => {
        console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 204) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.noDataFound
          });
          this._router.navigateByUrl('/vendor-management/vendor-listings');
        }
        else if (resp.status == 200) {
          
          this.countryListings = resp.country_listings;
          // this.stateListings = resp.state_listings;
          
          
          // console.log(resp);
          if (resp.data != null) {
            this.companyDetailsFormGroup.controls['addressCountry'].setValue(resp.data.registered_address_country_id);
            this.companyDetailsFormGroup.controls['companyName'].setValue(resp.data.company_name);
            this.companyDetailsFormGroup.controls['gstinNumber'].setValue(resp.data.gstin_number);
            this.companyDetailsFormGroup.controls['panNumber'].setValue(resp.data.pan_number);
            this.companyDetailsFormGroup.controls['contactNumber'].setValue(resp.data.contact_number);
            this.companyDetailsFormGroup.controls['addressStreet'].setValue(resp.data.registered_address_street);
            this.companyDetailsFormGroup.controls['addressState'].setValue(resp.data.registered_address_state_id);
            this.stateChangedEvent();
            this.companyDetailsFormGroup.controls['addressCity'].setValue(resp.data.registered_address_city_id);
            this.companyDetailsFormGroup.controls['addressPin'].setValue(resp.data.registered_address_pin);

            if (resp.data.supportive_image_gstin === null) {
              this.supportiveImageGstinBtnStatus = true;
            }
            else if (!resp.data.is_verified_by_admin) {
              this.supportiveImageGstinBtnStatus = true;
            }
            else {
              this.supportiveImageGstinBtnStatus = false;
            }

            if (resp.data.supportive_image_pan === null) {
              this.supportiveImagePanBtnStatus = true;
            }
            else if (!resp.data.is_verified_by_admin) {
              this.supportiveImagePanBtnStatus = true;
            }
            else {
              this.supportiveImagePanBtnStatus = false;
            }

            if (resp.data.supportive_image_address === null) {
              this.supportiveImageAddressBtnStatus = true;
            }
            else if (!resp.data.is_verified_by_admin) {
              this.supportiveImageAddressBtnStatus = true;
            }
            else {
              this.supportiveImageAddressBtnStatus = false;
            }

            if (resp.data.supportive_image_gstin == null) {
              this.companyDetailsFormGroup.controls['gstinImage'].setValue(this.gstinImageChangedEvent);
            }
            else {
              this.companyDetailsFormGroup.controls['gstinImage'].setValue(
                this.appVariables.uploadImageBasePath + 'vendor/' + this.vendorId + '/' + resp.data.supportive_image_gstin + '?t=' + (new Date().getTime())
              );
            }

            if (resp.data.supportive_image_pan == null) {
              this.companyDetailsFormGroup.controls['panCardImage'].setValue(this.panImageChangedEvent);
            }
            else {
              this.companyDetailsFormGroup.controls['panCardImage'].setValue(
                this.appVariables.uploadImageBasePath + 'vendor/' + this.vendorId + '/' + resp.data.supportive_image_pan + '?t=' + (new Date().getTime())
              );
            }

            if (resp.data.supportive_image_address == null) {
              this.companyDetailsFormGroup.controls['addressProofImage'].setValue(this.addressImageChangedEvent);
            }
            else {
              this.companyDetailsFormGroup.controls['addressProofImage'].setValue(
                this.appVariables.uploadImageBasePath + 'vendor/' + this.vendorId + '/' + resp.data.supportive_image_address + '?t=' + (new Date().getTime())
              );
            }

            this.getStateList();
          }
          else {
            // this.companyDetailsFormGroup.controls['entityType'].setValue(this.entityTypeListings[0].entity_type_id);
            this.companyDetailsFormGroup.controls['addressState'].setValue(this.stateListings[0].state_id);
            this.stateChangedEvent();
          }
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  stateChangedEvent() {
    this._emitter.emit('showLoader');
    this._companyDetailsService.getCityByState(this._userId, this._accessToken, this._csrfToken, this.companyDetailsFormGroup.value.addressState)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          this.cityListings = resp.data;
          if (this.companyDetailsFormGroup.controls['addressCity'].value == '') {
            this.companyDetailsFormGroup.controls['addressCity'].setValue(this.cityListings[0].city_id);
          }
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  companyDetailsFromSubmitted() {
    if (this.createOrEdit == 0) {
      this.createCompanyDetailsFunc();
    }
    else {
      this.editCompanyDetailsFunc();
    }
  }

  createCompanyDetailsFunc() {
    this._emitter.emit('showLoader');

    let formData = this.companyDetailsFormGroup.value;

    if (!(/data:image\/([a-zA-Z]*);base64,([^\"]*)/.test(formData.gstinImage))) {
      formData.gstinImage = null;
    }
    if (!(/data:image\/([a-zA-Z]*);base64,([^\"]*)/.test(formData.panCardImage))) {
      formData.panCardImage = null;
    }
    if (!(/data:image\/([a-zA-Z]*);base64,([^\"]*)/.test(formData.addressProofImage))) {
      formData.addressProofImage = null;
    }

    this._companyDetailsService.createCompanyDetails(this._userId, this._accessToken, this._csrfToken, this.vendorId, formData)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.vendorCreateCompanyDetailsSuccess
          });
          this.responseOutputObj.emit(resp);
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  editCompanyDetailsFunc() {
    this._emitter.emit('showLoader');

    let formData = this.companyDetailsFormGroup.value;

    if (!(/data:image\/([a-zA-Z]*);base64,([^\"]*)/.test(formData.gstinImage))) {
      formData.gstinImage = null;
    }
    if (!(/data:image\/([a-zA-Z]*);base64,([^\"]*)/.test(formData.panCardImage))) {
      formData.panCardImage = null;
    }
    if (!(/data:image\/([a-zA-Z]*);base64,([^\"]*)/.test(formData.addressProofImage))) {
      formData.addressProofImage = null;
    }

    this._companyDetailsService.editCompanyDetails(this._userId, this._accessToken, this._csrfToken, this.vendorId, formData)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.vendorUpdateCompanyDetailsSuccess
          });
          this.responseOutputObj.emit(resp);
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

}
