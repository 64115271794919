import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateTestimonialService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  createTestimonial(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("user_name", formData.userName);
    fd.append("user_designation", formData.userDesignation);
    fd.append("description", formData.description);
    fd.append("is_published", formData.isPublish);
    fd.append("user_pic", JSON.stringify(formData.userPic));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'create-testimonial', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editTestimonialPageData(userId, accessToken, csrfToken, testimonialId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("testimonial_id", testimonialId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'testimonial-edit-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editTestimonial(userId, accessToken, csrfToken, testimonialId, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("testimonial_id", testimonialId);
    fd.append("user_name", formData.userName);
    fd.append("user_designation", formData.userDesignation);
    fd.append("description", formData.description);
    fd.append("is_published", formData.isPublish);
    fd.append("user_pic", JSON.stringify(formData.userPic));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'edit-testimonial', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
