import { Component, Inject, OnInit, Input, Output, EventEmitter as EventEmitterCore } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfileImageService } from './profile-image.service';
import { AppVariables } from '../../../../../app.variables';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  baseUrl = environment.baseUrl;

  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public profilePicImageChangedEvent: string = '';
  public text = ' Best Resolution Size: 274 X 184px';
  public profileImageFormGroup: FormGroup = this._formBuilder.group({
    'profilePic': [{
      source_img: '',
      cropped_img: '',
      crop_data: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
    }, []]
  });

  @Input()vendorId: string;
  @Output() responseOutputObj = new EventEmitterCore<any>();

  constructor(private _profileImageService: ProfileImageService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, private _activatedRoute: ActivatedRoute, private _formBuilder: FormBuilder, public appVariables: AppVariables) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this._activatedRoute.params.subscribe((params) => {
        if(typeof params.vendorId !== 'undefined' && params.vendorId != null && params.vendorId != '') {
          this.createOrEdit = 1;
          this.getEditPageData();
        }
        else {
          this.createOrEdit = 0;

          this.getDataUri(this.baseUrl + 'images/noimage.jpg').then((resp) => {
            this.profileImageFormGroup.controls['profilePic'].setValue({
              source_img: resp['sourceImageUrl'],
              cropped_img: resp['sourceImageUrl'],
              crop_data: {
                x: 0.1,
                y: 0.1,
                w: 0.8,
                h: 0.8
              }
            });
            setTimeout(() => {
              this.profilePicImageChangedEvent = resp['sourceImageUrl'].toString();
            }, 200);
          });
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._profileImageService.profileImageEditData(this._userId, this._accessToken, this._csrfToken, this.vendorId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
      else if(resp.status == 200) {
        let imgUrl = resp.data == null || resp.data.owner_profile_pic_crop_data == null ? this.baseUrl + 'images/noimage.jpg' : this.baseUrl + 'images/uploads/vendor/' + this.vendorId + '/' + resp.data.owner_source_profile_pic;
        
        this.getDataUri(imgUrl).then((res) => {
          this.profileImageFormGroup.controls['profilePic'].setValue({
            source_img: res['sourceImageUrl'],
            cropped_img: res['sourceImageUrl'],
            crop_data: {
              x: resp.data != null && resp.data.owner_profile_pic_crop_data != null ? resp.data.owner_profile_pic_crop_data.x : 0.1,
              y: resp.data != null && resp.data.owner_profile_pic_crop_data != null ? resp.data.owner_profile_pic_crop_data.y : 0.1,
              w: resp.data != null && resp.data.owner_profile_pic_crop_data != null ? resp.data.owner_profile_pic_crop_data.w : 0.8,
              h: resp.data != null && resp.data.owner_profile_pic_crop_data != null ? resp.data.owner_profile_pic_crop_data.h : 0.8
            }
          });
          setTimeout(() => {
            this.profilePicImageChangedEvent = res['sourceImageUrl'].toString();
          }, 200);
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getDataUri(sourceImageFullPath, croppedImageFullPath = '') {
    return new Promise((resolve, reject) => {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          croppedImageUrl: '',
          sourceImageUrl: ''
        },
        imgType = 'source';

      img.crossOrigin = "anonymous";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        if (imgType == 'source') {
          data.sourceImageUrl = canvas.toDataURL('image/'+sourceImageFullPath.split('.')[sourceImageFullPath.split('.').length - 1]);
          if (croppedImageFullPath != '') {
            imgType = 'crop';
            img.src = croppedImageFullPath;
          }
          else {
            resolve(data);
          }
        }
        else if (imgType == 'crop') {
          imgType = '';
          data.croppedImageUrl = canvas.toDataURL('image/'+croppedImageFullPath.split('.')[croppedImageFullPath.split('.').length - 1]);
          resolve(data);
        }
      };
      img.src = sourceImageFullPath;
    });
  }

  profilePicFileUpload(evnt) {
    let _this = evnt.target;
    if (_this.files && _this.files[0]) {
      let fr = new FileReader();
      if(_this.files[0].type.indexOf('image/') < 0) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.invalidImageFormat
        });
        return false;
      }

      fr.onload = (evt) => {
        this.profilePicImageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }
  }

  profilePicCroppedOutput(croppedResult) {
    this.profileImageFormGroup.controls['profilePic'].setValue({
      source_img: croppedResult.sourceImage,
      cropped_img: croppedResult.croppedImage,
      crop_data: {
        x: croppedResult.croppedData.x,
        y: croppedResult.croppedData.y,
        w: croppedResult.croppedData.w,
        h: croppedResult.croppedData.h
      }
    });
  }

  profileImageSaveClicked() {
    if(this.createOrEdit == 0) {
      this.profileImageCreateFunc();
    }
    else {
      this.profileImageEditFunc();
    }
  }

  profileImageCreateFunc() {
    this._emitter.emit('showLoader');
    this._profileImageService.profileImageCreate(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.profileImageFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorCreateProfileImageSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  profileImageEditFunc() {
    this._emitter.emit('showLoader');
    this._profileImageService.profileImageedit(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.profileImageFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorUpdateProfileImageSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
