import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getCompanyDetailsCreatePageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-create-get-company-details-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getCityByState(userId, accessToken, csrfToken, stateId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("state_id", stateId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'get-city-from-state', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createCompanyDetails(userId, accessToken, csrfToken, vendorId, formData) {
    let fd = new FormData();
    console.log(vendorId)
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("address_country", formData.addressCountry);
    fd.append("comapny_name", formData.companyName);
    fd.append("gstin_number", formData.gstinNumber);
    fd.append("pan_number", formData.panNumber);
    fd.append("contact_number", formData.contactNumber);
    fd.append("address_street", formData.addressStreet);
    fd.append("address_state", formData.addressState);
    fd.append("address_city", formData.addressCity);
    fd.append("address_pin", formData.addressPin);
    fd.append("gstin_proof", formData.gstinImage);
    fd.append("pan_proof", formData.panCardImage);
    fd.append("address_proof", formData.addressProofImage);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-create-comapny-details', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getEditData(userId, accessToken, csrfToken, gymOwnerId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", gymOwnerId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-company-details-edit-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editCompanyDetails(userId, accessToken, csrfToken, vendorId, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("address_country", formData.addressCountry);
    fd.append("comapny_name", formData.companyName);
    fd.append("gstin_number", formData.gstinNumber);
    fd.append("pan_number", formData.panNumber);
    fd.append("contact_number", formData.contactNumber);
    fd.append("address_street", formData.addressStreet);
    fd.append("address_state", formData.addressState);
    fd.append("address_city", formData.addressCity);
    fd.append("address_pin", formData.addressPin);
    fd.append("gstin_proof", formData.gstinImage);
    fd.append("pan_proof", formData.panCardImage);
    fd.append("address_proof", formData.addressProofImage);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-edit-company-details', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }


  stateList(userId, accessToken, csrfToken, countrykey, ) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("country_key", countrykey);
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);
    return this._http.post(this.apiBaseUrl + 'get-state-from-country', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  cityList(userId, accessToken, csrfToken, stateKey) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("state_id", stateKey);
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);
    return this._http.post(this.apiBaseUrl + 'get-city-from-state', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
