import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { CreateTestimonialService } from './create-testimonial.service';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-create-testimonial',
  templateUrl: './create-testimonial.component.html',
  styleUrls: ['./create-testimonial.component.scss']
})
export class CreateTestimonialComponent implements OnInit {
  baseUrl = environment.baseUrl;

  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public testimonialId: string = '';
  public config: any = {
    height: 300,
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
    ]
  };
  public imageChangedEvent: string = '';
  public addEditTestimonialForm: FormGroup = this.formBuilder.group({
    'userName': ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
    'userDesignation': ['', [Validators.required]],
    'description': ['', [Validators.required, Validators.maxLength(500)]],
    'isPublish': [true, []],
    'userPic': [{
      source_img: '',
      cropped_img: '',
      crop_data: {
        x: 0.1,
        y: 0.1,
        w: 0.8,
        h: 0.8
      }
    }, []]
  });

  constructor(public formBuilder: FormBuilder,private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _createTestimonialService: CreateTestimonialService, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      
      this._activatedRoute.params.subscribe((param) => {
        if(param.testimonialId != null && param.testimonialId != '') {
          this.testimonialId = param.testimonialId;
          this.getEditPageData();
        }
        else {
          this.getDataUri(this.appVariables.noImageUrl).then((resp) => {
            setTimeout(() => {
              this.imageChangedEvent = resp.toString();
            }, 200);
          });
        }
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._createTestimonialService.editTestimonialPageData(this._userId, this._accessToken, this._csrfToken, this.testimonialId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/testimonials/testimonial-listings');
      }
      else if(resp.status == 200) {
        this.addEditTestimonialForm.controls['userName'].setValue(resp.data.user_name);
        this.addEditTestimonialForm.controls['userDesignation'].setValue(resp.data.user_designation);
        this.addEditTestimonialForm.controls['description'].setValue(resp.data.testimonial_message);
        this.addEditTestimonialForm.controls['isPublish'].setValue(resp.data.active_status);
        this.addEditTestimonialForm.controls['userPic'].setValue({
          source_img: resp.data.testimonial_source_profile_pic,
          cropped_img: resp.data.testimonial_cropped_profile_pic,
          crop_data: {
            x: resp.data.testimonial_profile_pic_crop_data.x,
            y: resp.data.testimonial_profile_pic_crop_data.y,
            w: resp.data.testimonial_profile_pic_crop_data.w,
            h: resp.data.testimonial_profile_pic_crop_data.h
          }
        });
        
        this.getDataUri(this.baseUrl + 'images/uploads/testimonials/' + resp.data.testimonial_source_profile_pic).then((resp) => {
          setTimeout(() => {
            this.imageChangedEvent = resp.toString();
          }, 200);
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/testimonials/testimonial-listings');
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  croppedOutput(croppedResult) {
    this.addEditTestimonialForm.controls['userPic'].setValue({
      source_img: croppedResult.sourceImage,
      cropped_img: croppedResult.croppedImage,
      crop_data: {
        x: croppedResult.croppedData.x,
        y: croppedResult.croppedData.y,
        w: croppedResult.croppedData.w,
        h: croppedResult.croppedData.h
      }
    });
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  getDataUri(sourceImageUrl) {
    return new Promise((resolve, reject) => {
      let canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d"),
          img = new Image();
      
      img.crossOrigin = "anonymous";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      };
      img.src = sourceImageUrl;
    });
  }

  createEditFormSubmit() {
    if(this.testimonialId == '') {
      this.createTestimonialFunc();
    }
    else {
      this.editTestimonialFunc();
    }
  }

  createTestimonialFunc() {
    this._emitter.emit('showLoader');
    this._createTestimonialService.createTestimonial(this._userId, this._accessToken, this._csrfToken, this.addEditTestimonialForm.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.testimonialCreateSuccess
        });
        this._router.navigateByUrl('/testimonials/testimonial-listings');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editTestimonialFunc() {
    this._emitter.emit('showLoader');
    this._createTestimonialService.editTestimonial(this._userId, this._accessToken, this._csrfToken, this.testimonialId, this.addEditTestimonialForm.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.testimonialEditSuccess
        });
        this._router.navigateByUrl('/testimonials/testimonial-listings');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
