import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductImageService {

  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  productImagesCreate(userId, accessToken, csrfToken, vendorId, productId, productCropData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);
    fd.append("product_image", JSON.stringify(productCropData.productImagesArr));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-images-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  productImagesEditGetData(userId, accessToken, csrfToken, vendorId, productId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-images-edit-page-get-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  productImagesEdit(userId, accessToken, csrfToken, vendorId, productId, productCropData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);
    fd.append("product_image", JSON.stringify(productCropData.productImagesArr));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-images-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
