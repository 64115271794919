import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LiveVideoCategoryService } from './live-video-category.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-live-videos-categories',
  templateUrl: './live-videos-categories.component.html',
  styleUrls: ['./live-videos-categories.component.scss']
})
export class LiveVideosCategoriesComponent implements OnInit {
  baseUrl = environment.baseUrl;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public videoCategoryFormGroup: FormGroup = this._formBuilder.group({
    'categoryName': ['', [Validators.required, Validators.maxLength(120)]],    
    'isPublished': [true, [Validators.required]],
    'categoryImage': [{
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    }, []]
  });
  public pageData: any = [];
  public editId: string = '';
  public imageChangedEvent: string = '';
  public deleteId: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _categoriesService: LiveVideoCategoryService, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  deleteFunc() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._categoriesService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoCategoryDeletedSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._categoriesService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  categoryIconCroppedOutput(evnt) {
    this.videoCategoryFormGroup.controls['categoryImage'].setValue({
      cropped_image_url: evnt.croppedImage,
      source_image_url: evnt.sourceImage,
      crop_data: {
        x: evnt.croppedData.x,
        y: evnt.croppedData.y,
        w: evnt.croppedData.w,
        h: evnt.croppedData.h
      }
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getDataUri(imgObj) {
    let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          cropped_image_url: '',
          source_image_url: '',
          crop_data: {
            x: '',
            y: '',
            w: '',
            h: ''
          }
        },
        imgType = 'source';
    
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      if(imgType == 'source') {
        imgType = 'crop';
        data.source_image_url = canvas.toDataURL();
        img.src = this.baseUrl + 'images/uploads/live-video-category/' + imgObj.category_icon_source;
        this.imageChangedEvent = data.source_image_url;
      }
      else if(imgType == 'crop') {
        imgType = '';
        data.cropped_image_url = canvas.toDataURL();
        this.videoCategoryFormGroup.controls['categoryImage'].setValue({
          cropped_image_url: data.cropped_image_url,
          source_image_url: data.source_image_url,
          crop_data: {
            x: imgObj.category_icon_crop_data.x,
            y: imgObj.category_icon_crop_data.y,
            w: imgObj.category_icon_crop_data.w,
            h: imgObj.category_icon_crop_data.h
          }
        });
      }
    };
    img.src = this.baseUrl + 'images/uploads/live-video-category/' + imgObj.category_icon_source;
  }
  formSubmitted() {
    if(this.editId != '') {
      this.editFunc();
    }
    else {
      this.createFunc();
    }
  }

  createFunc() {
    this._emitter.emit('showLoader');
    this._categoriesService.create(this._userId, this._accessToken, this._csrfToken, this.videoCategoryFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoCategoryCreateSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else if(resp.status == 409) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.videoCategotyExists
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editFunc() {
    this._emitter.emit('showLoader');
    this._categoriesService.edit(this._userId, this._accessToken, this._csrfToken, this.editId, this.videoCategoryFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoCategoryEditedSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.editId = data.video_category_id;
    this.videoCategoryFormGroup.controls['categoryName'].setValue(data.category_name);
    this.videoCategoryFormGroup.controls['isPublished'].setValue(data.active_status);
    this.getDataUri({
      category_icon_source: data.category_icon_source,
      category_icon_crop_data: data.category_icon_crop_data
    })
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.editId = '';
    this.videoCategoryFormGroup.reset({
      isPublished: true
    });
    this.videoCategoryFormGroup.controls['categoryImage'].setValue({
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    });
    this.imageChangedEvent = '';
    document.getElementsByClassName('input-file')[0]['value'] = '';
  }


}
