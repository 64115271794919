import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CreateEditProductService {

  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient, router: Router) {
  }

  updatePublishStatus(userId, accessToken, csrfToken, productId, publishStatus) {
    let fd = new FormData();

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_id", productId);
    fd.append("publish_status", publishStatus);

    let headers = new HttpHeaders()
      .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-update-publish-status', fd, { headers: headers })
      .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }


  getEditPageData(userId, accessToken, csrfToken, vendorId, productId) {
    let fd = new FormData();

    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);

    let headers = new HttpHeaders()
      .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-details-edit-page-get-data', fd, { headers: headers })
      .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
