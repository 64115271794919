import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { OrderCancleManagementService } from './order-cancle-management.service'
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-order-cancle-management',
  templateUrl: './order-cancle-management.component.html',
  styleUrls: ['./order-cancle-management.component.css']
})
export class OrderCancleManagementComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public username: string = '';
  
  public currentPageNumber: number = 1;
  public dataPerPage: number = 50;  
  public totalData: number = 0;
  public pageData: any = [];
  productList: any =[];
  public address: any= {};
  public detailsModalData: any = [];

  public editPaymentStatusFormGroup: FormGroup = this.formBuilder.group({  
    'requestId': ['0', [Validators.required]],
    'paymentStatus': [ 0, [Validators.required]],
    'bankTransactionId': ['', [this.confirmTransIdValidation]],
    'confirmBankTransactionId': ['', [this.confirmTransIdValidation]],
    'returnAmount': [0, [Validators.required]]
  });

  public searchFormGroup: FormGroup = this._formBuilder.group({
    'orderId': ['', [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
    'gymOwnerId': ['', [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
    'gymBranchId': ['', [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
    'startDate': [null, []],
    'endDate': [null, []],    
    'verifyStatus': ['0', []],
    'approveStatus': ['', []],
    'search': ['', []],
    'publishStatus': ['0', [Validators.required]]
  });
  constructor(private _formBuilder: FormBuilder, private _orderCancleManagementService: OrderCancleManagementService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService) { }

  confirmTransIdValidation(control: FormControl) {
    if (typeof control.parent !== 'undefined') {
      if (control.value == '') {
        return { errorMessage: 'This field is mandatory' };
      }
      else if (control.parent.controls['bankTransactionId'].value != control.parent.controls['confirmBankTransactionId'].value) {
        // control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
        return { errorMessage: 'This field is mandatory' };
        // return null;
      }else if (control.parent.controls['bankTransactionId'].value == control.parent.controls['confirmBankTransactionId'].value) {
        // control.parent.controls['accountNumber'].setErrors({ 'incorrect': true });
        control.parent.controls['confirmBankTransactionId'].setErrors(null);
        control.parent.controls['bankTransactionId'].setErrors(null);
        return null;
      }
      else {
        control.parent.controls['confirmAccountNumber'].setErrors(null);
        return null;
      }
    }
  }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.username = userDetails.user_name;
      // this.getPageDataFunc();
      this.orderCancelListFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  orderCancelListFunc() {    
    this._emitter.emit('showLoader');
    this._orderCancleManagementService.orderCancelList(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value.search, this.searchFormGroup.value.publishStatus, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        console.log(this.pageData)
        this.dataPerPage = resp.data_per_page;
        this.totalData = resp.total;
        this.pageData.forEach(element => {
          element.totalGst = 0;
          element.product_list.forEach(product => {
            
            element.totalGst = element.totalGst + product.gst_price;

          });
          element.totalGst = element.totalGst.toFixed(2);
        });

        // this.gstPrice = + resp.data.product_list[4].gst_price;
        console.log("this.pageData",this.pageData);
        // this.orderStatus = resp.data.transaction_status;
        // this.productList  = resp.data[0].product_list;
        // console.log("---",this.productList);
        // this.productTaxCodesListing = resp.product_tax_codes_listing;

        // this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        // this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  viewDetailsModal(template: TemplateRef<any>, productList, data) {
    this.editPaymentStatusFormGroup.controls["requestId"].setValue(data.invoice_id);
    this.editPaymentStatusFormGroup.controls["returnAmount"].setValue(data.payable_amount);
    this.modalRef = this._modalService.show(template, Object.assign({}, { class: 'modal-lg' }) );   
    this.productList = productList; 
    this.address = data;

  }

  changePaidStatusFunc() {
    this._emitter.emit('showLoader');
    this._orderCancleManagementService.changePaidStatus(this._userId, this._accessToken, this._csrfToken, this.editPaymentStatusFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          // msg: this.appVariables.applicationStatusChangedSuccess
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        // this.accountManagementsListingData = resp.balance_listings.data;
        // this.totalData = resp.balance_listings.data.total;
        // this.dataPerPage = resp.balance_listings.data.data_per_page;        

        // this.numberOfPages = (resp.balance_listings.data.total % resp.balance_listings.data.data_per_page != 0) ? parseInt((resp.balance_listings.data.total / resp.balance_listings.data.data_per_page).toString()) + 1 : resp.balance_listings.data.total / resp.balance_listings.data.data_per_page;
        // this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        console.log("resp-",resp)
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.paymentStatusChangedSuccess
        });
        this.modalRef.hide();
        this.orderCancelListFunc();
        // alert("Payment details submitted successful and invoice sent to the vendor.")
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.paymentStatusChangedError
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }


  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    // this.getListingsDataFunc();
  }

}
