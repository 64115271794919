import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import{ SecuritySettingsService } from './security-settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.scss']
})
export class SecuritySettingsComponent implements OnInit {
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public changeEmailFormGroup: FormGroup = this._formBuilder.group({
    'oldEmail': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
    'newEmail': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
    'confirmEmail': ['', [Validators.required]],
  });
  public changePasswordFormGroup: FormGroup = this._formBuilder.group({
    'oldPassword': ['', [Validators.required]],
    'newPassword': ['', [Validators.required]],
    'confirmPassword': ['', [Validators.required]],
  });
  constructor(private _formBuilder: FormBuilder,private _securitySettingsService:SecuritySettingsService,private _activatedRoute: ActivatedRoute,private _router: Router,public appVariables: AppVariables,@Inject(EventEmitter) private _emitter: EventEmitter) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  emailFormSubmitted() {
    this._emitter.emit('showLoader');
    this._securitySettingsService.changeLoginEmail(this._userId, this._accessToken, this._csrfToken, this.changeEmailFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
         msg: this.appVariables.securitySettingsInvalidOldEmail
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
         msg: this.appVariables.securitySettingsEmailEditSuccess
        });
        this.changeEmailFormGroup.reset();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  passwordFormSubmitted() {
    this._emitter.emit('showLoader');
    this._securitySettingsService.changeLoginPassword(this._userId, this._accessToken, this._csrfToken, this.changePasswordFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
         msg: this.appVariables.securitySettingsInvalidOldPassword
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
         msg: this.appVariables.securitySettingsPasswordEditSuccess
        });
        this.changePasswordFormGroup.reset();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
