import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GymServicePackagesService {
  apiBaseUrl = environment.apiBaseUrl;
  
  constructor(private _http: HttpClient) { }

  getServicePackageListings(userId, accessToken, csrfToken, gymId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("gym_id", gymId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gym-service-package-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createServicePackage(userId, accessToken, csrfToken, gymId, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("gym_id", gymId);
    fd.append("service_id", formData.packageService);
    fd.append("package_text", formData.packageText);
    fd.append("no_of_days", formData.numberOfDays);
    fd.append("package_price", formData.packagePrice);
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gym-service-package-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editServicePackage(userId, accessToken, csrfToken, servicePackageId, gymId, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("service_package_id", servicePackageId);
    fd.append("gym_id", gymId);
    fd.append("service_id", formData.packageService);
    fd.append("package_text", formData.packageText);
    fd.append("no_of_days", formData.numberOfDays);
    fd.append("package_price", formData.packagePrice);
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gym-service-package-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteServicePackage(userId, accessToken, csrfToken, servicePackageId, gymId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("service_package_id", servicePackageId);
    fd.append("gym_id", gymId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gym-service-package-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
