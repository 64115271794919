import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppVariables } from '../../app.variables';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { GymAmenitiesService } from './gym-amenities.service';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-gym-amenities',
  templateUrl: './gym-amenities.component.html',
  styleUrls: ['./gym-amenities.component.scss']
})
export class GymAmenitiesComponent implements OnInit {
  baseUrl = environment.baseUrl;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public addEditAmenitiesFormGroup: FormGroup = this._formBuilder.group({
    'amenityName': ['', [Validators.required]],
    'isPublished': [true, [Validators.required]],
    'amenityImage': [{
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    }, []]
  });

  public amenityListings: any = [];
  public amenityEditId: string = '';
  public imageChangedEvent: string = '';
  public amenityDeleteId: string = '';
 
  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _gymAmenitiesService: GymAmenitiesService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._gymAmenitiesService.getAmenityListingsData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.amenityListings = [];
      }
      else if(resp.status == 200) {
        this.amenityListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  amenityIconCroppedOutput(evnt) {
    this.addEditAmenitiesFormGroup.controls['amenityImage'].setValue({
      cropped_image_url: evnt.croppedImage,
      source_image_url: evnt.sourceImage,
      crop_data: {
        x: evnt.croppedData.x,
        y: evnt.croppedData.y,
        w: evnt.croppedData.w,
        h: evnt.croppedData.h
      }
    });
  }

  resetForm() {
    this.amenityEditId = '';
    this.addEditAmenitiesFormGroup.controls['amenityName'].setValue('');
    this.addEditAmenitiesFormGroup.controls['isPublished'].setValue(true);
    this.addEditAmenitiesFormGroup.controls['amenityImage'].setValue({
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    });
    this.imageChangedEvent = '';
    document.getElementsByClassName('input-file')[0]['value'] = '';
  }

  getDataUri(imgObj) {
    let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          cropped_image_url: '',
          source_image_url: '',
          crop_data: {
            x: '',
            y: '',
            w: '',
            h: ''
          }
        },
        imgType = 'source';
    
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      if(imgType == 'source') {
        imgType = 'crop';
        data.source_image_url = canvas.toDataURL();
        img.src = this.baseUrl + 'images/uploads/gym-amenities/' + imgObj.amenity_icon;
        this.imageChangedEvent = data.source_image_url;
      }
      else if(imgType == 'crop') {
        imgType = '';
        data.cropped_image_url = canvas.toDataURL();
        this.addEditAmenitiesFormGroup.controls['amenityImage'].setValue({
          cropped_image_url: data.cropped_image_url,
          source_image_url: data.source_image_url,
          crop_data: {
            x: imgObj.amenity_icon_crop_data.x,
            y: imgObj.amenity_icon_crop_data.y,
            w: imgObj.amenity_icon_crop_data.w,
            h: imgObj.amenity_icon_crop_data.h
          }
        });
      }
    };
    img.src = this.baseUrl + 'images/uploads/gym-amenities/' + imgObj.amenity_icon_source;
  }

  setEditData(data) {
    this.amenityEditId = data.amenity_id;
    this.addEditAmenitiesFormGroup.controls['amenityName'].setValue(data.amenity_name);
    this.addEditAmenitiesFormGroup.controls['isPublished'].setValue(data.active_status);
    this.getDataUri({
      amenity_icon: data.amenity_icon,
      amenity_icon_source: data.amenity_icon_source,
      amenity_icon_crop_data: data.amenity_icon_crop_data
    })
    window.scrollTo(0, 0);
  }

  createEditFormSubmit() {
    if(this.amenityEditId != '') {
      this.editAmenity();
    }
    else {
      this.createAmenity();
    }
  }

  createAmenity() {
    this._emitter.emit('showLoader');
    this._gymAmenitiesService.createAmenityFunc(this._userId, this._accessToken, this._csrfToken, this.addEditAmenitiesFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymAmenitiesCreateSuccess
        });
        this.amenityListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editAmenity() {
    this._emitter.emit('showLoader');
    this._gymAmenitiesService.editAmenityFunc(this._userId, this._accessToken, this._csrfToken, this.amenityEditId, this.addEditAmenitiesFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymAmenitiesEditedSuccess
        });
        this.amenityListings = resp.data;
        this.resetForm();
        this.amenityEditId = '';
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  
  deleteGymAmenities() {
    this._emitter.emit('showLoader');
    this.resetForm();
    this.modalRef.hide()
    this._gymAmenitiesService.deletePackage(this._userId, this._accessToken, this._csrfToken, this.amenityDeleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymAmenitiesDeletedSuccess
        });
        this.amenityListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymAmenitiesDeletedSuccess
        });
        this.amenityListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
}
