import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { SiteSettingsComponent } from './views/site-settings/site-settings.component';
import { AuthService as AuthGaurd } from './auth.service';
import { PackageManagementsComponent } from './views/package-managements/package-managements.component';
import { SecuritySettingsComponent } from './views/security-settings/security-settings.component';
import { CmsListingsComponent } from './views/cms-managements/cms-listings/cms-listings.component';
import { AddEditCmsComponent } from './views/cms-managements/add-edit-cms/add-edit-cms.component';
import { VendorListingsComponent } from './views/vendor-management/vendor-listings/vendor-listings.component';
import { CreateEditVendorComponent } from './views/vendor-management/create-edit-vendor/create-edit-vendor.component';
import { GymAmenitiesComponent } from './views/gym-amenities/gym-amenities.component';
import { GymServicesComponent } from './views/gym-services/gym-services.component';
import { EnquiryListingsComponent } from './views/vendor-enquiries/enquiry-listings/enquiry-listings.component';
import { EnquiryDetailsComponent } from './views/vendor-enquiries/enquiry-details/enquiry-details.component';
import { GymServicePackagesComponent } from './views/vendor-management/gym-service-packages/gym-service-packages.component';
import { ManageCitiesComponent } from './views/manage-cities/manage-cities.component';
import { VendorProductListingsComponent } from './views/vendor-product-management/vendor-product-listings/vendor-product-listings.component';
import { CreateTestimonialComponent } from './views/testimonials/create-testimonial/create-testimonial.component';
import { TestimonialListingsComponent } from './views/testimonials/testimonial-listings/testimonial-listings.component';
import { FaqComponent } from './views/cms-managements/faq/faq.component';
import { UserApplicationsComponent } from './views/user-applications/user-applications.component'
import { AccountsManagementComponent } from './views/accounts-management/accounts-management.component';
import { CustomerManagementComponent } from './views/customer-management/customer-management.component';
import { OrderCancellationRequestsComponent } from './views/order-cancellation-requests/order-cancellation-requests.component';
import { FullGymListingsComponent } from './views/full-gym-listings/full-gym-listings.component';
import { SiteReviewsComponent } from './views/site-reviews/site-reviews.component';
import { ManageReferralCodeComponent } from './views/manage-referral-code/manage-referral-code.component';
import { ManageDiscountCodeComponent } from './views/manage-discount-code/manage-discount-code.component';
import { FitcoinPurchaseComponent } from './views/fitcoin-purchase/fitcoin-purchase.component';
import { FitcoinPurchaseHistoryComponent } from './views/fitcoin-purchase-history/fitcoin-purchase-history.component';
import { ProductTaxCodeComponent } from './views/product-tax-code/product-tax-code.component';
import { OrderHistoryComponent } from './views/order-history/order-history.component';
import { CompetitionCategoryComponent } from './views/competition-management/competition-category/competition-category.component';
import { CompetitionListingComponent } from './views/competition-management/competition-listing/competition-listing.component';
import { CreateCompetitionComponent } from './views/competition-management/create-competition/create-competition.component';
import { ApplicationsComponent } from './views/competition-management/applications/applications.component';
import { CategoriesComponent } from './views/video-management/categories/categories.component';
import { SubCategoriesComponent } from './views/video-management/sub-categories/sub-categories.component';
import { CustomerPackagesComponent } from './views/video-management/customer-packages/customer-packages.component';
import { TrainerSubscriptionsComponent } from './views/video-management/trainer-subscriptions/trainer-subscriptions.component';
import { TrainerManagementComponent } from './views/trainer-management/trainer-management.component';
import { TrainerAccountsManagementComponent } from './views/trainer-accounts-management/trainer-accounts-management.component';
import { TrainerPurchaseHistoryComponent } from './views/trainer-purchase-history/trainer-purchase-history.component';
import { LiveVideosCategoriesComponent } from './views/live-videos/live-videos-categories/live-videos-categories.component';
import { TrainerVideosComponent } from './views/live-videos/trainer-videos/trainer-videos.component';
import { MyVideosComponent } from './views/live-videos/my-videos/my-videos.component';
import { UploadVideosComponent } from './views/live-videos/upload-videos/upload-videos.component';
import { TrainerVideosListingComponent } from './views/video-management/trainer-videos-listing/trainer-videos-listing.component';
import { AdminVideoUploadComponent } from './views/video-management/admin-video-upload/admin-video-upload.component';
import { AdminVideoListingComponent } from './views/video-management/admin-video-listing/admin-video-listing.component';
import { ComplainVideosComponent } from './views/live-videos/complain-videos/complain-videos.component';
import { AdvertisementsComponent } from './views/video-management/advertisements/advertisement.component';
import { ProductRelatedFeaturesComponent } from './views/product-related-features/product-related-features.component';
import { CreateEditProductComponent } from './views/vendor-product-management/create-edit-product/create-edit-product.component';
import { VendorFullProductComponent } from './views/vendor-full-product/vendor-full-product.component';
import { GstComponent } from './views/gst/gst.component';
import { ProductAttributeListComponent } from './views/product-attribute/product-attribute-list/product-attribute-list.component';
import { ProductAttributeCreateService } from './views/product-attribute/product-attribute-create/product-attribute-create.service';
import { ProductAttributeCreateComponent } from './views/product-attribute/product-attribute-create/product-attribute-create.component';
import { CouponComponent } from './views/coupon/coupon.component';
import { CatalougeEnquiryListingsComponent } from './views/vendor-product-management/catalouge-enquiry-listings/catalouge-enquiry-listings.component';
import { ComposeSubscriberComponent } from './views/compose-subscriber/compose-subscriber.component';
import { ProductDealsComponent } from './views/product-deals/product-deals.component';
import { OrderCancleManagementComponent } from './views/order-cancle-management/order-cancle-management.component';
// import { DashboardComponent } from './views/dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGaurd],
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    // canActivate: [AuthGaurd],
    canActivateChild: [AuthGaurd],
    children: [
      // {
      //   path: 'base',
      //   loadChildren: './views/base/base.module#BaseModule'
      // },
      // {
      //   path: 'buttons',
      //   loadChildren: './views/buttons/buttons.module#ButtonsModule'
      // },
      // {
      //   path: 'charts',
      //   loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      // },
      {
        path: 'dashboard',
        loadChildren : ()=> import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   path: 'icons',
      //   loadChildren: './views/icons/icons.module#IconsModule'
      // },
      // {
      //   path: 'notifications',
      //   loadChildren: './views/notifications/notifications.module#NotificationsModule'
      // },
      // {
      //   path: 'theme',
      //   loadChildren: './views/theme/theme.module#ThemeModule'
      // },
      // {
      //   path: 'widgets',
      //   loadChildren: './views/widgets/widgets.module#WidgetsModule'
      // },
      {
        path: 'site-settings',
        component: SiteSettingsComponent,
        data: {
          title: 'Site Settings'
        }
      },
      {
        path: 'gst-settings',
        component: GstComponent,
        data: {
          title: 'GST Settings'
        }
      },
      {
        path: 'product-attribute-list',
        component: ProductAttributeListComponent,
        data: {
          title: 'Product Attribute Management'
        }
      },
      {
        path: 'product-attribute-create',
        component: ProductAttributeCreateComponent,
        data: {
          title: 'Product Attribute Management'
        }
      },
      {
        path: 'product-attribute-edit/:productAttribiteId',
        component: ProductAttributeCreateComponent,
        data: {
          title: 'Product Attribute Management'
        }
      },
      // {
      //   path: 'dashboard',
      //   component: DashboardComponent,
      //   data: {
      //     title: 'Dashboard'
      //   }
      // },
      {
        path: 'package-management',
        component: PackageManagementsComponent,
        data: {
          title: 'Package Management Page'
        }
      },
      {
        path: 'security-settings',
        component: SecuritySettingsComponent,
        data: {
          title: 'Security Settings'
        }
      },
      {
        path: 'cms-management/cms-listings',
        component: CmsListingsComponent,
        data: {
          title: 'CMS Listing'
        }
      },
      {
        path: 'cms-management/cms-edit/:cmsId',
        component: AddEditCmsComponent,
        data: {
          title: 'CMS Edit'
        }
      },
      {
        path: 'cms-management/faq',
        component: FaqComponent,
        data: {
          title: 'FAQ Management'
        }
      },
      {
        path: 'vendor-management/vendor-listings',
        component: VendorListingsComponent,
        data: {
          title: 'Vendor Listing'
        }
      },
      {
        path: 'vendor-management/create-vendor',
        component: CreateEditVendorComponent,
        data: {
          title: 'Create Vendor'
        }
      },
      {
        path: 'vendor-management/edit-vendor/:vendorId',
        component: CreateEditVendorComponent,
        data: {
          title: 'Edit Vendor'
        }
      },
      {
        path: 'vendor-management/vendor-service-packages/:gymId',
        component: GymServicePackagesComponent,
        data: {
          title: 'Gym Service Packages'
        }
      },
      {
        path: 'branch-full-listings',
        component: FullGymListingsComponent,
        data: {
          title: 'Branch Full Listing'
        }
      },
      {
        path: 'vendor-product-management/create-product/:vendorId',
        component: CreateEditProductComponent,
        data: {
          title: 'Create Product'
        }
      },
      {
        path: 'vendor-product-management/edit-product/:vendorId/:productId',
        component: CreateEditProductComponent,
        data: {
          title: 'Edit Product'
        }
      },
      {
        path: 'vendor-product-management/product-listings/:vendorId',
        component: VendorProductListingsComponent,
        data: {
          title: 'Product Listing'
        }
      },
      {
        path: 'vendor-product-management/enquiry-listings',
        component: CatalougeEnquiryListingsComponent,
        data: {
          title: 'Enquiry Listing'
        }
      },
      {
        path: 'newsletter-compose',
        component: ComposeSubscriberComponent,
        data: {
          title: 'Newsletter Compose'
        }
      },
      {
        path: 'product-related-features',
        component: ProductRelatedFeaturesComponent,
        data: {
          title: 'Product Related Features'
        }
      },
      {
        path: 'product-full-listings',
        component: VendorFullProductComponent,
        data: {
          title: 'Product Full Listing'
        }
      },
      {
        path: 'manage-cities',
        component: ManageCitiesComponent,
        data: {
          title: 'Manage Cities'
        }
      },
      {
        path: 'manage-referral-code',
        component: ManageReferralCodeComponent,
        data: {
          title: 'Manage Referral Code'
        }
      },
      {
        path: 'manage-discount-code',
        component: ManageDiscountCodeComponent,
        data: {
          title: 'Manage Discount Code'
        }
      },
      {
        path: 'fitcoin-purchase',
        component: FitcoinPurchaseComponent,
        data: {
          title: 'Fitcoin Purchase Packages'
        }
      },
      {
        path: 'fitcoin-purchase-history',
        component: FitcoinPurchaseHistoryComponent,
        data: {
          title: 'Fitcoin Purchase History'
        }
      },
      {
        path: 'testimonials/create-testimonial',
        component: CreateTestimonialComponent,
        data: {
          title: 'Create Testimonial'
        }
      },
      {
        path: 'testimonials/edit-testimonial/:testimonialId',
        component: CreateTestimonialComponent,
        data: {
          title: 'Edit Testimonial'
        }
      },
      {
        path: 'testimonials/testimonial-listings',
        component: TestimonialListingsComponent,
        data: {
          title: 'Testimonial Listing'
        }
      },
      {
        path: 'user-application',
        component: UserApplicationsComponent,
        data: {
          title: 'User Application'
        }
      },
      {
        path: 'account-managements',
        component: AccountsManagementComponent,
        data: {
          title: 'Vendor Payments'
        }
      },
      {
        path: 'coupon-managements',
        component: CouponComponent,
        data: {
          title: 'Coupon'
        }
      },
      {
        path: 'customer-managements',
        component: CustomerManagementComponent,
        data: {
          title: 'Customer Managements'
        }
      },
      {
        path: 'product-reviews',
        component: SiteReviewsComponent,
        data: {
          title: 'Product Reviews'
        }
      },
      {
        path: 'product-deals',
        component: ProductDealsComponent,
        data: {
          title: 'Product Deals'
        }
      },
      {
        path: 'product-tax-code',
        component: ProductTaxCodeComponent,
        data: {
          title: 'Product Tax Code'
        }
      },
      {
        path: 'order-history',
        component: OrderHistoryComponent,
        data: {
          title: 'Order History'
        }
      }, 
      {
        path: 'order-list',
        component: OrderCancleManagementComponent,
        data: {
          title: 'Order List'
        }
      }, 
      {
        path: 'trainer-purchase-history',
        component: TrainerPurchaseHistoryComponent,
        data: {
          title: 'Trainer Purchase History'
        }
      },      
      {
        path: 'competition-management/competition-category',
        component: CompetitionCategoryComponent,
        data: {
          title: 'Challenges Category'
        }
      },      
      {
        path: 'competition-management/competition-create',
        component: CreateCompetitionComponent,
        data: {
          title: 'Challenges Create'
        }
      },
      {
        path: 'competition-management/competition-create/:competition_id',
        component: CreateCompetitionComponent,
        data: {
          title: 'Challenges Edit'
        }
      },
      {
        path: 'competition-management/competition-listing',
        component: CompetitionListingComponent,
        data: {
          title: 'Challenges Listing'
        }
      },
      {
        path: 'competition-management/applications',
        component: ApplicationsComponent,
        data: {
          title: 'Challenges Applications'
        }
      },
      {
        path: 'product/categories',
        component: CategoriesComponent,
        data: {
          title: 'Categories'
        }
      },
      {
        path: 'product/sub-categories',
        component: SubCategoriesComponent,
        data: {
          title: 'Sub Categories'
        }
      },
      {
        path: 'advertisements',
        component: AdvertisementsComponent,
        data: {
          title: 'Advertisement'
        }
      },
      {
        path: 'video-management/customer-packages',
        component: CustomerPackagesComponent,
        data: {
          title: 'Customer Subscription Packages'
        }
      },
      {
        path: 'video-management/trainer-subscriptions',
        component: TrainerSubscriptionsComponent,
        data: {
          title: 'Trainer Membership Packages'
        }
      }, 
      {
        path: 'video-management/trainer-video-list',
        component: TrainerVideosListingComponent,
        data: {
          title: 'Trainer Videos'
        }
      }, 
      {
        path: 'video-management/admin-video-upload',
        component: AdminVideoUploadComponent,
        data: {
          title: 'Admin Video Upload'
        }
      },
      {
        path: 'video-management/admin-video-edit/:videoId',
        component: AdminVideoUploadComponent,
        data: {
          title: 'Admin Video Edit'
        }
      },
      {
        path: 'video-management/admin-video-listing',
        component: AdminVideoListingComponent,
        data: {
          title: 'Admin Video List'
        }
      },
      {
        path: 'live-videos/categories',
        component: LiveVideosCategoriesComponent,
        data: {
          title: 'Live Videos Categories'
        }
      },
      {
        path: 'live-videos/trainer-videos',
        component: TrainerVideosComponent,
        data: {
          title: 'Trainer Videos'
        }
      },
      {
        path: 'live-videos/complain-videos',
        component: ComplainVideosComponent,
        data: {
          title: 'Complain Videos'
        }
      },
      {
        path: 'live-videos/my-live-videos',
        component: MyVideosComponent,
        data: {
          title: 'My Live Videos'
        }
      },
      {
        path: 'live-videos/upload-live-videos',
        component: UploadVideosComponent,
        data: {
          title: 'Upload Live Videos'
        }
      },
      {
        path: 'live-videos/live-video-edit/:videoId',
        component: UploadVideosComponent,
        data: {
          title: 'Live Video Edit'
        }
      },
      {
        path: 'trainer-management',
        component: TrainerManagementComponent,
        data: {
          title: 'Trainer Management'
        }
      },
      {
        path: 'trainer-account-managements',
        component: TrainerAccountsManagementComponent,
        data: {
          title: 'Trainer Payments'
        }
      },
      {
        path: 'vendor-enquiries',
        component: EnquiryListingsComponent,
        data: {
          title: 'Vendor Enqueries'
        }
      },
      {
        path: ':feedbackId',
        component: EnquiryDetailsComponent,
        data: {
          title: 'Vendor Enquiry Details'
        }
      }
      // {
      //   path: 'cancellation-request',
      //   component: OrderCancellationRequestsComponent,
      //   data: {
      //     title: 'Cancellation Request'
      //   }
      // }      
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
