import { Component, Inject } from '@angular/core';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component {

  constructor() { }

}
