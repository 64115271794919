import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteSettingsService } from './site-settings.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss']
})
export class SiteSettingsComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public addEditSiteSettingsFormGroup: FormGroup = this._formBuilder.group({
    'titleToShow': ['', [Validators.required]],
    'settingValueText': ['', []],
    'isPublished': [true, [Validators.required]]
  });
  public siteSettingListings: any = [];
  public siteSettingsEditId: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _siteSettingsService: SiteSettingsService, private _router: Router, @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getSiteSettingList();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getSiteSettingList() {
    this._emitter.emit('showLoader');
    this._siteSettingsService.getSiteSettingList(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.siteSettingListings = [];
      }
      else if(resp.status == 200) {
        this.siteSettingListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  formSubmitted() {
    if(this.siteSettingsEditId != '') {
      this.editSiteSettingsFunc();
    }
    else {
      this.createSiteSettingsFunc();
    }
  }

  createSiteSettingsFunc() {
    this._emitter.emit('showLoader');
    this._siteSettingsService.createSiteSettings(this._userId, this._accessToken, this._csrfToken, this.addEditSiteSettingsFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.siteSettingListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.siteSettingsCreateSuccess
        });
        this.siteSettingListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editSiteSettingsFunc() {
    this._emitter.emit('showLoader');
    this._siteSettingsService.editSiteSettings(this._userId, this._accessToken, this._csrfToken, this.siteSettingsEditId, this.addEditSiteSettingsFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.siteSettingListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.siteSettingsEditedSuccess
        });
        this.siteSettingListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.siteSettingsEditId = data.setting_id;
    this.addEditSiteSettingsFormGroup.controls['titleToShow'].setValue(data.setting_name);
    this.addEditSiteSettingsFormGroup.controls['settingValueText'].setValue(data.setting_value);
    this.addEditSiteSettingsFormGroup.controls['isPublished'].setValue(data.active_status);
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.siteSettingsEditId = '';
    this.addEditSiteSettingsFormGroup.reset({
      isPublished: true
    });
  }

}
