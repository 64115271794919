import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerPackagesService } from './customer-packages.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-customer-packages',
  templateUrl: './customer-packages.component.html',
  styleUrls: ['./customer-packages.component.scss']
})
export class CustomerPackagesComponent implements OnInit {
  baseUrl = environment.baseUrl;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public config: any = {
    height: 150
  };

  public addEditSubscriptionFormGroup: FormGroup = this._formBuilder.group({
    'packageName': ['', [Validators.required, Validators.maxLength(100)]],
    'packageDescription': ['', [Validators.required]],
    'packagePrice': ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/)]],
    'videoCredits': ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'googlePaySkuKey': ['', []],
    'applePaySkuKey': ['', []],
    'isPublished': [true, [Validators.required]],
    'packageImage': [{
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    }, []]
  });

  public pageData: any = [];
  public detailsModalData: any = {};
  public editId: string = '';
  public deleteId: string = '';  
  public imageChangedEvent: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _customerPackagesService: CustomerPackagesService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  deleteFunc() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._customerPackagesService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.subscriptionDeletedSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createEditFormSubmit() {
    if(this.editId != '') {
      this.editFunc();
    }
    else {
      this.createFunc();
    }
  }

  editFunc() {
    this._emitter.emit('showLoader');
    this._customerPackagesService.edit(this._userId, this._accessToken, this._csrfToken, this.editId, this.addEditSubscriptionFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.subscriptionEditedSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createFunc() {
    this._emitter.emit('showLoader');
    this._customerPackagesService.create(this._userId, this._accessToken, this._csrfToken, this.addEditSubscriptionFormGroup.value)
    .subscribe((resp) => {
      console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.subscriptionCreateSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {      
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._customerPackagesService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.editId = data.package_id;
    this.addEditSubscriptionFormGroup.controls['packageName'].setValue(data.package_name);    
    this.addEditSubscriptionFormGroup.controls['packageDescription'].setValue(data.package_description);
    this.addEditSubscriptionFormGroup.controls['packagePrice'].setValue(data.package_price);
    this.addEditSubscriptionFormGroup.controls['videoCredits'].setValue(data.video_credits);
    this.addEditSubscriptionFormGroup.controls['googlePaySkuKey'].setValue(data.google_pay_sku_key);
    this.addEditSubscriptionFormGroup.controls['applePaySkuKey'].setValue(data.apple_pay_sku_key);
    this.addEditSubscriptionFormGroup.controls['isPublished'].setValue(data.active_status);
    this.getDataUri({
      category_icon: data.cropped_image_name,
      category_icon_source: data.source_image_name,
      crop_data: data.cropped_data
    })
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.editId = '';
    this.addEditSubscriptionFormGroup.reset({
      isPublished: true
    });
    this.addEditSubscriptionFormGroup.controls['packageImage'].setValue({
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    });
    this.imageChangedEvent = '';
    document.getElementsByClassName('input-file')[0]['value'] = '';
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  detailsModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  iconCroppedOutput(evnt) {
    this.addEditSubscriptionFormGroup.controls['packageImage'].setValue({
      cropped_image_url: evnt.croppedImage,
      source_image_url: evnt.sourceImage,
      crop_data: {
        x: evnt.croppedData.x,
        y: evnt.croppedData.y,
        w: evnt.croppedData.w,
        h: evnt.croppedData.h
      }
    });
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  getDataUri(imgObj) {
    console.log(imgObj)
    let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          cropped_image_url: '',
          source_image_url: '',
          crop_data: {
            x: '',
            y: '',
            w: '',
            h: ''
          }
        },
        imgType = 'source';
    
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      if(imgType == 'source') {
        imgType = 'crop';
        data.source_image_url = canvas.toDataURL();
        img.src = this.baseUrl + 'images/uploads/customer-subscription-packages/' + imgObj.category_icon;
        this.imageChangedEvent = data.source_image_url;
      }
      else if(imgType == 'crop') {
        imgType = '';
        data.cropped_image_url = canvas.toDataURL();
        this.addEditSubscriptionFormGroup.controls['packageImage'].setValue({
          cropped_image_url: data.cropped_image_url,
          source_image_url: data.source_image_url,
          crop_data: {
            x: imgObj.crop_data.x,
            y: imgObj.crop_data.y,
            w: imgObj.crop_data.w,
            h: imgObj.crop_data.h
          }
        });
      }
    };
    img.src = this.baseUrl + 'images/uploads/customer-subscription-packages/' + imgObj.category_icon_source;
  }

}
