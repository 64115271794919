import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import { map } from "rxjs/operators";
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';

// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminVideoUploadService {
  apiBaseUrl = environment.apiBaseUrl;

  public vimeoAPI: string = '930133a6f51c20bf41eed8ac57cf7897';
  vimeoObsShare: Observable<string>;
  vimeoResult: string;

  private vimeoLink = new BehaviorSubject('');
  vimeoLinkObs = this.vimeoLink.asObservable();

  constructor(private http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);    

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this.http.post(this.apiBaseUrl + 'admin-video-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getEditPageData(userId, accessToken, csrfToken,videoKey) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);    
    fd.append("video_key", videoKey);    

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this.http.post(this.apiBaseUrl + 'admin-video-edit-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editVideoData(userId, accessToken, csrfToken,videoKey,videoFormGroup) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);    
    fd.append("video_key", videoKey);    
    fd.append("video_name", videoFormGroup.vimeoVideoName);    
    fd.append("video_description", videoFormGroup.vimeoVideoDescription);    
    
    fd.append("video_category_key", videoFormGroup.category);    
    fd.append("video_sub_category_key", videoFormGroup.subCategory);    
    fd.append("is_free", videoFormGroup.isFree);   
    fd.append("active_status", videoFormGroup.activeStatus); 

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this.http.post(this.apiBaseUrl + 'admin-video-edit-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getVideoSubCategoryByCategory(categoryId, csrfToken) {
    let fd = new FormData();
    fd.append("category_id", categoryId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this.http.post(this.apiBaseUrl + 'admin-video-category-wise-listing', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
  
  updateVimeoLink(val) {
    this.vimeoLink.next(val);
  }

  createVimeo(options, fileSize): Observable<any> {
    // CUSTOM HEADERS FOR A FIRST INIT CALL
    const initHeaders = new HttpHeaders({'Authorization': 'bearer ' + options.token });
    initHeaders.append('Content-Type', 'application/json');
    initHeaders.append('Accept', 'application/vnd.vimeo.*+json;version=3.4');
    // CUSTOM INIT BODY
    const initBody = {
      'upload' : {
        'approach' : 'tus',
        'size' : fileSize
      },
      'name': options.videoName,
      'description': options.videoDescription,
      'privacy': {
        "embed":"public",
        "view": "unlisted"
        // "embed":"private",
        // "view": "users"
      }
    };
    if (this.vimeoResult) {
      return new Observable<any>(observer => {
        observer.next(this.vimeoResult);
        observer.complete();
      });
    } else if (this.vimeoObsShare) {
      return this.vimeoObsShare;
    } else {
      return this.http.post( options.url, initBody, { headers: initHeaders});
    }
  }

  vimeoUpload(url, file: File): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({'Tus-Resumable': '1.0.0',
      'Upload-Offset': '0',
      'Content-Type': 'application/offset+octet-stream'});
    const params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
      headers: headers
    };
    const req = new HttpRequest('PATCH', url, file, options);
    return this.http.request(req);
  }


  videoUploadDB(userId, accessToken, csrfToken,videoFormGroup,fileSize, videoUrl){
    let fd = new FormData();
    
    console.log(userId, accessToken, csrfToken,videoFormGroup,fileSize);
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    
    fd.append("video_name", videoFormGroup.vimeoVideoName);    
    fd.append("video_description", videoFormGroup.vimeoVideoDescription);    
    
    fd.append("category_id", videoFormGroup.category);    
    fd.append("sub_category_id", videoFormGroup.subCategory);    
    fd.append("video_size_in_mb", fileSize);
    fd.append("is_free", videoFormGroup.isFree);  
    fd.append("video_url", videoUrl);    
    fd.append("active_status", videoFormGroup.activeStatus);    

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this.http.post(this.apiBaseUrl + 'admin-video-upload', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
  getVideoData(endPoint){
    const initHeaders = new HttpHeaders({'Authorization': 'bearer ' + this.vimeoAPI });
    initHeaders.append('Content-Type', 'application/json');
    initHeaders.append('Accept', 'application/vnd.vimeo.*+json;version=3.4');
    console.log('http://vimeo.com/api/v2/video/'+endPoint+'.json');
    return this.http.get( 'http://vimeo.com/api/v2/video/'+endPoint+'.json');
  }
}
