import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SecuritySettingsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  changeLoginEmail(userId, accessToken, csrfToken, emailFormData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("old_email", emailFormData.oldEmail);
    fd.append("new_email", emailFormData.newEmail);
    fd.append("confirm_new_email", emailFormData.confirmEmail);

    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'change-email', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  changeLoginPassword(userId, accessToken, csrfToken, passwordFormData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("old_password", passwordFormData.oldPassword);
    fd.append("new_password", passwordFormData.newPassword);
    fd.append("confirm_new_password", passwordFormData.confirmPassword);

    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'change-password', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
