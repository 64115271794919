import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductDealsService } from './product-deals.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';

@Component({
  selector: 'app-product-deals',
  templateUrl: './product-deals.component.html',
  styleUrls: ['./product-deals.component.css']
})
export class ProductDealsComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public productdealListings: any = [];
  public productdealEditId: string = '';

  public dataPerPage: number = 0;
  public totalData: number = 0;
  public currentPageNumber: number = 1;
  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []]
  });

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _dealService: ProductDealsService, private _router: Router, @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.productDealListing();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  productDealListing() {
    this._emitter.emit('showLoader');
    this._dealService.getDealDataList(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value.search, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.productdealListings = [];
      }
      else if(resp.status == 200) {
        this.productdealListings = resp.data;
        this.totalData = resp.total;
        this.dataPerPage = resp.data_per_page;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.productDealListing();
  }

  deleteProductDealFunc() {
    this._emitter.emit('showLoader');
    this._dealService.deleteDeal(this._userId, this._accessToken, this._csrfToken, this.productdealEditId, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.productdealListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.dealDeleteSuccessMessage
        });
        this.productdealListings = resp.data;
        this.totalData = resp.total;
        this.dataPerPage = resp.data_per_page;
        this.modalRef.hide()
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    this.productDealListing();
  }

}
