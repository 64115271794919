interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'CMS Management',
    url: '/cms-management',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'CMS Listing',
        url: '/cms-management/cms-listings',
        icon: 'icon-list'
      }
      // {
      //   name: 'FAQ Management',
      //   url: '/cms-management/faq',
      //   icon: 'icon-list'
      // }
    ]
  },

  {
        name: 'Categories',
        url: '/product/categories',
        icon: 'icon-list'
  },

  {
        name: 'Sub Categories',
        url: '/product/sub-categories',
        icon: 'icon-list'
  },
  {
    name: 'Advertisements',
    url: '/advertisements',
    icon: 'icon-list'
  },
  {
    name: 'Gst Settings',
    url: '/gst-settings',
    icon: 'icon-settings'
  },
  {
    name: 'Product Full Listing',
    url: '/product-full-listings',
    icon: 'icon-list'
  },
  {
    name: 'Product Related Feature',
    url: '/product-related-features',
    icon: 'icon-list'
  },
  
  // {
  //   name: 'Fitcoin Packages',
  //   url: '/package-management',
  //   icon: 'icon-energy'
  // }, 

  // {
  //   name: 'Fitcoin Purchase',
  //   url: '/fitcoin-purchase',
  //   icon: 'icon-energy'
  // },

  //new
  // {
  //   name: 'Fitcoin Purchase',
  //   url: '/fitcoin-purchase',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Packages',
  //       url: '/fitcoin-purchase',
  //       icon: 'icon-plus'
  //     },
  //     {
  //       name: 'Purchase History',
  //       url: '/fitcoin-purchase-history',
  //       icon: 'icon-list'
  //     }
  //   ]
  // },
  { 
    name: 'Vendor Management',
    url: '/vendor-management',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Create Vendor',
        url: '/vendor-management/create-vendor',
        icon: 'icon-plus'
      },
      {
        name: 'Vendor Listing',
        url: '/vendor-management/vendor-listings',
        icon: 'icon-list'
      }
      
    ]
  },
  {
    name: 'Product Attributes',
    url: '/product-attribute-list',
    icon: 'icon-list'
  },

  {
    name: 'Vendor Payments',
    url: '/account-managements',
    icon: 'icon-briefcase'
  },
  {
    name: 'Coupon Management',
    url: '/coupon-managements',
    icon: 'icon-list'
  },
  {
    name: 'Customer Management',
    url: '/customer-managements',
    icon: 'icon-people'
  },
  
  // {
  //   name: 'Trainer Management',
  //   url: '/trainer-management',
  //   icon: 'icon-people'
  // },

  // {
  //   name: 'Video Management',
  //   url: '/video-management',
  //   icon: 'icon-control-play',
  //   children: [
  //     {
  //       name: 'Categories',
  //       url: '/video-management/categories',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Sub Categories',
  //       url: '/video-management/sub-categories',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Trainer Subscriptions',
  //       url: '/video-management/trainer-subscriptions',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Customer Packages',
  //       url: '/video-management/customer-packages',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Trainer Videos',
  //       url: '/video-management/trainer-video-list',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Upload Video',
  //       url: '/video-management/admin-video-upload',
  //       icon: 'icon-cloud-upload'
  //     },
  //     {
  //       name: 'My Uploaded Videos',
  //       url: '/video-management/admin-video-listing',
  //       icon: 'icon-list'
  //     }
  //   ]
  // },

  // {
  //   name: 'Live Videos',
  //   url: '/video-management',
  //   icon: 'icon-control-play',
  //   children: [
  //     {
  //       name: 'Categories',
  //       url: '/live-videos/categories',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Trainer Videos',
  //       url: '/live-videos/trainer-videos',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Upload Live Video',
  //       url: '/live-videos/upload-live-videos',
  //       icon: 'icon-cloud-upload'
  //     },
  //     {
  //       name: 'My Live Videos',
  //       url: '/live-videos/my-live-videos',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Complain Videos',
  //       url: '/live-videos/complain-videos',
  //       icon: 'icon-list'
  //     }
  //   ]
  // },

  // {
  //   name: 'Trainer Payments',
  //   url: '/trainer-account-managements',
  //   icon: 'icon-briefcase'
  // },

  // {
  //   name: 'Manage Challenges',
  //   url: '/competition-management',
  //   icon: 'fa fa-shield',
  //   children: [
  //     {
  //       name: 'Challenges Category',
  //       url: '/competition-management/competition-category',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Challenges Create',
  //       url: '/competition-management/competition-create',
  //       icon: 'icon-plus'
  //     },
  //     {
  //       name: 'Challenges Listing',
  //       url: '/competition-management/competition-listing',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Applications',
  //       url: '/competition-management/applications',
  //       icon: 'icon-docs'
  //     }
  //   ]
  // },

  // {
  //   name: 'Gym Amenities',
  //   url: '/gym-amenities',
  //   icon: 'icon-list'
  // },

  // {
  //   name: 'Gym Services',
  //   url: '/gym-services',
  //   icon: 'icon-list'
  // },

  {
    name: 'Vendor Enquiries',
    url: '/vendor-enquiries',
    icon: 'icon-question'
  },

  // {
  //   name: 'User Applications',
  //   url: '/user-application',
  //   icon: 'icon-docs'
  // },

  // {
  //   name: 'Manage Cities',
  //   url: '/manage-cities',
  //   icon: 'icon-location-pin'
  // },

  // {
  //   name: 'Manage Referral Code',
  //   url: '/manage-referral-code',
  //   icon: 'icon-clock'
  // },

  // {
  //   // name: 'Manage Discount Code',
  //   name: 'Product Discount Code',
  //   url: '/manage-discount-code',
  //   icon: 'icon-present'
  // },

  // {
  //   name: 'Product Tax Code',
  //   url: '/product-tax-code',
  //   icon: 'fa fa-inr'
  // },

  //product purchase history
  {
    name: 'Seller Accounts',
    url: '/order-history',
    icon: 'fa fa-money'
  },

  {
    name: 'Order List',
    url: '/order-list',
    // url: '/order-cancel',
    icon: 'fa fa-money'
  },
  
  // {
  //   // name: 'Trainer Purchase History',
  //   name: 'Product Purchase History',
  //   url: '/trainer-purchase-history',
  //   icon: 'fa fa-money'
  // },

  // {
  //   name: 'Testimonials',
  //   url: '/testimonials',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Testimonial Listing',
  //       url: '/testimonials/testimonial-listings',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Create Testimonial',
  //       url: '/testimonials/create-testimonial',
  //       icon: 'icon-plus'
  //     }
  //   ]
  // },

  {
    name: 'Product Reviews',
    url: '/product-reviews',
    icon: 'icon-info'
  },
  {
    name: 'Product Deals',
    url: '/product-deals',
    icon: 'icon-list'
  },
  {
    name: 'Enquiry Listing',
    url: '/vendor-product-management/enquiry-listings',
    icon: 'icon-list'
  },
  {
    name: 'Newsletter Compose',
    url: '/newsletter-compose',
    icon: 'fa fa-envelope'
  },
  // {
  //   name: 'Cancellation Request',
  //   url: '/cancellation-request',
  //   icon: 'icon-close'
  // },
  {
    name: 'Site Settings',
    url: '/site-settings',
    icon: 'icon-settings'
  }
  // {
  //   title: true,
  //   name: 'Theme'
  // },
  // {
  //   name: 'Colors',
  //   url: '/theme/colors',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Disabled',
  //   url: '/dashboard',
  //   icon: 'icon-ban',
  //   badge: {
  //     variant: 'secondary',
  //     text: 'NEW'
  //   },
  //   attributes: { disabled: true },
  // },
  // {
  //   name: 'Download CoreUI',
  //   url: 'http://coreui.io/angular/',
  //   icon: 'icon-cloud-download',
  //   class: 'mt-auto',
  //   variant: 'success',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // }
];
