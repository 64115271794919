import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
import { GymServicePackagesService } from './gym-service-packages.service';

@Component({
  selector: 'app-gym-service-packages',
  templateUrl: './gym-service-packages.component.html',
  styleUrls: ['./gym-service-packages.component.scss']
})
export class GymServicePackagesComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public packageFormGroup: FormGroup = this._formBuilder.group({
    'packageService': ['', [Validators.required]],
    'packageText': ['', [Validators.required]],
    'numberOfDays': ['1', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{0,3}?$/)]],
    'packagePrice': ['0', [Validators.required, Validators.pattern(/^\d+$/)]],
    'isPublished': [true, [Validators.required]]
  });
  public gymId: string = '';
  public editPackageId: string = '';
  public deletePackageId: string = '';
  public packageListings: any = [];
  public pageData: any = [];

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables,private _gymServicePackagesService: GymServicePackagesService, private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this._activatedRoute.params.subscribe(param => {
        this.gymId = param.gymId;
        this.getServicePackageList();
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getServicePackageList() {
    this._emitter.emit('showLoader');
    this._gymServicePackagesService.getServicePackageListings(this._userId, this._accessToken, this._csrfToken, this.gymId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.packageListings = [];
        this._router.navigateByUrl('/gym-owner-management/gym-listings');
      }
      else if(resp.status == 200) {
        if(resp.data == null) {
          this.packageListings = [];
        }
        else {
          this.packageListings = resp.data;
        }
        this.pageData = resp.page_data;
        this.packageFormGroup.controls['packageService'].setValue(this.pageData[0].service_id);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetForm() {
    this.editPackageId = '';
    this.deletePackageId = '';
    this.packageFormGroup.controls['packageService'].setValue('');
    this.packageFormGroup.controls['packageText'].setValue('');
    this.packageFormGroup.controls['packagePrice'].setValue('0');
    this.packageFormGroup.controls['numberOfDays'].setValue('1');
    this.packageFormGroup.controls['isPublished'].setValue(true);
    this.packageFormGroup.controls['packageService'].setValue(this.pageData[0].service_id);
  }

  formSubmitted() {
    if(this.editPackageId != '') {
      this.editServicePackageFunc();
    }
    else {
      this.createServicePackageFunc();
    }
  }

  createServicePackageFunc() {
    this._emitter.emit('showLoader');
    this._gymServicePackagesService.createServicePackage(this._userId, this._accessToken, this._csrfToken, this.gymId, this.packageFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorServicePackageManagementsCreateSuccess
        });
        if(resp.data == null) {
          this.packageListings = [];
        }
        else {
          this.packageListings = resp.data;
        }
        this.pageData = resp.page_data;
        this.packageFormGroup.controls['packageService'].setValue(this.pageData[0].service_id);
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editServicePackageFunc() {
    this._emitter.emit('showLoader');
    this._gymServicePackagesService.editServicePackage(this._userId, this._accessToken, this._csrfToken, this.editPackageId, this.gymId, this.packageFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.vendorServicePackageManagementsInvalidPackage
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorServicePackageManagementsEditSuccess
        });
        if(resp.data == null) {
          this.packageListings = [];
        }
        else {
          this.packageListings = resp.data;
        }
        this.pageData = resp.page_data;
        this.packageFormGroup.controls['packageService'].setValue(this.pageData[0].service_id);
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data, subData) {
    this.editPackageId = subData.service_packages_id;
    this.packageFormGroup.controls['packageService'].setValue(data.gym_service_id);
    this.packageFormGroup.controls['packageText'].setValue(subData.package_name);
    this.packageFormGroup.controls['packagePrice'].setValue(subData.package_price);
    this.packageFormGroup.controls['numberOfDays'].setValue(subData.no_of_days);
    this.packageFormGroup.controls['isPublished'].setValue(subData.active_status);

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  deleteServicePackageConfirmClicked() {
    this._emitter.emit('showLoader');
    this._gymServicePackagesService.deleteServicePackage(this._userId, this._accessToken, this._csrfToken, this.deletePackageId, this.gymId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.vendorServicePackageManagementsInvalidPackage
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorServicePackageManagementsDeleteSuccess
        });
        if(resp.data == null) {
          this.packageListings = [];
        }
        else {
          this.packageListings = resp.data;
        }
        this.pageData = resp.page_data;
        this.packageFormGroup.controls['packageService'].setValue(this.pageData[0].service_id);
        this.resetForm();
        this.modalRef.hide();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
