import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileImageService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  profileImageCreate(userId, accessToken, csrfToken, vendor_id, cropData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendor_id);
    fd.append("profile_source_image", cropData.profilePic.source_img);
    fd.append("profile_cropped_image", cropData.profilePic.cropped_img);
    fd.append("profile_crop_data", JSON.stringify(cropData.profilePic.crop_data));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-create-profile-image', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  profileImageEditData(userId, accessToken, csrfToken, vendorId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-profile-image-edit-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  profileImageedit(userId, accessToken, csrfToken, vendorId, cropData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("profile_source_image", cropData.profilePic.source_img);
    fd.append("profile_cropped_image", cropData.profilePic.cropped_img);
    fd.append("profile_crop_data", JSON.stringify(cropData.profilePic.crop_data));

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-edit-profile-image', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
