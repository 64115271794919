import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppVariables } from '../../app.variables';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { GymServicesService } from './gym-services.service';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-gym-services',
  templateUrl: './gym-services.component.html',
  styleUrls: ['./gym-services.component.scss']
})
export class GymServicesComponent implements OnInit {
  baseUrl = environment.baseUrl;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public addEditServicesFormGroup: FormGroup = this._formBuilder.group({
    'serviceName': ['', [Validators.required]],
    'serviceType': ['1', [Validators.required]],
    'isPublished': [true, [Validators.required]],
    'serviceImage': [{
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    }, []]
  });

  public serviceListings: any = [];
  public serviceEditId: string = '';
  public imageChangedEvent: string = '';
  public serviceDeleteId: string = '';
 
  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _gymServicesService: GymServicesService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._gymServicesService.getServiceListingsData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.serviceListings = [];
      }
      else if(resp.status == 200) {
        this.serviceListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  serviceIconCroppedOutput(evnt) {
    this.addEditServicesFormGroup.controls['serviceImage'].setValue({
      cropped_image_url: evnt.croppedImage,
      source_image_url: evnt.sourceImage,
      crop_data: {
        x: evnt.croppedData.x,
        y: evnt.croppedData.y,
        w: evnt.croppedData.w,
        h: evnt.croppedData.h
      }
    });
  }

  resetForm() {
    this.serviceEditId = '';
    this.addEditServicesFormGroup.controls['serviceName'].setValue('');
    this.addEditServicesFormGroup.controls['serviceType'].setValue('');
    this.addEditServicesFormGroup.controls['isPublished'].setValue(true);
    this.addEditServicesFormGroup.controls['serviceImage'].setValue({
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    });
    this.imageChangedEvent = '';
    document.getElementsByClassName('input-file')[0]['value'] = '';
  }

  getDataUri(imgObj) {
    let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          cropped_image_url: '',
          source_image_url: '',
          crop_data: {
            x: '',
            y: '',
            w: '',
            h: ''
          }
        },
        imgType = 'source';
    
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      if(imgType == 'source') {
        imgType = 'crop';
        data.source_image_url = canvas.toDataURL();
        img.src = this.baseUrl + 'images/uploads/gym-services/' + imgObj.service_icon;
        this.imageChangedEvent = data.source_image_url;
      }
      else if(imgType == 'crop') {
        imgType = '';
        data.cropped_image_url = canvas.toDataURL();
        this.addEditServicesFormGroup.controls['serviceImage'].setValue({
          cropped_image_url: data.cropped_image_url,
          source_image_url: data.source_image_url,
          crop_data: {
            x: imgObj.service_icon_crop_data.x,
            y: imgObj.service_icon_crop_data.y,
            w: imgObj.service_icon_crop_data.w,
            h: imgObj.service_icon_crop_data.h
          }
        });
      }
    };
    img.src = this.baseUrl + 'images/uploads/gym-services/' + imgObj.service_icon_source;
  }

  setEditData(data) {
    this.serviceEditId = data.service_id;
    this.addEditServicesFormGroup.controls['serviceName'].setValue(data.service_name);
    this.addEditServicesFormGroup.controls['serviceType'].setValue(data.service_type);
    this.addEditServicesFormGroup.controls['isPublished'].setValue(data.active_status);
    this.getDataUri({
      service_icon: data.service_icon,
      service_icon_source: data.service_icon_source,
      service_icon_crop_data: data.service_icon_crop_data
    });
    window.scrollTo(0, 0);
  }

  createEditFormSubmit() {
    if(this.serviceEditId != '') {
      this.editService();
    }
    else {
      this.createService();
    }
  }

  createService() {
    this._emitter.emit('showLoader');
    this._gymServicesService.createServiceFunc(this._userId, this._accessToken, this._csrfToken, this.addEditServicesFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymServicesCreateSuccess
        });
        this.serviceListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editService() {
    this._emitter.emit('showLoader');
    this._gymServicesService.editServiceFunc(this._userId, this._accessToken, this._csrfToken, this.serviceEditId, this.addEditServicesFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymServicesEditedSuccess
        });
        this.serviceListings = resp.data;
        this.resetForm();
        this.serviceEditId = '';
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  
  deleteGymServices() {
    this._emitter.emit('showLoader');
    this.resetForm();
    this.modalRef.hide()
    this._gymServicesService.deletePackage(this._userId, this._accessToken, this._csrfToken, this.serviceDeleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymServicesDeletedSuccess
        });
        this.serviceListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymServicesDeletedSuccess
        });
        this.serviceListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
