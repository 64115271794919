import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppVariables } from '../../../app.variables';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { CreateEditVendorService } from './create-edit-vendor.service';

@Component({
  selector: 'app-create-edit-vendor',
  templateUrl: './create-edit-vendor.component.html',
  styleUrls: ['./create-edit-vendor.component.scss']
})
export class CreateEditVendorComponent implements OnInit {

  @ViewChild('vendorTabs') vendorTabs: TabsetComponent;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public userTitle: any = [];
  public vendorId: string = '';
  public activeTab: number = 0;
  public tabActivationStatus: any = [0, 0, 0, 0];
  public adminVerificationStatus: boolean = false;
  public publishStatus: boolean = false;
  public verifiedAt: string = 'N/A';
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public deductionRateInput: string = '';
  public deductionRate: string = '';
  public showDeductionRateEditIcon: boolean = false;

  // Doughnut
  public doughnutChartData: number[] = [0, 100];
  public doughnutChartType: string = 'doughnut';
  public doughnutChartOptions: any = {
    tooltips: {
      enabled: false
    }
  };
  public doughnutChartColors: any =  [{
    backgroundColor: ['#009666', '#9e9e9e'],
    borderColor: ['#009666', '#9e9e9e'],
    pointBackgroundColor: ['#009666', '#9e9e9e']
  }];

  constructor(private _modalService: BsModalService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute, private _createEditGymOwnerService: CreateEditVendorService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.userTitle = JSON.parse(localStorage.getItem("userTitles"));

      this._activatedRoute.params.subscribe((param) => {
        this.vendorId = typeof param.vendorId !== 'undefined' ? param.vendorId : '';
        if(this.vendorId != '') {
          this.createOrEdit = 1;
          this.getEditData();
        }
        else {
          this.getCreateData();
        }
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  isNumericInvalidate() {
    return (this.deductionRateInput == '' || this.deductionRateInput == '0' || !(/^(\d){1,2}(\.(\d){1,2})?$/.test(this.deductionRateInput)));
  }
  
  editDeductionRate(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getCreateData() {
    this._emitter.emit('showLoader');
    this._createEditGymOwnerService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.deductionRate = resp.data.vendor_deduction_rate;
        this.deductionRateInput = this.deductionRate;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getEditData() {
    this._emitter.emit('showLoader');
    this._createEditGymOwnerService.getEditPageData(this._userId, this._accessToken, this._csrfToken, this.vendorId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
      else if(resp.status == 200) {
        this.adminVerificationStatus = resp.data.verification_status;
        this.verifiedAt = resp.data.verified_at == null ? 'N/A' : resp.data.verified_at;
        this.publishStatus = resp.data.active_status;

        let profileCompleted = resp.data.setup_complete_percentage;
        this.doughnutChartData = [profileCompleted, 100 - profileCompleted];
        this.tabActivationStatus[0] = 1;
        this.tabActivationStatus[1] = !resp.data.company_details_status ? 0 : 1;
        this.tabActivationStatus[2] = !resp.data.profile_image_status ? 0 : 1;
        this.tabActivationStatus[3] = !resp.data.bank_details_status ? 0 : 1;

        this.showDeductionRateEditIcon = resp.data.company_details_status;

        this.vendorTabs.tabs[0].disabled = false;
        this.vendorTabs.tabs[1].disabled = false;
        this.vendorTabs.tabs[2].disabled = false;
        this.vendorTabs.tabs[3].disabled = false;

        this.deductionRate = resp.data.vendor_deduction_rate;
        this.deductionRateInput = this.deductionRate;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  changeTab(evnt, tabIndx) {
    this.activeTab = tabIndx;
  }

  personalDetailsTabOutput(resp) {
    console.log(resp)
    if(resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];
      this.vendorId = resp.vendor_id;

      if(this.createOrEdit == 0) {
        this.vendorTabs.tabs[0].disabled = true;
        this.vendorTabs.tabs[1].disabled = false;
        this.vendorTabs.tabs[2].disabled = true;
        this.vendorTabs.tabs[3].disabled = true;
      }
      this.activeTab = 1;
      this.vendorTabs.tabs[1].active = true;
    }
  }

  companyDetailsTabOutput(resp) {
    if(resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];

      if(this.createOrEdit == 0) {
        this.vendorTabs.tabs[0].disabled = true;
        this.vendorTabs.tabs[1].disabled = true;
        this.vendorTabs.tabs[2].disabled = false;
        this.vendorTabs.tabs[3].disabled = true;
      }

      this.showDeductionRateEditIcon = true;
      this.activeTab = 2;
      this.vendorTabs.tabs[2].active = true;
      this.tabActivationStatus[1] = 1;
    }
  }

  profileImageTabOutput(resp) {
    if(resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];

      if(this.createOrEdit == 0) {
        this.vendorTabs.tabs[0].disabled = true;
        this.vendorTabs.tabs[1].disabled = true;
        this.vendorTabs.tabs[2].disabled = true;
        this.vendorTabs.tabs[3].disabled = false;
      }

      this.activeTab = 3;
      this.vendorTabs.tabs[3].active = true;
      this.tabActivationStatus[2] = 1;
    }
  }

  bankDetailsTabOutput(resp) {
    if(resp.status == 200) {
      let profileCompleted = resp.setup_complete_percentage;
      this.doughnutChartData = [profileCompleted, 100 - profileCompleted];
      this.tabActivationStatus[3] = 1;

      if(this.createOrEdit == 0) {
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
    }
  }

  isVerifiedChanged() {
    this._emitter.emit('showLoader');
    this._createEditGymOwnerService.updateVerificationStatus(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.adminVerificationStatus)
    .subscribe((resp) => {
      console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorAdminVerificationStatusUpdateSuccess
        });
        // this.verifiedAt = resp.data.verified_at == null ? 'N/A' : resp.data.verified_at;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editDeductionRateFunc() {
    this._emitter.emit('showLoader');
    this._createEditGymOwnerService.editDuductionRate(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.deductionRateInput)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorAdminDeductionRateUpdateSuccess
        });
        this.deductionRate = this.deductionRateInput;
        this.modalRef.hide();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isPubishedChanged() {
    this._emitter.emit('showLoader');
    this._createEditGymOwnerService.updatePublishStatus(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.publishStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorAdminPublishStatusUpdateSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
}
