import { Component, Inject, OnInit, Input, Output, TemplateRef } from '@angular/core';
import { EventEmitter } from 'events';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { SubCategoriesService } from './sub-categories.service';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {
  baseUrl = environment.baseUrl;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public text = ' Best Resolution Size: 128 X 128px';
  public addEditVideoSubCategoryFormGroup: FormGroup = this._formBuilder.group({
    'categoryId': ['', [Validators.required]],
    'subCategoryName': ['', [Validators.required, Validators.maxLength(120)]],
    'isPublished': [true, [Validators.required]]
  });

  public pageData: any = [];
  public categoryListing: any = [];
  public editId: string = '';
  public deleteId: string = '';
  public bannerNoImageUrl = 'assets/img/no-img-5x1.jpg';
  public imageChangedEvent: any = [];
  bannerData
  constructor(
    private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _subCategoriesService: SubCategoriesService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  public  imgData= {
    croppedImageUrl: this.bannerNoImageUrl,
    sourceImageUrl: '',
    cropData: {
      x: '',
      y: '',
      w: '',
      h: ''
    }
 
};

croppedOutput(croppedResult) {
  this.imgData.croppedImageUrl = croppedResult.croppedImage;
  this.imgData.sourceImageUrl = croppedResult.sourceImage;
  this.imgData.cropData.x = croppedResult.croppedData.x;
  this.imgData.cropData.y = croppedResult.croppedData.y;
  this.imgData.cropData.w = croppedResult.croppedData.w;
  this.imgData.cropData.h = croppedResult.croppedData.h;
}

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'];
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  deleteGymAmenities() {
    this._emitter.emit('showLoader');
    this.resetForm();
    this.modalRef.hide()
    this._subCategoriesService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoSubCategoryDeletedSuccess
        });
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoSubCategoryDeletedSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createCategory() {
    this._emitter.emit('showLoader');
    this._subCategoriesService.create(this._userId, this._accessToken, this._csrfToken, this.addEditVideoSubCategoryFormGroup.value, this.imgData)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoSubCategoryCreateSuccess
        });
        this.pageData = resp.listing_data.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editCategory() {
    this._emitter.emit('showLoader');
    this._subCategoriesService.edit(this._userId, this._accessToken, this._csrfToken, this.editId, this.addEditVideoSubCategoryFormGroup.value, this.imgData)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.videoSubCategoryEditedSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
        this.editId = '';
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createEditFormSubmit() {
    if(this.editId != '') {
      this.editCategory();
    }
    else {
      this.createCategory();
    }
  }

  async setEditData(data) {
    let sourceImage = this.baseUrl+'images/uploads/product-sub-category/'+data?.sub_category_icon_source;
    this.imageChangedEvent = await this.getBase64ImageFromUrl(sourceImage);
    this.imgData.cropData = data.sub_category_icon_crop_data;
    this.imgData.sourceImageUrl=this.imageChangedEvent;

    this.editId = data.sub_category_id;
    this.addEditVideoSubCategoryFormGroup.controls['categoryId'].setValue(data.category_id);
    this.addEditVideoSubCategoryFormGroup.controls['subCategoryName'].setValue(data.sub_category_name);
    this.addEditVideoSubCategoryFormGroup.controls['isPublished'].setValue(data.active_status);   
    window.scrollTo(0, 0);
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  resetForm() {
    this.editId = '';
    this.addEditVideoSubCategoryFormGroup.controls['categoryId'].setValue(this.categoryListing[0].video_category_id);
    this.addEditVideoSubCategoryFormGroup.controls['subCategoryName'].setValue('');
    this.addEditVideoSubCategoryFormGroup.controls['isPublished'].setValue(true);
    this.imageChangedEvent=[]
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._subCategoriesService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        this.categoryListing = resp.category_listing;
        this.addEditVideoSubCategoryFormGroup.controls['categoryId'].setValue(resp.category_listing[0].video_category_id);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
