import { Component, Inject, OnInit, Input, Output, EventEmitter as EventEmitterCore } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BasicDetailsService } from './basic-details.service';
import { AppVariables } from '../../../../../app.variables';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit {

  public config: any = {
    height: 150,
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
    ],
  };

  public pageData: any = [];
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public vendorId: string = '';
  public productId: string = '';

  public categoryListing: any = [];
  public subCategoryListing: any = [];
  public unitListing: any = [];
  public gstSlabListing: any = [];

  public basicDetailsFormGroup: FormGroup = this._formBuilder.group({
    'productName': ['', [Validators.required]],
    'categoryId': ['', [Validators.required]],
    'subCategoryId': ['', [Validators.required]],
    'productDescription': ['', []],
    'gstSlab': ['', [Validators.required]],
    'hsnCode': ['', [Validators.required, Validators.pattern(/\d/)]],
    'sacCode': ['', [Validators.required]],
    'weight': ['', [Validators.required]],
    'iscatalogue': [false, [Validators.required]],
    'orginalPrice': ['', [Validators.required]],
    'listingPrice': ['', [Validators.required]],
    'maxQuantity': ['', []],
    'youtubeUrl': ['', []]
  });

  @Output() responseOutputObj = new EventEmitterCore<any>();

  constructor(@Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, private _activatedRoute: ActivatedRoute, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _basicDetailsService: BasicDetailsService) { }

  establishedYearValidation(control: FormControl) {
    if(control.value <= new Date().getFullYear()) {
      return null;
    }
    else {
      return {error: 'error'};
    }
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      
      this._userId = userDetails.user_id;
      

      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this._activatedRoute.params.subscribe((params) => {
        this.vendorId = params.vendorId;
        if(typeof params.productId !== 'undefined' && params.productId != '' && params.productId != null) {
          this.productId = params.productId;
          this.getEditBasicDetailsPageData();
          this.createOrEdit = 1;
        }
        else {
          this.createOrEdit = 0;
          this.getCreateBasicDetailsPageData();
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  // click category auto add sub-category
  onChangeCategoryData(isDataSetNeeded = false){
    this._basicDetailsService.getSubCategoryData(this._userId, this._accessToken, this.basicDetailsFormGroup.value.categoryId, this._csrfToken)
    .subscribe((resp) => {
      // console.log('getSubdata', resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        
        this.pageData = resp.data;
        
        this.subCategoryListing = resp.subcategory_listing;
        
        
        if(resp.subcategory_listing.length > 0 && !isDataSetNeeded) {
          this.basicDetailsFormGroup.controls['subCategoryId'].setValue(resp.subcategory_listing[0].subcategory_id);
        }
       
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  
  // Create Get Data
  getCreateBasicDetailsPageData() {
    this._basicDetailsService.basicDetailCreatePageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        this.categoryListing = resp.category_listing;
        this.basicDetailsFormGroup.controls['categoryId'].setValue(resp.category_listing[0].video_category_id);
        // this.unitListing = resp.unit_type_listing;
        this.gstSlabListing = resp.gst_slab_listing;
        this.basicDetailsFormGroup.controls['gstSlab'].setValue(resp.gst_slab_listing[0].gst_slab_id);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  // Edit Get Data
  getEditBasicDetailsPageData() {
    this._emitter.emit('showLoader');
    this._basicDetailsService.basicDetailEditPageData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/product-management/product-listings');
      }
      else if(resp.status == 200) {
        this.categoryListing = resp.category_listing;
        // this.unitListing = resp.unit_type_listing;
        this.gstSlabListing = resp.gst_slab_listing;
        this.basicDetailsFormGroup.controls['productName'].setValue(resp.data.product_name);
        this.basicDetailsFormGroup.controls['categoryId'].setValue(resp.data.category_id);
        this.basicDetailsFormGroup.controls['subCategoryId'].setValue(resp.data.subcategory_id);
        this.basicDetailsFormGroup.controls['gstSlab'].setValue(resp.data.gst_slab_id);
        this.basicDetailsFormGroup.controls['hsnCode'].setValue(resp.data.hsn_code);
        this.basicDetailsFormGroup.controls['sacCode'].setValue(resp.data.sac_code);
        this.basicDetailsFormGroup.controls['weight'].setValue(resp.data.weight);
        this.basicDetailsFormGroup.controls['iscatalogue'].setValue(resp.data.is_catalogue);
        this.basicDetailsFormGroup.controls['orginalPrice'].setValue(resp.data.base_price_original);
        this.basicDetailsFormGroup.controls['listingPrice'].setValue(resp.data.base_price_listing);
        this.basicDetailsFormGroup.controls['productDescription'].setValue(resp.data.product_description);
        this.basicDetailsFormGroup.controls['youtubeUrl'].setValue(resp.data.youtube_url);
        this.basicDetailsFormGroup.controls['maxQuantity'].setValue(resp.data.max_quantity);
        this.onChangeCategoryData(true);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  productBasicSaveClicked() {
    if(this.productId == '') { // Create
      this.createBasicDetailsFunc();
    }
    else { // Edit
      this.editBasicDetailsFunc();
    }
  }

  // Create
  createBasicDetailsFunc() {
    this._emitter.emit('showLoader');
    // console.log('_basicDetailsService', this._userId, this._accessToken, this._csrfToken, this.basicDetailsFormGroup.value.subCategoryId, this.basicDetailsFormGroup.value);
    
    this._basicDetailsService.createBasicDetails(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.basicDetailsFormGroup.value.subCategoryId, this.basicDetailsFormGroup.value)
    .subscribe((resp) => {
      console.log('createBasicDetailsFunc', resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.requiredErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productCreateSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

// Edit
  editBasicDetailsFunc() {
    this._emitter.emit('showLoader');
    this._basicDetailsService.editBasicDetails(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.basicDetailsFormGroup.value)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.requiredErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productEditedSuccess
        });
        resp.product_id = this.productId;
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
}
