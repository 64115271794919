import { Component, Inject, OnInit, Output, EventEmitter as EventEmitterCore } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PersonalDetailsService } from './personal-details.service';
import { AppVariables } from '../../../../../app.variables';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public userTitle: any = [];
  public vendorId: string = '';

  @Output() responseOutputObj = new EventEmitterCore<any>();

  public personalDetailsFormGroup: FormGroup = this._formBuilder.group({
    'userTitle': ['', [Validators.required]],
    'userName': ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
    'userEmail': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
    'userPassword': ['', [this.passworddMatchValidation.bind(this)]],
    'userConfirmPassword': ['', [this.confirmPasswordMatchValidation.bind(this)]]
  });

  constructor(@Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, private _formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute, private _personalDetailsService: PersonalDetailsService, public appVariables: AppVariables) { }

  passworddMatchValidation(control: FormControl) {
    if(typeof control.parent !== 'undefined') {
      if(this.vendorId == '') {
        if(control.value == '' || !(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*\_\-])[a-zA-Z0-9!@#$%^&*\_\-]{8,}$/.test(control.value))) {
          return { errorMessage: 'This field is mandatory' };
        }
        else if(control.parent.controls['userPassword'].value != control.parent.controls['userConfirmPassword'].value) {
          control.parent.controls['userConfirmPassword'].setErrors({ 'incorrect': true });
          return null;
        }
        else {
          control.parent.controls['userConfirmPassword'].setErrors(null);
          return null;
        }
      }
      else {
        return null;
      }
    }
  }

  confirmPasswordMatchValidation(control: FormControl) {
    if (typeof control.parent !== 'undefined') {
      if(this.vendorId == '') {
        if(control.value == '') {
          return { errorMessage: 'This field is mandatory' };
        }
        else if(control.parent.controls['userPassword'].value != control.parent.controls['userConfirmPassword'].value) {
          return { errorMessage: 'This field is mandatory' };
        }
        else {
          control.parent.controls['userConfirmPassword'].setErrors(null);
          return null;
        }
      }
      else {
        return null;
      }
    }
  }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.userTitle = JSON.parse(localStorage.getItem("userTitles"));
      this.personalDetailsFormGroup.controls['userTitle'].setValue(this.userTitle[0].user_title_id);

      this._activatedRoute.params.subscribe((param) => {
        this.vendorId = typeof param.vendorId !== 'undefined' ? param.vendorId : '';
        if(this.vendorId != '') {
          this.getEditPageData();
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._personalDetailsService.getEditData(this._userId, this._accessToken, this._csrfToken, this.vendorId)
    .subscribe((resp) => {
      console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
      else if(resp.status == 200) {
        this.personalDetailsFormGroup.controls['userTitle'].setValue(resp.data.user_title_id);
        this.personalDetailsFormGroup.controls['userName'].setValue(resp.data.user_name);
        this.personalDetailsFormGroup.controls['userEmail'].setValue(resp.data.user_email);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  personalDetailsFormSubmitted() {
    if(this.vendorId == '') {
      this.personalDetailsCreateFunc();
    }
    else {
      this.personalDetailsUpdateFunc();
    }
  }

  personalDetailsCreateFunc() {
    this._emitter.emit('showLoader');
    this._personalDetailsService.createPersonalDetails(this._userId, this._accessToken, this._csrfToken, this.personalDetailsFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 409) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.vendorCreatePersonalDetailsDuplicateEmailError
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorCreatePersonalDetailsSuccess
        });
        console.log(resp)
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  personalDetailsUpdateFunc() {
    this._emitter.emit('showLoader');
    this._personalDetailsService.editPersonalDetailsData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.personalDetailsFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/vendor-management/vendor-listings');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorUpdatePersonalDetailsSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
