import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FitcoinPurchaseService } from './fitcoin-purchase.service'
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-fitcoin-purchase',
  templateUrl: './fitcoin-purchase.component.html',
  styleUrls: ['./fitcoin-purchase.component.scss']
})
export class FitcoinPurchaseComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;  
  public pageData: any = [];

  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  public fitcoinPurchasePackageEditId: string = '';
  public fitcoinPurchasePackageDeleteId: string = '';

  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'approveStatus': ['', [Validators.required]]
  });

  public fitcoinPurchaseFormGroup: FormGroup = this.formBuilder.group({
    'fitcoinPackageName': ['', [Validators.required]],
    'fitcoinAmount': ['', [Validators.required, Validators.maxLength(6), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'price': ['', [Validators.required, Validators.maxLength(6), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'isPublished': [true, [Validators.required]]
  });

  constructor(private _formBuilder: FormBuilder, private _fitcoinPurchaseService: FitcoinPurchaseService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  resetForm() {
    this.fitcoinPurchasePackageEditId = '';
    this.fitcoinPurchaseFormGroup.reset({
      isPublished: true
    });    
  }

  editFunc() {
    this._emitter.emit('showLoader');
    this._fitcoinPurchaseService.edit(this._userId, this._accessToken, this._csrfToken, this.fitcoinPurchaseFormGroup.value, this.fitcoinPurchasePackageEditId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.fitcoinPurchaseEditedSuccess
        });
        this.pageData = resp.data;
        this.fitcoinPurchasePackageEditId = '';
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.fitcoinPurchasePackageEditId = data.package_id;    
    this.fitcoinPurchaseFormGroup.controls['fitcoinPackageName'].setValue(data.package_name);
    this.fitcoinPurchaseFormGroup.controls['fitcoinAmount'].setValue(data.fitcoin_amount);
    this.fitcoinPurchaseFormGroup.controls['price'].setValue(data.purchase_price);
    this.fitcoinPurchaseFormGroup.controls['isPublished'].setValue(data.active_status);
    window.scrollTo(0, 0);
  }

  createFitcoinPurchase() {
    this._emitter.emit('showLoader');

    this._fitcoinPurchaseService.create(this._userId, this._accessToken, this._csrfToken, this.fitcoinPurchaseFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 409) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.fitcoinPurchaseCreateSametime
        });
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.fitcoinPurchaseCreateSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createEditFormSubmit() {
    if (this.fitcoinPurchasePackageEditId != '') {
      this.editFunc();
    }
    else {
      this.createFitcoinPurchase();
    }
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._fitcoinPurchaseService.getPageData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        // console.log(this.pageData)
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        // this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteFunc() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._fitcoinPurchaseService.delete(this._userId, this._accessToken, this._csrfToken, this.fitcoinPurchasePackageDeleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.fitcoinPurchaseDeleteSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
