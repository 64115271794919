import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EventEmitter } from 'events';
import {ComposeSubscriberService} from './compose-subscriber.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-compose-subscriber',
  templateUrl: './compose-subscriber.component.html',
  styleUrls: ['./compose-subscriber.component.css']
})
export class ComposeSubscriberComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public dataPerPage: number = 0;
  public totalData: number = 0;
  public currentPageNumber: number = 1;
  public mailListings: any = [];

  @ViewChild('composeMail') composeMail: any;
  public config: any = {
    height: 300
  };
  public composeMailForm: FormGroup = this._formBuilder.group({
    // 'vendorId': ['', [Validators.required]],
    'mailSubject': ['', [Validators.required]],
    'mailBody': ['', [Validators.required]]
  });

  constructor(
    private _modalService: BsModalService,
    private _formBuilder: FormBuilder,
    private _composeSubscriberService: ComposeSubscriberService,
    private _router: Router,
    @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter,
    public appVariables: AppVariables,
    private datePipe: DatePipe  
  ) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      
      this.getMailListings();

      this._modalService.onHidden.subscribe(() => {
        // this.composeMailForm.controls['vendorId'].setValue('');
        this.composeMailForm.controls['mailSubject'].setValue('');
        this.composeMailForm.controls['mailBody'].setValue('');
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getMailListings() {
    this._emitter.emit('showLoader');
    this._composeSubscriberService.getMailListings(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.mailListings = [];
      }
      else if(resp.status == 200) {
        this.mailListings = resp.data;
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  openModal(composeMail: TemplateRef<any>) {
    this.modalRef = this._modalService.show(composeMail, {
      ignoreBackdropClick: true
    });
  }

  composeMailSubmit() {
    if(!this.composeMailForm.valid) {
      return false;
    }
    this._emitter.emit('showLoader');
    this._composeSubscriberService.composeNewMailSend(this._userId, this._accessToken, this._csrfToken, this.composeMailForm.value)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');
      this.modalRef.hide();
      this.composeMailForm.reset();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.composeMailSentSuccess
        });
        // console.log(resp)
        this.mailListings = resp.listing_data.data;
        this.dataPerPage = resp.data_per_page;
        this.totalData = this.mailListings.length

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this.modalRef.hide();
      this.composeMailForm.reset();
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    this.getMailListings();
  }

  accountManagementEditData(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

}
