import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductRelatedFeaturesService } from './product-related-features.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-product-related-features',
  templateUrl: './product-related-features.component.html',
  styleUrls: ['./product-related-features.component.scss']
})
export class ProductRelatedFeaturesComponent implements OnInit {
  baseUrl = environment.baseUrl;

  @ViewChild('categoryIcon') categoryIcon: ElementRef;

  bannerData
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public videoProductRelatedFormGroup: FormGroup = this._formBuilder.group({
    'name': ['', [Validators.required, Validators.maxLength(120)]],
    // 'showInHeaderMenu': [false, [Validators.required]],
    'isPublished': [true, [Validators.required]]
  });
  public pageData: any = [];
  public editId: string = '';
  public deleteId: string = '';
  public bannerNoImageUrl = 'assets/img/no-img-5x1.jpg';
  public imageChangedEvent: any = [];
  public text = ' Best Resolution Size: 400 X 400px';

  constructor(
    private _modalService: BsModalService, private _formBuilder: FormBuilder, private _productRelatedFeaturesService: ProductRelatedFeaturesService, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables
  ) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  public imgData = {
    croppedImageUrl: this.bannerNoImageUrl,
    sourceImageUrl: '',
    cropData: {
      x: '',
      y: '',
      w: '',
      h: ''
    }

  };

  croppedOutput(croppedResult) {
    this.imgData.croppedImageUrl = croppedResult.croppedImage;
    this.imgData.sourceImageUrl = croppedResult.sourceImage;
    this.imgData.cropData.x = croppedResult.croppedData.x;
    this.imgData.cropData.y = croppedResult.croppedData.y;
    this.imgData.cropData.w = croppedResult.croppedData.w;
    this.imgData.cropData.h = croppedResult.croppedData.h;
  }

  // public bannerSection: any = [JSON.parse(JSON.stringify(this.bannerSectionBlankDataObj))];
  croppedImage: any = '';

  deleteFunc() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._productRelatedFeaturesService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');
        this.modalRef.hide();

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.productRelatedFeaturesDeletedSuccess
          });
          this.pageData = resp.data;
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this.modalRef.hide();
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._productRelatedFeaturesService.getPageData(this._userId, this._accessToken, this._csrfToken)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.pageData = [];
        }
        else if (resp.status == 200) {
          this.pageData = resp.data;
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        if (typeof this.modalRef !== 'undefined') {
          this.modalRef.hide();
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  formSubmitted() {
    if (this.editId != '') {
      this.editFunc();
    }
    else {
      this.createFunc();
    }
  }

  createFunc() {
    this._emitter.emit('showLoader');
    this._productRelatedFeaturesService.create(this._userId, this._accessToken, this._csrfToken, this.videoProductRelatedFormGroup.value, this.imgData)
      .subscribe((resp) => {
        console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.pageData = [];
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.productRelatedFeaturesCreateSuccess

          });
          this.pageData = resp.listing_data.data;
          this.resetForm();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if (_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'];
      }
      fr.readAsDataURL(_this.files[0]);
    }
  }

  editFunc() {

    console.log(this.imageChangedEvent);

    this._emitter.emit('showLoader');
    this._productRelatedFeaturesService.edit(this._userId, this._accessToken, this._csrfToken, this.editId, this.videoProductRelatedFormGroup.value, this.imgData)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.pageData = [];
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.videoCategoryEditedSuccess
          });
          this.pageData = resp.data;
          this.resetForm();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  async setEditData(data) {
    let sourceImage = this.baseUrl + 'images/uploads/product-related-feature/' + data?.source_icon_name;
    this.imageChangedEvent = await this.getBase64ImageFromUrl(sourceImage);
    this.imgData.cropData = data.cropped_data;
    // this.imgData.sourceImageUrl=this.imageChangedEvent;
    // this.imgData.croppedImageUrl = await this.getBase64ImageFromUrl(sourceImage);

    this.editId = data.product_feature_id;
    this.videoProductRelatedFormGroup.controls['name'].setValue(data.product_feature_name);
    // this.videoProductRelatedFormGroup.controls['showInHeaderMenu'].setValue(data.show_in_header_menu);
    this.videoProductRelatedFormGroup.controls['isPublished'].setValue(data.active_status);
    window.scrollTo(0, 0);
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  resetForm() {
    this.editId = '';
    this.videoProductRelatedFormGroup.reset({
      isPublished: true
    });
    this.imageChangedEvent = [];
  }

}
