import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VarientService {

  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  // Create Data
  createProductVarientData(userId, accessToken, csrfToken, productId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_id", productId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-variant-create-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  // Create formData
  createProductVarient(userId, accessToken, csrfToken, vendorId, productId, productVariantData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);
    fd.append("product_variant", JSON.stringify(productVariantData));
    console.log('productVariantData', productVariantData);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-variant-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editProductVarientgetData(userId, accessToken, csrfToken, vendorId, productId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-variant-edit-page-get-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editProductVarient(userId, accessToken, csrfToken, vendorId, productId, productVariantData) {
    console.log(productVariantData)
      let fd = new FormData();
      
      fd.append("user_id", userId);
      fd.append("access_token", accessToken);
      fd.append("vendor_id", vendorId);
      fd.append("product_id", productId);
      fd.append("product_variant", JSON.stringify(productVariantData));

      let headers = new HttpHeaders()
      .set('Authorization', csrfToken);

      return this._http.post(this.apiBaseUrl + 'product-variant-edit', fd, { headers: headers })
      .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
