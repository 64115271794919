import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ManageDiscountCodeService } from './manage-discount-code.service'
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manage-discount-code',
  templateUrl: './manage-discount-code.component.html',
  styleUrls: ['./manage-discount-code.component.scss']
})
export class ManageDiscountCodeComponent implements OnInit {
  model;
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;  
  public pageData: any = [];
  
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;
  
  public discountCodeEditId: string = '';
  public discountCodeDeleteId: string = '';
  public today: Date;
  public startMinDate: Date;
  public endMinDate: Date;

  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'approveStatus': ['', [Validators.required]]
  });

  public createEditDiscountCodeFormGroup: FormGroup = this.formBuilder.group({
    'discountPercentage': ['', [Validators.required, Validators.maxLength(6), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'startDate': [new Date(), [Validators.required]],
    'endDate': [null, [Validators.required]],
    'isPublished': [true, [Validators.required]]
  });

  constructor(private _formBuilder: FormBuilder, private _manageDiscountCodeService: ManageDiscountCodeService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    this.today = new Date();
    this.startMinDate = new Date();
    this.endMinDate = new Date();    
    this.endMinDate.setDate(this.createEditDiscountCodeFormGroup.value.startDate.getDate() + 1);
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._manageDiscountCodeService.getPageData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        // console.log(this.pageData)
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  onStartDateChangeEvent(value: Date): void{
    this.createEditDiscountCodeFormGroup.controls['endDate'].setValue(null);
    if(value == null){
      this.createEditDiscountCodeFormGroup.controls['startDate'].setValue(this.today);  
    }
    else
    {
      this.createEditDiscountCodeFormGroup.controls['startDate'].setValue(value);
    }    
    // this.endMinDate.setDate(value.getDate() + 1);
    this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate()+1);
  }

  onEndDateChangeEvent(){
    // console.log(222)
  }

  createEditFormSubmit() {
    if (this.discountCodeEditId != '') {
      this.editDiscountCode();
    }
    else {
      this.createDiscountCode();
    }
  }

  deleteDiscountCode() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._manageDiscountCodeService.deleteDiscount(this._userId, this._accessToken, this._csrfToken, this.discountCodeDeleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.discountCodeDeleteSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.discountCodeEditId = data.discount_id;
    // var startDate = data.start_date.split("-");

    var starParts = data.start_date.split('-');
    var endParts = data.end_date.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var startDate = new Date(starParts[0], starParts[1] - 1, starParts[2]); 
    var endDate = new Date(endParts[0], endParts[1] - 1, endParts[2]);
    this.startMinDate = startDate;
    
    this.createEditDiscountCodeFormGroup.controls['discountPercentage'].setValue(data.discount_percentage);
    this.createEditDiscountCodeFormGroup.controls['startDate'].setValue(startDate);
    this.createEditDiscountCodeFormGroup.controls['endDate'].setValue(endDate);
    this.createEditDiscountCodeFormGroup.controls['isPublished'].setValue(data.active_status);

    window.scrollTo(0, 0);
  }

  editDiscountCode() {
    this._emitter.emit('showLoader');
    this._manageDiscountCodeService.editDiscount(this._userId, this._accessToken, this._csrfToken, this.createEditDiscountCodeFormGroup.value, this.discountCodeEditId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.discountCodeEditedSuccess
        });
        this.pageData = resp.data;
        this.discountCodeEditId = '';
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createDiscountCode() {
    this._emitter.emit('showLoader');
    this._manageDiscountCodeService.createDiscount(this._userId, this._accessToken, this._csrfToken, this.createEditDiscountCodeFormGroup.value)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 409) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.discountCodeCreateSametime
        });
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.discountCodeCreateSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetForm() {
    this.discountCodeEditId = '';
    this.createEditDiscountCodeFormGroup.reset({
      isPublished: true,
      startDate: this.today
    });
    // this.createEditDiscountCodeFormGroup.controls['startDate'].setValue(this.today);
    this.createEditDiscountCodeFormGroup.controls['endDate'].setValue(null);
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getPageDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getPageDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getPageDataFunc();
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }
}
