import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdvertisementsService } from './advertisement.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';



@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementsComponent implements OnInit {
  baseUrl = environment.baseUrl;

  bannerData
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public formGroupData: FormGroup = this._formBuilder.group({
    'bannername': ['', [Validators.required, Validators.maxLength(120)]],
    'alttext': [undefined, [Validators.required]],
    'outputimagewidthpx': [100, [Validators.required]],
    'outputimageheightpx': [100, [Validators.required]],
    'redirectUrl':['', [Validators.required]],
    'isPublished': [true, [Validators.required]]
  });
  public pageData: any = [];
  public editId: string = '';
  public deleteId: string = '';
  public bannerNoImageUrl = 'assets/img/no-img-5x1.jpg';
  public imageChangedEvent: any = [];
  public dataPerPage: number = 0;
  public totalData: number = 0;
  public currentPageNumber: number = 1;
  public flag: number = 0;
  public height: number = 0;
  public weight: number = 0;
  public text: string = '';
  constructor(
    private _modalService: BsModalService, private _formBuilder: FormBuilder,
    private _advertisementsService: AdvertisementsService, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit() {
    console.log(this.imageWidth.value);
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  get imageWidth() { return this.formGroupData.get('outputimagewidthpx') };
  get imageHieght() { return this.formGroupData.get('outputimageheightpx') };

  public imgData = {
    croppedImageUrl: this.bannerNoImageUrl,
    sourceImageUrl: '',
    cropData: {
      x: '',
      y: '',
      w: '',
      h: ''
    }

  };

  croppedOutput(croppedResult) {
    this.imgData.croppedImageUrl = croppedResult.croppedImage;
    this.imgData.sourceImageUrl = croppedResult.sourceImage;
    this.imgData.cropData.x = croppedResult.croppedData.x;
    this.imgData.cropData.y = croppedResult.croppedData.y;
    this.imgData.cropData.w = croppedResult.croppedData.w;
    this.imgData.cropData.h = croppedResult.croppedData.h;
  }

  // public bannerSection: any = [JSON.parse(JSON.stringify(this.bannerSectionBlankDataObj))];
  croppedImage: any = '';

  deleteFunc() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._advertisementsService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');
        this.modalRef.hide();

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.advertisementDeletedSuccess
          });
          this.pageData = resp.data;
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this.modalRef.hide();
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._advertisementsService.getPageData(this._userId, this._accessToken, this._csrfToken)
      .subscribe((resp) => {
        console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.pageData = [];
        }
        else if (resp.status == 200) {
          console.log(resp);
          this.pageData = resp.data;
          this.totalData = this.pageData.length;
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        if (typeof this.modalRef !== 'undefined') {
          this.modalRef.hide();
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  formSubmitted() {
    if (this.editId != '') {
      this.editFunc();
    }
    else {
      this.createFunc();
    }
  }

  createFunc() {
    this._emitter.emit('showLoader');
    this._advertisementsService.create(this._userId, this._accessToken, this._csrfToken, this.formGroupData.value, this.imgData)
      .subscribe((resp) => {
        console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.pageData = [];
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.advertisementCreateSuccess
          });
          this.pageData = resp.listing_data.data;
          this.resetForm();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if (_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'];
      }
      fr.readAsDataURL(_this.files[0]);
    }
  }

  editFunc() {

    // console.log(this.imageChangedEvent);

    this._emitter.emit('showLoader');
    this._advertisementsService.edit(this._userId, this._accessToken, this._csrfToken, this.editId, this.formGroupData.value, this.imgData)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.pageData = [];
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.advertisementEditedSuccess
          });
          this.pageData = resp.data;
          this.resetForm();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  setEditData(data) {
    let sourceImage = this.baseUrl + 'images/uploads/advertisement/' + data?.banner_pic_source;
    this.imgData.cropData = data.banner_pic_crop_data;
    // this.imgData.sourceImageUrl=this.imageChangedEvent;
    // this.imgData.croppedImageUrl = await this.getBase64ImageFromUrl(sourceImage);
    if(data.banner_id == 'ADVB12496434'){
      this.flag = 0;this.height = 1558;this.weight = 159;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }else if(data.banner_id == 'ADVB20274214' ){
      this.flag = 1;this.height = 506;this.weight = 264;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }else if(data.banner_id == 'ADVB07887635' ){
      this.flag = 2;this.height = 506;this.weight = 258;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }else if(data.banner_id == 'ADVB69020483' ){
      this.flag = 3;this.height = 769;this.weight = 402;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }else if(data.banner_id == 'ADVB94491158' ){
      this.flag = 4,this.height = 769,this.weight = 402;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }else if(data.banner_id == 'ADVB82214878' ){
      this.flag = 5;this.height = 1753;this.weight = 210;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }else if(data.banner_id == 'ADVB04085283' ){
      this.flag = 6;this.height = 311;this.weight = 612;
      this.text = ' Best Resolution Size: '+this.height+' X '+this.weight+'px';
    }
    this.editId = data.banner_id;
    this.formGroupData.controls['bannername'].setValue(data.banner_name);
    this.formGroupData.controls['alttext'].setValue(data.alt_text);
    this.formGroupData.controls['outputimagewidthpx'].setValue(data.output_width);
    this.formGroupData.controls['outputimageheightpx'].setValue(data.output_height);
    this.formGroupData.controls['redirectUrl'].setValue(data.redirect_url);
    this.formGroupData.controls['isPublished'].setValue(data.active_status);
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.getBase64ImageFromUrl(sourceImage).then(result=>{
        this.imageChangedEvent = result.toString();
      });
    }, 200);
  }

  getBase64ImageFromUrl(imageUrl) {
    return new Promise((resolve, reject) => {
      let canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d"),
          img = new Image();
      
      img.crossOrigin = "anonymous";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      };
      img.src = imageUrl;
    });
  }

  resetForm() {
    this.editId = '';
    this.formGroupData.reset({
      isPublished: true,
      outputimagewidthpx: 100,
      outputimageheightpx: 100
    });
    this.imageChangedEvent = [];
  }
  

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    
  }

}
