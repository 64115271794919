import { Component, Inject, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UploadVideosService } from './upload-videos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';


import {HttpEventType, HttpResponse} from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
 
declare var $: any;

@Component({
  selector: 'app-upload-videos',
  templateUrl: './upload-videos.component.html',
  styleUrls: ['./upload-videos.component.scss']
})
export class UploadVideosComponent implements OnInit {

  private data: any;
  public uploadPercent;
  // Track upload status by tracking code
  // 0 - Not started
  // 1 - File chosen
  // 2 - Wrong file type
  // 3 - Uploading
  // 4 - Upload error
  // 5 - Upload complete
  public uploadStatus: Number = 0;

  
  private vimeoAPI: string = '930133a6f51c20bf41eed8ac57cf7897';
  // private vimeoVideoName: string = 'TEST'; 

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public currentPageNumber: number = 1;
  public dataPerPage: number = 50;
  public config: any = {
    height: 150
  };
  public pageData: any = [];
  public categoryListing: any = [];
  public detailsModalData: any = {};
  public videoFile;
  public timeHour:string = '00';
  public timeMinute:string = '00';
  public timeSecs:string = '00';
  // public editId: string = '';
  // public deleteId: string = '';

  public maxUploadSizeInMb: number = 0;
  public maxUploadFiles: number = 0;

  public membershipPackageLimitReached: boolean = false;
  public uploadedUrl: string = '';

  public videoNameErrorShow: boolean = false;
  public videoDescriptionErrorShow: boolean = false;

  public videoName:string = '';
  public time:any = [];
  public videoDuration: any = 0;
  public videoUrl: any;
  public videoId:string = '';
  public minDate:any;
  public videoThumnail = this.appVariables.noImageUrl;
  public uploadedVideoUrl:any;
  public timeArr: any = [{'time':'00:00','class':0},{'time':'00:30','class':0},{'time':'01:00','class':0},{'time':'01:30','class':0},{'time':'02:00','class':0},{'time':'02:30','class':0},{'time':'03:00','class':0},{'time':'03:30','class':0},{'time':'04:00','class':0},{'time':'04:30','class':0},{'time':'05:00','class':0},{'time':'05:30','class':0},{'time':'06:00','class':0},{'time':'06:30','class':0},{'time':'07:00','class':0},{'time':'07:30','class':0},{'time':'08:00','class':0},{'time':'08:30','class':0},{'time':'09:00','class':0},{'time':'09:30','class':0},{'time':'10:00','class':0},{'time':'10:30','class':0},{'time':'11:00','class':0},{'time':'11:30','class':0},{'time':'12:00','class':0},{'time':'12:30','class':0},{'time':'13:00','class':0},{'time':'13:30','class':0},{'time':'14:00','class':0},{'time':'14:30','class':0},{'time':'15:00','class':0},{'time':'15:30','class':0},{'time':'16:00','class':0},{'time':'16:30','class':0},{'time':'17:00','class':0},{'time':'17:30','class':0},{'time':'18:00','class':0},{'time':'18:30','class':0},{'time':'19:00','class':0},{'time':'19:30','class':0},{'time':'20:00','class':0},{'time':'20:30','class':0},{'time':'21:00','class':0},{'time':'21:30','class':0},{'time':'22:00','class':0},{'time':'22:30','class':0},{'time':'23:00','class':0},{'time':'23:30','class':0}];
  public videoFormGroup: FormGroup = this._formBuilder.group({
    'vimeoAPI': ['', []],
    'vimeoVideoName': ['', [Validators.required]],
    'vimeoVideoDescription': ['', [Validators.required]],
    'category': ['', [Validators.required]],
    'date': ['', [Validators.required]],
    'isFree': [false, [Validators.required]],
    'activeStatus' : [true,[Validators.required]]
  });

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _uploadVideoService: UploadVideosService, private _sanitize: DomSanitizer,private _activateRoute:ActivatedRoute) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");      
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    // Init Vimeo Data Form
    // this.initVimeoForm();
    if(typeof this._activateRoute.snapshot.params.videoId != 'undefined') {
      this.getEditPageData();
      this.videoId = this._activateRoute.snapshot.params.videoId;
    } else {
      this.getPageDataFunc();
    }
    let date = new Date();
    this.minDate = date.getFullYear()+'-'+(date.getMonth() + 1)+'-'+(date.getDate() + 1);
    this.videoFormGroup.controls['vimeoAPI'].setValue(this.vimeoAPI);
    // Return Vimeo Link from API response
    this._uploadVideoService.vimeoLinkObs.subscribe(
      data => {
        console.log('Video Url: ' + data);
        this.uploadedUrl = data;
      }, error => {
        throw new Error(error);
      }
    ); 
  }

  getDuration(e) {
    console.log(parseInt(e.target.duration));
    this.videoDuration=parseInt(e.target.duration);
    this.timeHour = ('0' + parseInt((this.videoDuration/ 3600).toString())).slice(-2);
    this.timeMinute = ('0' + parseInt(((this.videoDuration % 3600) / 60).toString())).slice(-2);
    this.timeSecs = ('0' + (this.videoDuration % 3600) % 60).slice(-2);
    console.log(this.timeHour,this.timeMinute,this.timeSecs);
  }
  timeData(data,index) {
    let timeIndex = this.time.indexOf(data)
    if(this.timeArr[index].class == 1) {
      console.log(timeIndex);
      this.timeArr[index].class = 0;
      this.time.splice(timeIndex,1);
    } else {
      // let duration = this.addTimes(this.timeHour+':'+this.timeMinute,this.time.slice(-1)[0]);
      console.log(this.addTimes(this.timeHour+':'+this.timeMinute,data));
      if(this.time.length == 0) {
        this.time.push(data);
        this.timeArr[index].class = 1;
      } else if(this.dateCompare(this.addTimes(this.timeHour+':'+this.timeMinute,this.time.slice(-1)[0]),data) == -1) {
        this.time.push(data);
        this.timeArr[index].class = 1;
      } else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.videoDurationError
        });
      }
      console.log(this.timeArr,'time');
    }
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._uploadVideoService.getEditPageData(this._userId,this._accessToken,this._csrfToken,this._activateRoute.snapshot.params.videoId).subscribe(resp=>{
      this._emitter.emit('hideLoader');
      if(resp.status == 200) {
        this.categoryListing = resp.data.category_listings;
        this.videoFormGroup.controls['category'].setValue(resp.data.video_data.category_id);
        this.videoFormGroup.controls['vimeoVideoName'].setValue(resp.data.video_data.video_name);
        this.videoFormGroup.controls['vimeoVideoDescription'].setValue(resp.data.video_data.video_description);
        this.videoFormGroup.controls['isFree'].setValue(resp.data.video_data.is_free);
        this.videoFormGroup.controls['date'].setValue(resp.data.video_data.schedule_date);
        this.videoFormGroup.controls['activeStatus'].setValue(resp.data.video_data.active_status);
        this.videoDuration = resp.data.video_data.video_duration_in_seconds;
        for(let i in resp.data.video_data.scheduled_time) {
          this.time.push(resp.data.video_data.scheduled_time[i].split(':')[0]+':'+resp.data.video_data.scheduled_time[i].split(':')[1])
          for(let j in this.timeArr) {
            if(this.timeArr[j].time.match(resp.data.video_data.scheduled_time[i].split(':')[0]+':'+resp.data.video_data.scheduled_time[i].split(':')[1])) {
              this.timeArr[j].class = 1;
            }
          }
        }
        this.uploadedUrl = resp.data.video_data.video_url;
        this._uploadVideoService.getVideoData(this.uploadedUrl.split('/')[3]).subscribe(data=>{
          this.videoThumnail = data[0].thumbnail_small;
        });
        this.uploadedVideoUrl = this._sanitize.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.uploadedUrl.split('/')[3]);
        this.videoName = resp.data.video_data.video_name;
      } else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    },err=>{
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }else if(err.status == 400){
        this._emitter.emit('showAlert', {
          type: 2,
          msg: err.error.message
        });
      }else{        
        this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
      }
    })
  }

  
  dateCompare(time1,time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0],parts[1],0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0],parts[1],0);
  
    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime()>t2.getTime()) return 1;
    if (t1.getTime()<t2.getTime()) return -1;
    return 0;
  }

  timeToMins(time) {
    var b = time.split(':');
    return b[0]*60 + +b[1];
  }
  timeFromMins(mins) {
    function z(n){return (n<10? '0':'') + n;}
    var h = (mins/60 |0) % 24;
    var m = mins % 60;
    return z(h) + ':' + z(m);
  }
  addTimes(t0, t1) {
    return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._uploadVideoService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }      
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });        
      }
      else if(resp.status == 200 && resp.data != '') {
        this.pageData = resp.data;
        this.categoryListing = resp.data.category_listings;
        this.videoFormGroup.controls['category'].setValue(resp.data.category_listings[0].video_category_id);
        this.maxUploadSizeInMb = this.pageData.max_upload_size_in_mb - this.pageData.upload_video_size_in_mb;
        this.maxUploadFiles = this.pageData.max_upload_number - this.pageData.total_uploaded_videos;

        if(this.maxUploadSizeInMb <= 0 || this.maxUploadFiles <= 0) {
          alert('You have reached to the limit of your membership package.');
          this.membershipPackageLimitReached = true;
        }
      }      
    }, (err) => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }else if(err.status == 400){
        this._emitter.emit('showAlert', {
          type: 2,
          msg: err.error.message
        });
      }else{        
        this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
      }
      
    });
  }
  selectFile(event): void {

    this.videoName = event.target.files[0].name;
    let file = event.target.files[0];
    this.videoFile = event.target.files;
    if (this.videoFile && this.videoFile[0]) {
      this.videoUrl = this._sanitize.bypassSecurityTrustUrl(URL.createObjectURL(this.videoFile[0]));
    }
    // console.log(file.size,this.maxUploadSizeInMb * 1024 * 1024);
    if (file.size > this.maxUploadSizeInMb * 1024 * 1024) {      
      alert('max file size exceeded!');
      file = null;
      return;
    }  
  }

  uploadVimeoVideo(files: FileList): void {
    console.log(this.videoFormGroup.value)
    if(!this.videoFormGroup.controls['vimeoVideoName'].valid) {
      this.videoNameErrorShow = true;
    }
    if(!this.videoFormGroup.controls['vimeoVideoDescription'].valid) {
      this.videoDescriptionErrorShow = true;
    }
    
    if(this.videoFormGroup.controls['vimeoVideoName'].invalid) {
      this._emitter.emit('showAlert', {
        type: 2,
        msg: 'Video Name field is mandatory.'
      });
      return;
    }
    if(this.videoFormGroup.controls['vimeoVideoDescription'].invalid) {
      this._emitter.emit('showAlert', {
        type: 2,
        msg: 'Video Description field is mandatory.'
      });
      return;
    }
    this.uploadStatus = 1;

    if(this.videoId != '' ) {
      this._emitter.emit('showLoader');
      this._uploadVideoService.editVideoData(this._userId,this._accessToken,this._csrfToken,this.videoId,this.videoFormGroup.value,this.time).subscribe(resp=>{
        this._emitter.emit('hideLoader');
        if(resp.status == 200) {
          this.videoFormGroup.controls['vimeoVideoName'].setValue('');
          this.videoFormGroup.controls['vimeoVideoDescription'].setValue('');
          this.uploadedUrl ='';
          this.uploadStatus = 0;
          this.videoId = '';
          this._emitter.emit('showAlert', {
            type: 1,
            msg: 'Your video Infomation has been updated successfully.'
          });
          setTimeout(() => {
            this._router.navigateByUrl('/live-videos/my-live-videos');
          }, 2000);
        }
      },err=>{

      })
      return;
    }

    const file: File = files[0];
    // console.log(file);
    console.log(this.videoFormGroup.value);

    if (file.size > this.maxUploadSizeInMb * 1024 * 1024) {
      
      alert('max file size exceeded!');
      return;
    }

    const isAccepted = this.checkAllowedType(file.type);
    if (isAccepted) {
      this.uploadStatus = 1;
      const options = {
        // token : this.getFormValue('vimeoAPI'),
        // url : 'https://api.vimeo.com/me/videos',
        // videoName: this.getFormValue('vimeoVideoName'),
        // videoDescription: this.getFormValue('vimeoVideoDescription')
        token : this.vimeoAPI,
        url : 'https://api.vimeo.com/me/videos',
        videoName: this.getFormValue('vimeoVideoName'),
        videoDescription: this.getFormValue('vimeoVideoDescription')        
// vimeoVideoName
      };
      
      this._emitter.emit('showLoader');

      this._uploadVideoService.createVimeo(options, file.size)
        .pipe(
          map(data => this.data = data),
          switchMap(
            () => {
              this._uploadVideoService.updateVimeoLink(this.data.link);
              if (this.data.upload.size === file.size) {
                return this._uploadVideoService.vimeoUpload(this.data.upload.upload_link, file);
              } else {
                this.uploadStatus = 4;
              }
            }
          )
        ).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadPercent = Math.round(100 * event.loaded / event.total);
            this.uploadStatus = 3;
          } else if (event instanceof HttpResponse) {
            this.uploadStatus = 5;
            setTimeout(() => {
              this.uploadStatus = 0;
            }, 5000);
          }
        },
        (error) => {
          console.log('Upload Error:', error);
          this._emitter.emit('hideLoader');
          this.uploadStatus = 4;
        }, () => {
          console.log('Upload done');
          // call api 

          this._uploadVideoService.videoUploadDB(this._userId, this._accessToken, this._csrfToken,this.videoFormGroup.value,((file.size / 1024) / 1024).toFixed(3), this.uploadedUrl,this.videoDuration,this.time).subscribe(res=>{
            this._emitter.emit('hideLoader');
            console.log(res);
            if(res.status == 200) {
              this.videoFormGroup.controls['vimeoVideoName'].setValue(null);
              this.videoFormGroup.controls['vimeoVideoDescription'].setValue(null);
              this._emitter.emit('showAlert', {
                type: 1,
                msg: 'Your video has been uploaded successfully.'
              });
              setTimeout(() => {
                this._router.navigateByUrl('/live-videos/my-live-videos');
              }, 2000);
            }
          });
        }
      );
    
      this.videoName = '';
    } else {
      this.uploadStatus = 2;
    }
  }

  initVimeoForm() {
    this.videoFormGroup = new FormGroup(
      {
        // vimeoAPI: new FormControl('', [Validators.required]),
        vimeoVideoName: new FormControl('', [Validators.required]),
        vimeoVideoDescription: new FormControl('', [Validators.required]),
        category: new FormControl('', [Validators.required]),
        subCategory: new FormControl('', [Validators.required]),
        isFree: new FormControl(true, [Validators.required])
      }
    );
  }

  // HELPERS
  allowUpload(): void {
    this.uploadStatus = 0;
  }

  checkAllowedType(filetype: string): boolean {
    const allowed = ['mov', 'wmv', 'avi', 'flv', 'mp4'];
    const videoType = filetype.split('/').pop();
    return allowed.includes(videoType);
  }

  getFormValue(selector: string) {
    return this.videoFormGroup.get(selector).value;
  }

  detailsModal(videoName) {
    
  }


}
