import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { FaqService } from './faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public faqId: string = '';
  public deleteFaqId: string = '';
  public config: any = {
    height: 150,
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
    ],
  };
  public faqListingData: any = [];
  public currentPageNumber: number = 1;
  public numberOfPages: number = 0;
  public dataPerPage: number = 20;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  public addEditFaqForm: FormGroup = this.formBuilder.group({
    'faqTitle': ['', [Validators.required]],
    'faqDescription': ['', [Validators.required]],
    'isPublish': [true, []]
  });

  public faqSearchForm: FormGroup = this.formBuilder.group({
    'searchText': ['', [Validators.required]],
    'isPublish': [0, [Validators.required]]
  });

  constructor(private _modalService: BsModalService, public formBuilder: FormBuilder,private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _faqService: FaqService, public appVariables: AppVariables, private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getListingData();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber - 1;
    this.getListingData();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getListingData();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getListingData();
  }

  setEditData(faqData) {
    this.faqId = faqData.faq_id;
    this.addEditFaqForm.controls['faqTitle'].setValue(faqData.faq_question);
    this.addEditFaqForm.controls['faqDescription'].setValue(faqData.faq_answer);
    this.addEditFaqForm.controls['isPublish'].setValue(faqData.active_status);
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.addEditFaqForm.controls['faqTitle'].setValue('');
    this.addEditFaqForm.controls['faqDescription'].setValue('');
    this.addEditFaqForm.controls['isPublish'].setValue(true);
  }

  getListingData() {
    this._emitter.emit('showLoader');
    this._faqService.faqListingsData(this._userId, this._accessToken, this._csrfToken, this.faqSearchForm.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.faqListingData = [];
      }
      else if(resp.status == 200) {
        this.faqListingData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  formSubmitted() {
    if(this.faqId == '') {
      this.createFaqFunc();
    }
    else {
      this.editFaqFunc();
    }
  }

  createFaqFunc() {
    this._emitter.emit('showLoader');
    this._faqService.createFaq(this._userId, this._accessToken, this._csrfToken, this.addEditFaqForm.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.faqListingData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.faqCreateSuccess
        });
        this.faqListingData = resp.data;
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editFaqFunc() {
    this._emitter.emit('showLoader');
    this._faqService.editFaq(this._userId, this._accessToken, this._csrfToken, this.addEditFaqForm.value, this.faqId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.faqListingData = [];
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.invalidFaqId
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.faqEditedSuccess
        });
        this.faqListingData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.resetForm();
        this.faqId = '';

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteTestimonial() {
    this._emitter.emit('showLoader');
    this._faqService.deleteFaq(this._userId, this._accessToken, this._csrfToken, this.deleteFaqId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.faqListingData = [];

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.resetForm();
        this.deleteFaqId = '';

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        this.modalRef.hide()
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.invalidFaqId
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.faqDeletedSuccess
        });
        this.faqListingData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.resetForm();
        this.deleteFaqId = '';

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        this.modalRef.hide()
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
