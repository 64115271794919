import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorProductListingsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getListingData(userId, accessToken, csrfToken, vendorId, searchText, isPublished, currentPageNumber) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("search_text", searchText);
    fd.append("is_published", isPublished);
    fd.append("page_no", currentPageNumber);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-listing', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editVerificationStatus(userId, accessToken, csrfToken, gymOwnerId, gymBranchId, verifyStatus) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("gym_owner_id", gymOwnerId);
    fd.append("gym_branch_id", gymBranchId);
    fd.append("verification_status", verifyStatus);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gym-branch-verification-status-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editPublishStatus(userId, accessToken, csrfToken, gymBranchId, publishStatus) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_id", gymBranchId);
    fd.append("publish_status", publishStatus);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-update-publish-status', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteProduct(userId, accessToken, csrfToken, gymOwnerId, gymBranchId, searchText, isPublished, currentPageNumber) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", gymOwnerId);
    fd.append("product_id", gymBranchId);
    fd.append("is_published", isPublished);
    fd.append("search_text", searchText);
    fd.append("page_no", currentPageNumber);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
