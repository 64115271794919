import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { TrainerPurchaseService } from './trainer-purchase.service';


@Component({
  selector: 'app-trainer-purchase-history',
  templateUrl: './trainer-purchase-history.component.html',
  styleUrls: ['./trainer-purchase-history.component.scss']
})

export class TrainerPurchaseHistoryComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  
  public currentPageNumber: number = 1;
  public dataPerPage: number = 50;  
  public pageData: any = [];
  public productTaxCodesListing: any = [];

  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  public orderEditId: string = '';

  public searchFormGroup: FormGroup = this._formBuilder.group({
    'searchText': ['', []]
  });

  // public orderHistoryEditFormGroup: FormGroup = this.formBuilder.group({
  //   'orderId': ['', []],
  //   'productTaxCode': ['', []],
  //   'principalAmount': ['', [Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/)]],
  //   'principalAmountBasic': ['', [Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/)]]    
  // });

  constructor(private _formBuilder: FormBuilder, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService,public _trainerService: TrainerPurchaseService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getPageDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }

  }


  getPageDataFunc() {    
    
    this._emitter.emit('showLoader');
    this._trainerService.getPageData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);return;
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        // console.log(this.pageData)
        this.dataPerPage = resp.data_per_page;
        this.productTaxCodesListing = resp.product_tax_codes_listing;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['searchText'].setValue('');
    
    this.getPageDataFunc();
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getPageDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getPageDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getPageDataFunc();
  }
}
