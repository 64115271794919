import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { OrderHistoryService } from './order-history.service'
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  viewDetailsModalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public addressPin: number;
  public pickupPostcode: number;
  private shipOrderId: number;
  public weight: number;
  public declaredValue: number;
  public isReturn: number;
  public available_courier_companies: [];

  public courierId: any;

  public username: string = '';
  public bookingId: string = '';
  public statusType: number = 1;
  
  public currentPageNumber: number = 1;
  public dataPerPage: number = 50;  
  public pageData: any = [];
  public productTaxCodesListing: any = [];

  public detailsModalData: any = [];

  public numberOfPages: number = 0;
  public paginationArr: any = [];
  productList: any =[];

  public orderStatusList: any = [];

  public searchCriteriaChanged: boolean = false;

  public orderEditId: string = '';

  public totalData: number = 0;

  public today: Date;
  public startMinDate: Date;
  public endMinDate: Date;

  public address: any= {};
  public orderStatus: '';

  public gstPrice: number = 0;

  public startDate:string = '';
  public endDate:string = '';

  public sellerListingSearch: any =[];
  public searchFlag: number = 0; 

  public selectSellerId: string;

  public selectSellerName: string;
  public statusNote:string=''; 
  
  public startEndDateFormGroup: FormGroup = this._formBuilder.group({
    'startEndDate': ['', []],
    'sellerName': ['', []],
    'sellerId': ['',[]]
  });

  public shipRocketFormGroup: FormGroup = this._formBuilder.group({
    'order_awb_code': ['',[]]
  });

  public searchFormGroup: FormGroup = this._formBuilder.group({
    'orderId': ['', [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
    'gymOwnerId': ['', [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
    'gymBranchId': ['', [Validators.pattern(/^[0-9a-zA-Z]+$/)]],
    'startDate': [null, []],
    'endDate': [null, []],    
    'verifyStatus': ['0', []],
    'approveStatus': ['', []],
    'search': ['', []],
    'publishStatus': ['0', [Validators.required]]
  });

  public orderHistoryEditFormGroup: FormGroup = this.formBuilder.group({
    'orderId': ['', []],
    'productTaxCode': ['', []],
    'principalAmount': ['', [Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/)]],
    'principalAmountBasic': ['', [Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/)]]    
  });

  constructor(private _formBuilder: FormBuilder, private _orderHistoryService: OrderHistoryService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables, public formBuilder: FormBuilder, private _ngZone: NgZone, private _activatedRoute: ActivatedRoute, private _modalService: BsModalService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this.pickupPostcode = userDetails.admin_postcode;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.username = userDetails.user_name;
      // this.getPageDataFunc();
      this.orderHistoryListFunc();

    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    this.today = new Date();
  }


  productTotalListingPrice(data){
    let price = 0;
    for(let elem of data.product_list)
      price -= (parseInt(elem.product_listing_price) * (-1));

    return price;
  }

  totalCGSTAmount(data){
    let price = 0;
    for(let elem of data.product_list)
      price -= (parseInt(elem.cgst_amount) * (-1));

    return price;
  }

  totalSGSTAmount(data){
    let price = 0;
    for(let elem of data.product_list)
      price -= (parseInt(elem.sgst_amount) * (-1));

    return price;
  }

  totalIGSTAmount(data){
    let price = 0;
    for(let elem of data.product_list)
      price -= (parseInt(elem.igst_amount) * (-1));

    return price;
  }

  totalGSTAmount(data){
    let price = 0;
    for(let elem of data.product_list)
      price -= (parseInt(elem.gst_price) * (-1));

    return price;
  }

  totalPrice(data){
    let price = this.productTotalListingPrice(data);
    price += this.totalGSTAmount(data);
    price -= parseInt(data.total_discount_amount);
    price += parseInt(data.delivery_charge);
    return price;
  }

  confirmSaveModal(template1: TemplateRef<any>){
    this.confirmModalRef = this._modalService.show(template1);
  }

  nextStatusFunc(){
    var awbCode= this.shipRocketFormGroup.value.order_awb_code;
    var statusNote :string = this.orderStatusList[this.orderStatusList.length - 1].status_note;
    this._orderHistoryService.orderStatusUpdate(this._userId, this._accessToken, this._csrfToken,this.bookingId, awbCode, this.statusType, statusNote).subscribe((resp) => {
      if(resp.status ==200){
        this.confirmModalRef.hide();
        this.orderStatusList=resp.data.map(elem => {
          return elem;
        });
        
        this.statusType = 1;
        this.addNextOrderStatus(this.orderStatusList);
        for(let data of this.pageData) {
          if(data.invoice_id == this.bookingId) {
            data.order_status_list = resp.data.map(elem => {
              return elem;
            });
            break;
          }
        }
      }
      else{
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {

    });
  }

  orderHistoryListFunc() {    
    this._emitter.emit('showLoader');
    this._orderHistoryService.orderHistoryList(this._userId, this._accessToken, this._csrfToken, this.startEndDateFormGroup.value.sellerName, this.searchFormGroup.value.publishStatus, this.currentPageNumber, this.startDate,this.endDate)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        // this.pageData = resp.data;
        // console.log("list",this.pageData)
          //** unique id display changes done  */
        const uniqueInvoiceIds = new Set();

        this.pageData = resp.data.filter((item) => {
          if (!uniqueInvoiceIds.has(item.invoice_id)) {
            uniqueInvoiceIds.add(item.invoice_id);
            return true;
          }
          return false;
        });

        this.dataPerPage = resp.data_per_page;
        this.totalData = resp.total;
        // this.pageData.forEach(element => {
        //   element.totalGst = 0;
        //   element.product_list.forEach(product => {
            
        //     element.totalGst = element.totalGst + product.gst_price;

        //   });
        //   element.totalGst = element.totalGst.toFixed(2);
        // });


        this.pageData.forEach(element => {
          element.totalGst = 0;
          element.gst = 0;
          element.igst = 0;
          element.totalProductListingPrice = 0;
          element.product_list.forEach(product => {
            
            element.totalGst = element.totalGst + product.gst_price;
            element.gst =  (element.gst +(parseFloat(element.totalGst) / 2));
            element.totalProductListingPrice =  element.totalProductListingPrice + product.product_listing_price;
          });
          element.totalGst = element.totalGst.toFixed(2);
          // element.totalProductListingPrice = element.product_listing_price.toFixed(2);
          // element.gst = element.gst.toFixed(2);
          // element.igst = element.igst.toFixed(2);
        });

        // this.gstPrice = + resp.data.product_list[4].gst_price;
        // console.log("this.pageData",this.pageData);
        // this.orderStatus = resp.data.transaction_status;
        // this.productList  = resp.data[0].product_list;
        // console.log("---",this.productList);
        // this.productTaxCodesListing = resp.product_tax_codes_listing;

        // this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        // this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createCsvFunc(){
    this._emitter.emit('showLoader');
    
    this._orderHistoryService.csvCreate(this._userId, this._accessToken, this._csrfToken, this.startEndDateFormGroup.value.sellerName, this.searchFormGroup.value.publishStatus, this.currentPageNumber, this.startDate,this.endDate)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {        
        window.open(resp.data);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getSellerData(data,evnt){
    this.searchFlag = 1;
    console.log("Value",data,evnt.key);
    if(data.length > 2 && evnt.key != 'Enter') {
      this._orderHistoryService.orderVenderList(this._userId, this._accessToken, this._csrfToken, data).subscribe(resp => {
          console.log(resp);
          if(resp.status == 401) {
            this._router.navigateByUrl('/login');
          }
          else if(resp.status == 200) {
            // this._csrfToken = JSON.stringify(resp.tokens);
            // localStorage.setItem(btoa('tokens'), btoa(this._csrfToken));
            this.sellerListingSearch = resp.data;
          }
          else {
            this._emitter.emit('showAlert', {
              type: 2,
              msg: this.appVariables.someErrorOccurred
            });
          }
        })
    } else {
      this.sellerListingSearch = [];
    }
  }

  getData(data) {
    this.searchFlag = 1;
    this.sellerListingSearch = [];
    this.selectSellerId  = data.vendor_id;
    this.selectSellerName = data.vendor_name;
    this.startEndDateFormGroup.controls['sellerName'].setValue(data.vendor_id);
    this.startEndDateFormGroup.controls['sellerId'].setValue(data.vendor_name);
  }

  orderHistoryEditFormSubmit(){
    this._emitter.emit('showLoader');
    
    this._orderHistoryService.edit(this._userId, this._accessToken, this._csrfToken, this.orderHistoryEditFormGroup.value, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        this.orderHistoryEditFormGroup.reset();
        this.viewDetailsModalRef.hide();
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.orderHistoryEditedSuccess
        });
        this.dataPerPage = resp.data_per_page;
        this.productTaxCodesListing = resp.product_tax_codes_listing;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  onStartDateChangeEvent(value: Date): void{
    this.searchFormGroup.controls['endDate'].setValue(null);
    if(value == null){
      this.searchFormGroup.controls['startDate'].setValue(null);      
    }
    else
    {
      // this.searchFormGroup.controls['startDate'].setValue(value);
      this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate()+1);
    }
    // this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate()+1);
  }

  onEndDateChangeEvent(value: Date){
    console.log(value)    
  }

  getPageDataFunc() {    
    this._emitter.emit('showLoader');
    
    this._orderHistoryService.getPageData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        // console.log(this.pageData)
        this.dataPerPage = resp.data_per_page;
        this.productTaxCodesListing = resp.product_tax_codes_listing;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  viewDetailsModal(template: TemplateRef<any>, productList, data) {
    this.viewDetailsModalRef = this._modalService.show(template, Object.assign({}, { class: 'modal-lg' }) );   
    this.productList = productList; 
    this.address = data;
    this.orderStatusList = Object.assign([],data.order_status_list);
    this.addNextOrderStatus(data.order_status_list);
    this.shipRocketFormGroup.controls['order_awb_code'].setValue(data.shiprocket_order_details[0].awb_code);
  }

  addNextOrderStatus(currentStatusList: any) {
    if(currentStatusList[currentStatusList.length - 1].status_id != 0 && currentStatusList[currentStatusList.length - 1].status_id < 6) {
      this.orderStatusList.push({
        status_id: currentStatusList.length + 1,
        entry_time: '',
        status_note: ''
      });
    }
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.reset({
      startDate: null,
      endDate: null
    });
    this.searchFormGroup.controls['orderId'].setValue('');
    this.searchFormGroup.controls['gymOwnerId'].setValue('');
    this.searchFormGroup.controls['gymBranchId'].setValue('');    
    // this.searchFormGroup.controls['verifyStatus'].setValue('0');
    // this.searchFormGroup.controls['publishStatus'].setValue('0');
    // this.getPageDataFunc();
    this.orderHistoryListFunc();
  }  

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getPageDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getPageDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getPageDataFunc();
  }

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    this.orderHistoryListFunc();
  }

  dateRangeCreated(event) {
    // alert('date range change');
    if(event != null) {
      this.startDate = event[0].toJSON().split('T')[0];  
      this.endDate = event[1].toJSON().split('T')[0];
      console.log(this.startDate,this.endDate);  
      // this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate()+1);
    }
  }

  searchData(){
    this.orderHistoryListFunc();
    this.startEndDateFormGroup.controls['sellerName'].setValue('');
    this.startEndDateFormGroup.controls['sellerId'].reset();
  }

}
