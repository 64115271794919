import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SiteSettingsService {
  apiBaseUrl = environment.apiBaseUrl;
  
  constructor(private _http: HttpClient) { }

  getSiteSettingList(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'site-setting-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createSiteSettings(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("setting_name", formData.titleToShow);
    fd.append("setting_value", formData.settingValueText);
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'site-setting-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editSiteSettings(userId, accessToken, csrfToken, settingId, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("setting_id", settingId);
    fd.append("setting_name", formData.titleToShow);
    fd.append("setting_value", formData.settingValueText);
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'site-setting-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
