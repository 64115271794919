import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../../app.variables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalougeEnquiryListingsService } from './catalouge-enquiry-listings.service';


@Component({
  selector: 'app-catalouge-enquiry-listings',
  templateUrl: './catalouge-enquiry-listings.component.html',
  styleUrls: ['./catalouge-enquiry-listings.component.css']
})
export class CatalougeEnquiryListingsComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public catalogListingsData: any = [];
  // public dataPerPage: number = 20;
  public vendorId: string = '';
  public gymDeleteId: string = '';
  public dataPerPage: number = 0;
  public totalData: number = 0;
  public currentPageNumber: number = 1;
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  public accountsManagementModalData: any = [];
  public accountManagementsListingData: any = [];

  public searchFormGroup: FormGroup = this.formBuilder.group({
    'search': ['', []],
    // 'verifyStatus': ['0', [Validators.required]],
    'publishStatus': ['0', [Validators.required]]
  });
  
  public editPaymentStatusFormGroup: FormGroup = this.formBuilder.group({  
    'enquiryId': ['0', [Validators.required]],
    'paymentStatus': [ 0, [Validators.required]],
  });

  constructor(private _modalService: BsModalService, private _catalougeEnquiryListingsService: CatalougeEnquiryListingsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables,public formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this._activatedRoute.params.subscribe((param) => {
        this.vendorId = param.vendorId;
        this.getListingsDataFunc();
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._catalougeEnquiryListingsService.getListingData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value.search, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp.data);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.catalogListingsData = resp.data;
        // this.totalData = this.catalogListingsData.length
        this.totalData = resp.total
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        // this.paginationArr = this.generatePaginationArr(this.numberOfPages);        
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  accountManagementEditData(template: TemplateRef<any>) {
    // this.editApplicationFormGroup.controls["applicationId"].setValue(this.applicationDetailsModalData.application_id);
    // this.editApplicationFormGroup.controls["paymentStatus"].setValue(this.applicationDetailsModalData.approve_status);
    this.editPaymentStatusFormGroup.controls["enquiryId"].setValue(this.accountsManagementModalData.catalouge_enquiry_id);
    // this.deductionAmnt = this.adminDeductionAmnt(this.accountsManagementModalData.requested_to_encash,this.accountsManagementModalData.admin_deduction_percentage)
    // this.actualAmntToReturn = this.accountsManagementModalData.return_to_bank_amount - this.deductionAmnt;
    // alert(this.deductionAmnt);
    
    this.modalRef = this._modalService.show(template);
  }
  
  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    this.getListingsDataFunc();
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.getListingsDataFunc();
  }

  changePaidStatusFunc() {
    this._emitter.emit('showLoader');
    this._catalougeEnquiryListingsService.changePaidStatus(this._userId, this._accessToken, this._csrfToken, this.editPaymentStatusFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        // this._router.navigateByUrl('/login');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: resp.message
        });
        // this._toastr.error('Enquiry process not updated.', '', {
        //   timeOut: 3000,
        //   positionClass:'toast-bottom-right'
        // });
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: resp.message
        });
      }
      else if(resp.status == 400) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: resp.message
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: resp.message
        });
      }
      else if(resp.status == 200) {
        // this.accountManagementsListingData = resp.balance_listings.data;
        // this.totalData = resp.balance_listings.data.total;
        // this.dataPerPage = resp.balance_listings.data.data_per_page;        

        // this.numberOfPages = (resp.balance_listings.data.total % resp.balance_listings.data.data_per_page != 0) ? parseInt((resp.balance_listings.data.total / resp.balance_listings.data.data_per_page).toString()) + 1 : resp.balance_listings.data.total / resp.balance_listings.data.data_per_page;
        // this.paginationArr = this.generatePaginationArr(this.numberOfPages);
        console.log("resp-12334>>>",resp)
       
        // this.modalRef.hide();
        // alert("Payment details submitted successful and invoice sent to the vendor.")
        // this.toastr.success('Enquiry process edit successfully.', '', {
        //   timeOut: 5000,
        //   positionClass: 'toast-bottom-right'
        // });
        
        this.modalRef.hide();
        this._emitter.emit('showAlert', {
          type: 1,
          msg: resp.message
        });
        this.getListingsDataFunc();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: resp.message
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

}
