import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainerVideosService {
  apiBaseUrl = environment.apiBaseUrl;

  public vimeoAPI: string = '930133a6f51c20bf41eed8ac57cf7897';
  vimeoObsShare: Observable<string>;
  vimeoResult: string;

  private vimeoLink = new BehaviorSubject('');
  vimeoLinkObs = this.vimeoLink.asObservable();

  constructor(private _http: HttpClient) { }

  getTrainerVideosList(userId,accessToken,csrfToken,searchText,dataPerPage,pageNo) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", searchText);
    fd.append("data_per_page", dataPerPage);
    fd.append("pageno", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-live-videos-listing', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));

  }

  deleteTrainerVideo(userId, accessToken, csrfToken, videoKey,searchText,dataPerPage,pageNo) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("live_video_key", videoKey);
    fd.append("search_text", searchText);
    fd.append("data_per_page", dataPerPage);
    fd.append("pageno", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-live-videos-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  activeStatusEdit(userId, accessToken, csrfToken, trainerVideoId, activeStatus,searchText,dataPerPage,pageNo) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("live_video_key", trainerVideoId);
    fd.append("active_status", activeStatus);
    fd.append("data_per_page", dataPerPage);
    fd.append("search_text", searchText);
    fd.append("pageno", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-live-videos-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
  getVideoData(endPoint){
    const initHeaders = new HttpHeaders({'Authorization': 'bearer ' + this.vimeoAPI });
    initHeaders.append('Content-Type', 'application/json');
    initHeaders.append('Accept', 'application/vnd.vimeo.*+json;version=3.4');
    console.log('http://vimeo.com/api/v2/video/'+endPoint+'.json');
    return this._http.get( 'http://vimeo.com/api/v2/video/'+endPoint+'.json');
  }
}
