import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductTaxCodeService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken, searchForm, currentPageNumber) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-tax-code-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  create(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("tax_code", formData.taxCode.trim());    
    fd.append("is_publish", formData.isPublished);    
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-tax-code-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  edit(userId, accessToken, csrfToken, formData, editId) {
    let fd = new FormData();    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_tax_id", editId);
    fd.append("product_tax_code", formData.taxCode.trim());    
    fd.append("is_publish", formData.isPublished);   
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-tax-code-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  delete(userId, accessToken, csrfToken, deleteId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("product_tax_id", deleteId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-tax-code-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
