import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrainerSubscriptionsService } from './trainer-subscriptions.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';

@Component({
  selector: 'app-trainer-subscriptions',
  templateUrl: './trainer-subscriptions.component.html',
  styleUrls: ['./trainer-subscriptions.component.scss']
})
export class TrainerSubscriptionsComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public config: any = {
    height: 150
  };

  public addEditSubscriptionFormGroup: FormGroup = this._formBuilder.group({
    'packageName': ['', [Validators.required, Validators.maxLength(100)]],
    'packageDescription': ['', [Validators.required]],
    'packagePrice': ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/)]],
    'maxUploadSizeInMb': ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'maxUploadNumber': ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'isPublished': [true, [Validators.required]]
  });

  public pageData: any = [];
  public detailsModalData: any = {};
  public editId: string = '';
  public deleteId: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _trainerSubscriptionsService: TrainerSubscriptionsService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  deleteFunc() {
    this._emitter.emit('showLoader');
    this.modalRef.hide()
    this._trainerSubscriptionsService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerSubscriptionDeletedSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createEditFormSubmit() {
    if(this.editId != '') {
      this.editFunc();
    }
    else {
      this.createFunc();
    }
  }

  editFunc() {
    this._emitter.emit('showLoader');
    this._trainerSubscriptionsService.edit(this._userId, this._accessToken, this._csrfToken, this.editId, this.addEditSubscriptionFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerSubscriptionEditedSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  createFunc() {
    this._emitter.emit('showLoader');
    this._trainerSubscriptionsService.create(this._userId, this._accessToken, this._csrfToken, this.addEditSubscriptionFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerSubscriptionCreateSuccess
        });
        this.pageData = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._trainerSubscriptionsService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(data) {
    this.editId = data.package_id;
    this.addEditSubscriptionFormGroup.controls['packageName'].setValue(data.package_name);    
    this.addEditSubscriptionFormGroup.controls['packageDescription'].setValue(data.package_description);
    this.addEditSubscriptionFormGroup.controls['packagePrice'].setValue(data.package_price);
    this.addEditSubscriptionFormGroup.controls['maxUploadSizeInMb'].setValue(data.max_upload_size_in_mb);
    this.addEditSubscriptionFormGroup.controls['maxUploadNumber'].setValue(data.max_upload_number);    
    this.addEditSubscriptionFormGroup.controls['isPublished'].setValue(data.active_status);
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.editId = '';
    this.addEditSubscriptionFormGroup.reset({
      isPublished: true
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  detailsModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

}
