import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from 'events';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppVariables } from '../../../app.variables';
import {TrainerVideosService} from './trainer-videos.service';

@Component({
  selector: 'app-trainer-videos',
  templateUrl: './trainer-videos.component.html',
  styleUrls: ['./trainer-videos.component.scss']
})
export class TrainerVideosComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public pageData: any =[];
  public numberOfPages: number = 0;
  public deleteTranerVideoId: string = '';
  public paginationArr: any = [];
  public currentPageNumber: number = 1;
  public dataPerPage: number = 10;
  public searchText: string = '';
  public detailsModalData: any = {};
  public detailsModalDataVideoUrl: any;
  public videoName = '';
  public videoThumnail = this.appVariables.noImageUrl;
  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
  });
  constructor(private _trainerService:TrainerVideosService,private _formBuilder: FormBuilder,private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter,private _modalService: BsModalService,public appVariables: AppVariables,private _sanitizer: DomSanitizer, private _activateRoute: ActivatedRoute) { }

  ngOnInit(): void {
    try {
      console.log(this._activateRoute.snapshot.queryParams);
      if(typeof this._activateRoute.snapshot.queryParams.video_name != 'undefined') {
        this.searchFormGroup.controls['search'].setValue(this._activateRoute.snapshot.queryParams.video_name);
      }
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getTrainerListData();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }
  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.getTrainerListData();
  }
  toFixed(value: string, upto = 2) {
    return parseFloat(value).toFixed(upto);
  }
  getTrainerListData() {
    this._emitter.emit('showLoader');
    this._trainerService.getTrainerVideosList(this._userId, this._accessToken, this._csrfToken,this.searchFormGroup.value.search,this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  } 

  deleteConfirmed() {
    this._emitter.emit('showLoader');
    this._trainerService.deleteTrainerVideo(this._userId, this._accessToken, this._csrfToken, this.deleteTranerVideoId,this.searchFormGroup.value.search,this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerVideoDeleteSuccess
        });
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerVideoDeleteSuccess
        });
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isActiveStatusChanged(videoId, activeStatus) {
    this._emitter.emit('showLoader');
    this._trainerService.activeStatusEdit(this._userId, this._accessToken, this._csrfToken, videoId, activeStatus,this.searchText,this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.activeStatusChangedSuccess
        });
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }
  detailsModal(template: TemplateRef<any>,videoName) {
    this._trainerService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{
      
      this.videoThumnail = data[0].thumbnail_small;
      // console.log(data);
    });
    this.detailsModalDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.detailsModalData.video_url.split('/')[3]);
    this.videoName = videoName;
    this.modalRef = this._modalService.show(template);
    // this._myUploadedVideosService.getVideoData(this.detailsModalData.video_url.split('/')[3]).subscribe(data=>{ 
    //   console.log(data);
    // });
    // this.videoImg(template);
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getTrainerListData();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getTrainerListData();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getTrainerListData();
  }
}
