import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CouponService } from './coupon.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public numberOfPages: number = 0;
  public currentPageNumber: number = 1;
  public dataPerPage: number = 10;
  public endMinDate: Date;
  public today: Date;
  public startMinDate: Date;
  isDiscountInPercentage = true;
  public couponListings: any = [];
  public couponEditId: string = '';
  public couponDeleteId: string = '';

  public addEditCouponFormGroup = new FormGroup({
    discountPercentage: new FormControl(''),
    flatDiscount: new FormControl(''),
    discountType: new FormControl('discount', [Validators.required]),
    couponName: new FormControl('', [Validators.required]),
    couponCode: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    activeStatus: new FormControl(true, [Validators.required])
  });

  constructor(
    private _modalService: BsModalService,
    private _formBuilder: FormBuilder,
    private _couponService: CouponService,
    private _router: Router,
    @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter,
    public appVariables: AppVariables,
    private datePipe: DatePipe  
  ) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getCouponList();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getCouponList() {
    this._emitter.emit('showLoader');
    this._couponService.getCouponList(this._userId, this._accessToken, this._csrfToken, this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.couponListings = [];
      }
      else if(resp.status == 200) {
        this.couponListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }


  createCouponFunc() {
    this._emitter.emit('showLoader');
    // console.log(">>>>", this.addEditCouponFormGroup.value);yyyy-MM-dd
    // console.log(this.datePipe.transform(this.addEditCouponFormGroup.value.startDate, 'yyyy-MM-dd HH:mm'));
    this.addEditCouponFormGroup.value.startDate = this.datePipe.transform(this.addEditCouponFormGroup.value.startDate, 'yyyy-MM-dd');
    this.addEditCouponFormGroup.value.endDate = this.datePipe.transform(this.addEditCouponFormGroup.value.endDate, 'yyyy-MM-dd');
    console.log(">>>>", this.addEditCouponFormGroup.value);
    this._couponService.createCoupon(this._userId, this._accessToken, this._csrfToken, this.addEditCouponFormGroup.value)
      .subscribe((resp) => {
        // console.log(resp);
        this._emitter.emit('hideLoader');

        if (resp.status == 401) {
          this._router.navigateByUrl('/login');
        }
        else if (resp.status == 201) {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
        else if (resp.status == 204) {
          this.couponListings = [];
        }
        else if (resp.status == 200) {
          this._emitter.emit('showAlert', {
            type: 1,
            msg: this.appVariables.couponCreateSuccess
          });
          this.couponListings = resp.data.listings.data;
          this.resetForm();
          // this.getCouponList();
        }
        else {
          this._emitter.emit('showAlert', {
            type: 2,
            msg: this.appVariables.someErrorOccurred
          });
        }
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/login');
        }
        this._emitter.emit('hideLoader');
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      });
  }

  editCouponFunc() {
    this._emitter.emit('showLoader');
    this.addEditCouponFormGroup.value.startDate = this.datePipe.transform(this.addEditCouponFormGroup.value.startDate, 'yyyy-MM-dd');
    this.addEditCouponFormGroup.value.endDate = this.datePipe.transform(this.addEditCouponFormGroup.value.endDate, 'yyyy-MM-dd');
    console.log(">>>>", this.addEditCouponFormGroup.value);
    this._couponService.editCoupon(this._userId, this._accessToken, this._csrfToken, this.couponEditId, this.addEditCouponFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.couponListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.couponEditedSuccess
        });
        this.couponListings = resp.data.listings.data;
        this.resetForm();
        // this.getCouponList();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  formSubmitted() {
    // this.createCouponFunc();
    if(this.couponEditId != '') {
      this.editCouponFunc();
    }
    else {
      this.createCouponFunc();
    }
  }

  onStartDateChangeEvent(value: Date): void {
    this.addEditCouponFormGroup.controls['endDate'].setValue(null);
    if (value == null) {
      this.addEditCouponFormGroup.controls['startDate'].setValue(this.today);
    } else {
      this.addEditCouponFormGroup.controls['startDate'].setValue(value);
      //this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1);
    }
    this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate()+1);
  }

  // onEndDateChangeEvent(value: Date): void {
  //   this.addEditCouponFormGroup.controls['startDate'].setValue(null);
  //   if (value == null) {
  //     this.addEditCouponFormGroup.controls['endDate'].setValue(null);
  //   } else {
  //     // this.searchFormGroup.controls['startDate'].setValue(value);
  //     this.startMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1);
  //   }
  //   // this.endMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate()+1);
  // }

  onEndDateChangeEvent(value: Date) {
    console.log(value)
  }

  chooseDiscountType() {
    // console.log(event.target.value);
    const value = this.addEditCouponFormGroup.get('discountType').value;
    if (value === 'flat') {
      this.isDiscountInPercentage = false;
      this.addEditCouponFormGroup.get('flatDiscount').setValidators([Validators.required])
      this.addEditCouponFormGroup.get('discountPercentage').clearValidators()
    } else {
      this.isDiscountInPercentage = true;
      this.addEditCouponFormGroup.get('discountPercentage').setValidators([Validators.required])
      this.addEditCouponFormGroup.get('flatDiscount').clearValidators()

    }
    this.addEditCouponFormGroup.get('discountPercentage').updateValueAndValidity();
    this.addEditCouponFormGroup.get('flatDiscount').updateValueAndValidity();

  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  modalClose() {
    this.couponEditId = '';
    this.modalRef.hide();
  }

  deleteCouponFunc() {
    this._emitter.emit('showLoader');
    this._couponService.deleteCoupon(this._userId, this._accessToken, this._csrfToken, this.couponEditId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.couponListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.couponDeletedSuccess
        });
        this.couponListings = resp.data;
        this.modalRef.hide()
        this.resetForm();
        this.getCouponList();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }


  setEditData(data) {
    this.couponEditId = data.coupon_id;
    this.addEditCouponFormGroup.controls['couponName'].setValue(data.coupon_name);
    this.addEditCouponFormGroup.controls['couponCode'].setValue(data.coupon_code);
    this.addEditCouponFormGroup.controls['discountPercentage'].setValue(data.discount_percentage);
    this.addEditCouponFormGroup.controls['flatDiscount'].setValue(data.flat_discount);
    this.addEditCouponFormGroup.controls['startDate'].setValue(new Date(data.start_date));
    this.addEditCouponFormGroup.controls['endDate'].setValue(new Date(data.end_date));
    this.addEditCouponFormGroup.controls['activeStatus'].setValue(data.active_status);
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.couponEditId = '';
    this.addEditCouponFormGroup.reset({
      activeStatus: true
    });
  }

}
