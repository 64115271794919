import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';
import { AppVariables } from '../../../app.variables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VendorListingsService } from './vendor-listings.service';

@Component({
  selector: 'app-vendor-listings',
  templateUrl: './vendor-listings.component.html',
  styleUrls: ['./vendor-listings.component.scss']
})
export class VendorListingsComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public gymListingsData: any = [];
  // public dataPerPage: number = 20;
  public gymOwnerDeleteId: string = '';
  public dataPerPage: number = 0;
  public totalData: number = 0;
  public currentPageNumber: number = 1;

  @ViewChild('bs-dropdown') dropdown: ElementRef;
  
  status: { isOpen: boolean } = { isOpen: false };
  public searchFormGroup: FormGroup = this.formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'publishStatus': ['0', [Validators.required]]
  });
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  constructor(private _modalService: BsModalService, private _gymOwnerListingsService: VendorListingsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables,public formBuilder: FormBuilder) { }
  
  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");

      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
  
  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.searchFormGroup.controls['verifyStatus'].setValue('0');
    this.searchFormGroup.controls['publishStatus'].setValue('0');
    this.getListingsDataFunc();
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber - 1;
    this.getListingsDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getListingsDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getListingsDataFunc();
  }

  isActiveStatusChanged(gymOwnerId, activeStatus) {
    this._emitter.emit('showLoader');
    this._gymOwnerListingsService.updatePublishStatus(this._userId, this._accessToken, this._csrfToken, gymOwnerId, activeStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorAdminPublishStatusUpdateSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isVerifiedChanged(gymOwnerId, verificationStatus) {
    this._emitter.emit('showLoader');
    this._gymOwnerListingsService.updateVerificationStatus(this._userId, this._accessToken, this._csrfToken, gymOwnerId, verificationStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.vendorAdminVerificationStatusUpdateSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  
  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    if(!!this.searchCriteriaChanged) {
      this.currentPageNumber = 1;
    }
    this._gymOwnerListingsService.getGymListings(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.searchCriteriaChanged = false;

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this.gymListingsData = [];
      }
      else if(resp.status == 200) {
        this.gymListingsData = resp.data;
        // this.totalData = this.gymListingsData.length
        this.totalData = resp.total
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
         msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this.searchCriteriaChanged = false;
      this._emitter.emit('showAlert', {
        type: 2,
       msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteListingsDataFunc() {
    this._emitter.emit('showLoader');
    this.currentPageNumber = 1;
    this._gymOwnerListingsService.deleteGym(this._userId, this._accessToken, this._csrfToken, this.gymOwnerDeleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.searchCriteriaChanged = false;
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 204) {
        this.gymListingsData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
         msg: this.appVariables.vendorListingsDeletedSuccess
        });
        this.gymListingsData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
         msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this.searchCriteriaChanged = false;
      this._emitter.emit('showAlert', {
        type: 2,
       msg: this.appVariables.someErrorOccurred
      });
    });
  }

  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    this.getListingsDataFunc();
  }

}
