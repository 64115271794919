import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  postLogin(data) {
    let fd = new FormData();
    fd.append("user_email", data.userEmail);
    fd.append("user_password", data.userPassword);

    return this._http.post(this.apiBaseUrl + 'login', fd)
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
