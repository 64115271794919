import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrainerSubscriptionsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-membership-packages-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  create(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("package_name", formData.packageName.trim());
    fd.append("package_price", formData.packagePrice);    
    fd.append("package_description", formData.packageDescription);
    fd.append("max_upload_size_in_mb", formData.maxUploadSizeInMb);
    fd.append("max_upload_number", formData.maxUploadNumber);
    fd.append("is_publish", formData.isPublished);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-membership-packages-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  edit(userId, accessToken, csrfToken, editId, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("package_id", editId);
    fd.append("package_name", formData.packageName.trim());
    fd.append("package_price", formData.packagePrice);
    fd.append("package_description", formData.packageDescription);
    fd.append("max_upload_size_in_mb", formData.maxUploadSizeInMb);
    fd.append("max_upload_number", formData.maxUploadNumber);    
    fd.append("is_publish", formData.isPublished);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-membership-packages-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  delete(userId, accessToken, csrfToken, deleteId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("package_id", deleteId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'trainer-membership-packages-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
