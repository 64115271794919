import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductRelatedFeaturesService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-related-features-listing', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  create(userId, accessToken, csrfToken, formData, imgData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("feature_name", formData.name.trim());
    fd.append("is_publish", formData.isPublished);
    fd.append("image", JSON.stringify(imgData));
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);
    
    return this._http.post(this.apiBaseUrl + 'product-related-features-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  edit(userId, accessToken, csrfToken, editId, formData, imgData) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("featurekey", editId);
    fd.append("featurename", formData.name.trim());
    fd.append("image", JSON.stringify(imgData));
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-related-features-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  delete(userId, accessToken, csrfToken, deleteId) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("featurekey", deleteId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-related-features-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}  