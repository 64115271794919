import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributeListService } from './product-attribute-list.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';

@Component({
  selector: 'app-product-attribute-list',
  templateUrl: './product-attribute-list.component.html',
  styleUrls: ['./product-attribute-list.component.css']
})
export class ProductAttributeListComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public productAttributeListings: any = [];
  public productAttributeDeleteId: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _productAttributeService: ProductAttributeListService, private _router: Router, @Inject(EventEmitter) @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getGstList();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getGstList() {
    this._emitter.emit('showLoader');
    this._productAttributeService.productAttributeList(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.productAttributeListings = [];
      }
      else if(resp.status == 200) {
        this.productAttributeListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteProductAttributeFunc() {
    this._emitter.emit('showLoader');
    this._productAttributeService.deleteProductAttribute(this._userId, this._accessToken, this._csrfToken, this.productAttributeDeleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.productAttributeListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.attributeDeleteSuccess
        });
        this.productAttributeListings = resp.data;
        this.modalRef.hide()
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  modalClose() {
    console.log("modal");
    
    this.modalRef.hide();
  }

}
