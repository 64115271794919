import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../app.variables';
import {PackageManagementsService} from './package-managements.service';

@Component({
  selector: 'app-package-managements',
  templateUrl: './package-managements.component.html',
  styleUrls: ['./package-managements.component.scss']
})
export class PackageManagementsComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public packageFormGroup: FormGroup = this._formBuilder.group({
    'mintransaction': ['', [Validators.required, Validators.maxLength(6), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'maxtransaction': ['', [Validators.required, Validators.maxLength(6), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'fitcoin': ['', [Validators.required, Validators.maxLength(6), Validators.pattern(/^[1-9]{1}(\d?)+$/)]],
    'isPublished': [true, [Validators.required]]
  });
  public editPackageId: string = '';
  public deletePackageId: string = '';
  public packageListings: any = [];


  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables,private _packageManagementService: PackageManagementsService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getFitcoinPackageList();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  getFitcoinPackageList() {
    this._emitter.emit('showLoader');
    this._packageManagementService.getPackageListings(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.packageListings = [];
      }
      else if(resp.status == 200) {
        this.packageListings = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetForm() {
    this.editPackageId = '';
    this.packageFormGroup.controls['mintransaction'].setValue('1');
    this.packageFormGroup.controls['maxtransaction'].setValue('1');
    this.packageFormGroup.controls['fitcoin'].setValue('1');
    this.packageFormGroup.controls['isPublished'].setValue(true);
  }

  formSubmitted() {
    if(this.editPackageId != '') {
      this.editFitcoinPackageFunc();
    }
    else {
      this.createFitcoinPackageFunc();
    }
  }

  createFitcoinPackageFunc() {
    this._emitter.emit('showLoader');
    this._packageManagementService.createPackage(this._userId, this._accessToken, this._csrfToken, this.packageFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.packageListings = [];
      }
      else if(resp.status == 409) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.packageConflictsWithOtherPackages
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.packageManagementCreateSuccess
        });
        this.packageListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editFitcoinPackageFunc() {
    this._emitter.emit('showLoader');
    this._packageManagementService.editPackage(this._userId, this._accessToken, this._csrfToken, this.editPackageId, this.packageFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.packageListings = [];
      }
      else if(resp.status == 409) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.packageConflictsWithOtherPackages
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.packageManagementEditedSuccess
        });
        this.packageListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  setEditData(packageData) {
    this.editPackageId = packageData.fitcoin_package_id;
    this.packageFormGroup.controls['mintransaction'].setValue(packageData.price_min_amount);
    this.packageFormGroup.controls['maxtransaction'].setValue(packageData.price_max_amount);
    this.packageFormGroup.controls['fitcoin'].setValue(packageData.fitcoins_amount);
    this.packageFormGroup.controls['isPublished'].setValue(packageData.active_status);
    window.scrollTo(0, 0);
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  deleteFitcoinPackageConfirmClicked() {
    this._emitter.emit('showLoader');
    this._packageManagementService.deletePackage(this._userId, this._accessToken, this._csrfToken, this.deletePackageId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.packageManagementDeletedSuccess
        });
        this.packageListings = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.packageManagementDeletedSuccess
        });
        this.packageListings = resp.data;
        this.resetForm();
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this.modalRef.hide();
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
}
