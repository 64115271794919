import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ngx-msd-pagination',
  templateUrl: './ngx-msd-pagination.component.html',
  styleUrls: ['./ngx-msd-pagination.component.scss']
})
export class NgxMsdPaginationComponent implements OnInit {

  // @Input() totalNumberOfData: number;
  // @Input() dataPerPage: number;
  // @Input() currentPageNumber: number;
  private _totalNumberOfData: number
  private _dataPerPage: number
  public _currentPageNumber: number
  @Output() pageNumberChanged = new EventEmitter<any>();

  public paginationArr: any = [];
  public totalNumberOfPaginationsToShow: number = 8;
  public numOfPaginations: number = 0;

  constructor() {
  }

  @Input() set totalNumberOfData(val) {
    this._totalNumberOfData = val;
    this.paginationCalculations();
  }

  @Input() set dataPerPage(val) {
    this._dataPerPage = val;
    this.paginationCalculations();
  }

  @Input() set currentPageNumber(val) {
    this._currentPageNumber = val;
    this.paginationCalculations();
  }

  ngOnInit() {
  }

  paginationCalculations() {
    this.numOfPaginations = this._totalNumberOfData % this._dataPerPage == 0 ? this._totalNumberOfData / this._dataPerPage : parseInt((this._totalNumberOfData / this._dataPerPage).toString()) + 1;

    this.paginationArr = [];

    if(this.numOfPaginations <= this.totalNumberOfPaginationsToShow) {
      for(let i = 1; i<= this.numOfPaginations; i++) {
        this.paginationArr.push(i);
      }
    }
    else if(this._currentPageNumber <= (parseInt((this.totalNumberOfPaginationsToShow / 2).toString()))) {
      for(let i = 1; i<= this.totalNumberOfPaginationsToShow - 2; i++) {
        this.paginationArr.push(i);
      }
      this.paginationArr.push('...');
      this.paginationArr.push(this.numOfPaginations);
    }
    else if(this._currentPageNumber >= (this.numOfPaginations - parseInt((this.totalNumberOfPaginationsToShow / 2).toString()))) {
      this.paginationArr.push(1);
      this.paginationArr.push('...');
      for(let i = (this.numOfPaginations - parseInt((this.totalNumberOfPaginationsToShow / 2).toString()) - 1); i<= this.numOfPaginations; i++) {
        this.paginationArr.push(i);
      }
    }
    else {
      this.paginationArr.push(1);
      this.paginationArr.push('...');
      for(let i=this._currentPageNumber - (parseInt((this.totalNumberOfPaginationsToShow / 4).toString())); i<=this._currentPageNumber + (parseInt((this.totalNumberOfPaginationsToShow / 4).toString())); i++) {
        this.paginationArr.push(i);
      }
      this.paginationArr.push('...');
      this.paginationArr.push(this.numOfPaginations);
    }
  }

  paginationClicked(evnt, pageNum) {
    evnt.preventDefault();
    if(isNaN(pageNum)) {
      return false;
    }

    this._currentPageNumber = pageNum;
    // this.paginationCalculations();
    this.pageNumberChanged.emit(pageNum);
  }

  prevClicked() {
    if(this._currentPageNumber == 1) {
      return false;
    }

    this._currentPageNumber = this._currentPageNumber - 1;
    // this.paginationCalculations();
    this.pageNumberChanged.emit(this._currentPageNumber);
  }

  nextClicked() {
    if(this._currentPageNumber == this.numOfPaginations) {
      return false;
    }

    this._currentPageNumber = this._currentPageNumber + 1;
    // this.paginationCalculations();
    this.pageNumberChanged.emit(this._currentPageNumber);
  }

}
