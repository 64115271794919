import { Component, Inject, OnInit, Input, Output, TemplateRef } from '@angular/core';
import { EventEmitter } from 'events';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppVariables } from '../../../app.variables';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { CreateCompetitionService } from './create-competition.service'

@Component({
  selector: 'app-create-competition',
  templateUrl: './create-competition.component.html',
  styleUrls: ['./create-competition.component.scss']
})
export class CreateCompetitionComponent implements OnInit {
  baseUrl = environment.baseUrl;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public pageData: any = [];
  public competitionCategoryListing: any = [];
  public imageChangedEvent: string = '';
  public categoryDeleteId: string = '';
  public config: any = {
    height: 150
  };
  
  public competitionEditId: string = '';

  public addEditCompetitionFormGroup: FormGroup = this._formBuilder.group({
    'title': ['', [Validators.required]],
    'category': ['', [Validators.required]],
    'description': ['', [Validators.required]],
    'toDoList': ['', [Validators.required]],
    'selectionCriteria': ['', [Validators.required]],    
    'isPublish': [true, []],
    'competitionImage': [{
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    }, []]
  });

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, private _activatedRoute: ActivatedRoute, @Inject(EventEmitter) private _emitter: EventEmitter, private _createCompetitionService: CreateCompetitionService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      // this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    this._activatedRoute.params.subscribe(param => {
      if(typeof param.competition_id !== 'undefined' && param.competition_id != null) {
        this.competitionEditId = param.competition_id;
        this.getEditPageData();
      }
      else{        
      this.getCreatePageDataFunc();
      }
    });
  }

  fileUpload(evnt) {
    let _this = evnt.target;
    if(_this.files && _this.files[0]) {
      let fr = new FileReader();

      fr.onload = (evt) => {
        this.imageChangedEvent = evt.target['result'].toString();
      }
      fr.readAsDataURL(_this.files[0]);
    }  
  }

  competitionCroppedOutput(evnt) {
    this.addEditCompetitionFormGroup.controls['competitionImage'].setValue({
      cropped_image_url: evnt.croppedImage,
      source_image_url: evnt.sourceImage,
      crop_data: {
        x: evnt.croppedData.x,
        y: evnt.croppedData.y,
        w: evnt.croppedData.w,
        h: evnt.croppedData.h
      }
    });
  }

  editCompetitionFunction() {
    this._emitter.emit('showLoader');
    
    this._createCompetitionService.editCompetition(this._userId, this._accessToken, this._csrfToken, this.competitionEditId, this.addEditCompetitionFormGroup.value)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.competitionEditedSuccess
        });
        this._router.navigateByUrl('/competition-management/competition-listing');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._createCompetitionService.editPageDetails(this._userId, this._accessToken, this._csrfToken, this.competitionEditId)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/competition-management/competition-create');
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/competition-management/competition-create');
      }
      else if(resp.status == 200 && resp.data != '') {
        let data = resp.data;
        this.competitionCategoryListing = resp.competition_category_listing;
        this.addEditCompetitionFormGroup.controls['title'].setValue(data.competition_title);
        this.addEditCompetitionFormGroup.controls['category'].setValue(data.competition_category_id);
        this.addEditCompetitionFormGroup.controls['description'].setValue(data.competition_description);
        this.addEditCompetitionFormGroup.controls['toDoList'].setValue(data.competition_to_do_list);
        this.addEditCompetitionFormGroup.controls['selectionCriteria'].setValue(data.competition_selection_criteria);
        this.addEditCompetitionFormGroup.controls['isPublish'].setValue(data.active_status);
        setTimeout(() => {
          this.getDataUri({
            cropped_image_name: data.cropped_image_name,
            source_image_name: data.source_image_name,
            crop_data: data.cropped_data
          })
        }, 100);        
      }
      else if(resp.status == 200 && resp.data == null) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });
        this._router.navigateByUrl('/competition-management/competition-create');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
        this._router.navigateByUrl('/competition-management/competition-create');
      }
    }, (err) => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  formSubmitted() {    
    if(this.competitionEditId == '') {
      this.createCompetition();
    }
    else {
      this.editCompetitionFunction();
    }
  }

  createCompetition() {
        
    this._emitter.emit('showLoader');
    this._createCompetitionService.create(this._userId, this._accessToken, this._csrfToken, this.addEditCompetitionFormGroup.value)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.competitionCreateSuccess
        });
        this.pageData = resp.data;
        // this.resetForm();
        this._router.navigateByUrl('/competition-management/competition-listing');
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });    
  }

  getCreatePageDataFunc() {
    this._emitter.emit('showLoader');
    this._createCompetitionService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        
        this.competitionCategoryListing = resp.data;
        this.addEditCompetitionFormGroup.controls['category'].setValue(resp.data[0].competition_category_id);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetForm() {
//     title
// category
// description
// toDoList
// selectionCriteria
    this.competitionEditId = '';
    this.addEditCompetitionFormGroup.controls['title'].setValue('');    
    // this.addEditCompetitionFormGroup.controls['category'].setValue(this.pageData[0].competition_category_id);
    this.addEditCompetitionFormGroup.reset();
    this.addEditCompetitionFormGroup.controls['isPublished'].setValue(true);
    this.addEditCompetitionFormGroup.controls['competitionImage'].setValue({
      cropped_image_url: '',
      source_image_url: '',
      crop_data: {
        x: '',
        y: '',
        w: '',
        h: ''
      }
    });
    this.imageChangedEvent = '';
    document.getElementsByClassName('input-file')[0]['value'] = '';
  }

  getDataUri(imgObj) {
    let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image(),
        data = {
          cropped_image_url: '',
          source_image_url: '',
          crop_data: {
            x: '',
            y: '',
            w: '',
            h: ''
          }
        },
        imgType = 'source';
    
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      if(imgType == 'source') {
        imgType = 'crop';
        data.source_image_url = canvas.toDataURL();
        img.src = this.baseUrl + 'images/uploads/competition/' + imgObj.cropped_image_name;
        this.imageChangedEvent = data.source_image_url;
      }
      else if(imgType == 'crop') {
        imgType = '';
        data.cropped_image_url = canvas.toDataURL();
        this.addEditCompetitionFormGroup.controls['competitionImage'].setValue({
          cropped_image_url: data.cropped_image_url,
          source_image_url: data.source_image_url,
          crop_data: {
            x: imgObj.crop_data.x,
            y: imgObj.crop_data.y,
            w: imgObj.crop_data.w,
            h: imgObj.crop_data.h
          }
        });
      }
    };
    img.src = this.baseUrl + 'images/uploads/competition/' + imgObj.source_image_name;
  }
  
}
