import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { EventEmitter } from 'events';
import {EnquiryListingsService} from './enquiry-listings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../../app.variables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-enquiry-listings',
  templateUrl: './enquiry-listings.component.html',
  styleUrls: ['./enquiry-listings.component.scss']
})

export class EnquiryListingsComponent implements OnInit {

  @ViewChild('composeMail') composeMail: any;

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  // public dataPerPage: number = 20;
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public gymOwnerId: string = '';
  public gymOwnerIdEditStatus: number = 0; // 1=> edit not possible, 0=> edit possible
  public dataPerPage: number = 0;
  public totalData: number = 0;
  public currentPageNumber: number = 1;

  public config: any = {
    height: 300,
    toolbar: [
      // [groupName, [list of button]]
      // ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol']],
      // ['insert', ['table', 'link', 'hr']]
    ]
  };
  public composeMailForm: FormGroup = this._formBuilder.group({
    'vendorId': ['', [Validators.required]],
    'mailSubject': ['', [Validators.required]],
    'mailBody': ['', [Validators.required]]
  });

  public mailListings: any = [];

  constructor(private _modalService: BsModalService,  private _formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _enquiryListingsService: EnquiryListingsService, public appVariables: AppVariables) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      try{  
        this._activatedRoute.params.subscribe((param) => {
        this.gymOwnerId = param.vendorId;
        if(this.gymOwnerId != undefined){
          this.gymOwnerIdEditStatus = 1;
          this.composeMailForm.controls['vendorId'].setValue(this.gymOwnerId);
          setTimeout(() => {
            this.openModal(this.composeMail);
          }, 200);
        }
        });        
        this.getMailListings();
      }
      catch{        
        this.getMailListings();
      }

      this._modalService.onHidden.subscribe(() => {
        this.composeMailForm.controls['vendorId'].setValue('');
        this.composeMailForm.controls['mailSubject'].setValue('');
        this.composeMailForm.controls['mailBody'].setValue('');
      });
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }
  
  openModal(composeMail: TemplateRef<any>) {
    this.modalRef = this._modalService.show(composeMail, {
      ignoreBackdropClick: true
    });
  }

  composeMailSubmit() {
    if(!this.composeMailForm.valid) {
      return false;
    }
    this._emitter.emit('showLoader');
    this._enquiryListingsService.composeNewMailSend(this._userId, this._accessToken, this._csrfToken, this.composeMailForm.value)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');
      this.modalRef.hide();
      this.composeMailForm.reset();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.feedbackEnquiryToVendorComposeMailSentSuccess
        });
        // console.log(resp)
        this.mailListings = resp.data;
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this.modalRef.hide();
      this.composeMailForm.reset();
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  
  getMailListings() {
    this._emitter.emit('showLoader');
    this._enquiryListingsService.getMailListings(this._userId, this._accessToken, this._csrfToken, this.currentPageNumber)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.mailListings = [];
      }
      else if(resp.status == 200) {
        this.mailListings = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.totalData = this.mailListings.length

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber - 1;
    this.getMailListings();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getMailListings();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getMailListings();
  }

  
  paginationNumberChanged(pageNum) {
    this.currentPageNumber = pageNum;
    this.getMailListings();
  }
  
}
