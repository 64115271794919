import { Component, Inject, OnInit, Input, Output, EventEmitter as EventEmitterCore, ViewChild, ElementRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FeaturesService }   from './features.service'
import { AppVariables } from '../../../../../app.variables';
import { EventEmitter } from 'events';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public pageData: any = [];
  private finalFeatureList: any = [];
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public vendorId: string = '';

  @Input() productId: string;
  @Output() responseOutputObj = new EventEmitterCore<any>();

  constructor(private _featuresService: FeaturesService, @Inject(EventEmitter) private _emitter: EventEmitter, public appVariables: AppVariables,private _activatedRoute: ActivatedRoute, private _router: Router) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this._activatedRoute.params.subscribe((param) => {
        this.vendorId = param.vendorId;
        if(typeof param.productId !== 'undefined' && param.productId != '' && param.productId != null) {
          this.productId = param.productId;
          this.createOrEdit = 1;
          this.getEditPageData();
        }
        else {
          this.createOrEdit = 0;
          this.getCreatePageData();
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    
  }

  getCreatePageData(){
    this._emitter.emit('showLoader');
    this._featuresService.productFeaturesgetCreateData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.pageData = resp.feature_list;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getEditPageData(){
    this._emitter.emit('showLoader');
    this._featuresService.productFeaturesGetEditData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
    .subscribe((resp) => {
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.pageData = resp.feature_list;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  productFeaturesSubmit() {
    this.finalFeatureList = [];

    for(let data of this.pageData) {
      if(!!data.checked_status) {
        this.finalFeatureList.push(data.product_feature_id);
      }
    }

    if(this.createOrEdit == 0) {
      this.relatedFeaturesCreateFunc();
    }
    else {
      this.relatedFeaturesEditFunc();
    }
  }

  relatedFeaturesCreateFunc() {
    this._emitter.emit('showLoader');
    this._featuresService.productFeaturesCreate(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.finalFeatureList)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productFeaturesCreateSuccess
        });
        this.responseOutputObj.emit(resp);
        this._router.navigateByUrl(`/vendor-product-management/product-listings/${this.vendorId}`);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  relatedFeaturesEditFunc() {
    this._emitter.emit('showLoader');
    this._featuresService.productFeaturesEdit(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, this.finalFeatureList)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productFeaturesEditSuccess
        });
        resp.product_id = this.productId;
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
