import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GstService {

  apiBaseUrl = environment.apiBaseUrl;
  
  constructor(private _http: HttpClient) { }

  getGstList(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gst-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createGst(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("gst_total_value", formData.gstTotalValue);
    fd.append("cgst_value", formData.cgstValue);
    fd.append("sgst_value", formData.sgstValue);
    fd.append("igst_value", formData.igstValue); 
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gst-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editGst(userId, accessToken, csrfToken, gstId, formData) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("gst_id", gstId);
    fd.append("gst_total_value", formData.gstTotalValue);
    fd.append("cgst_value", formData.cgstValue);
    fd.append("sgst_value", formData.sgstValue);
    fd.append("igst_value", formData.igstValue); 
    fd.append("is_publish", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gst-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteGst(userId, accessToken, csrfToken, gstId) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("gst_id", gstId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'gst-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
