import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubCategoriesService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-sub-category-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  create(userId, accessToken, csrfToken, formData, imgData) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("categorykey", formData.categoryId);
    fd.append("subcategoryname", formData.subCategoryName.trim());
    fd.append("category_icon", JSON.stringify(imgData))
    fd.append("is_publish", formData.isPublished);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-sub-category-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  edit(userId, accessToken, csrfToken, editId, formData, imgData) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("subcategorykey", editId);
    fd.append("categorykey", formData.categoryId);
    fd.append("subcategoryname", formData.subCategoryName.trim());
    fd.append("category_icon", JSON.stringify(imgData))
    fd.append("is_publish", formData.isPublished);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);
    console.log(formData)
    return this._http.post(this.apiBaseUrl + 'product-sub-category-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  delete(userId, accessToken, csrfToken, deleteId) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("subcategorykey", deleteId);
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);
    return this._http.post(this.apiBaseUrl + 'product-sub-category-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

}
