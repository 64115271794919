import { Component, Inject, OnInit, Input, Output, EventEmitter as EventEmitterCore, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';
import { AppVariables } from '../../../../../app.variables';
import { VarientService } from './varient.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;

@Component({
  selector: 'app-varient',
  templateUrl: './varient.component.html',
  styleUrls: ['./varient.component.scss']
})
export class VarientComponent implements OnInit {
  public baseUrl = environment.baseUrl;
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public createOrEdit: number = 0; // 0 => Create, 1 => Edit
  public vendorId: string = '';
  public attributeData: any = [];
  public subAttributeData: any = [];
  public productImage: any = [];
  public fileList: any = [];

  @Input() productId: string = '';
  @Output() responseOutputObj = new EventEmitterCore<any>();
  public unitTypeName: string = '';
  public orginalBasePrice: any = 0;
  public listingBasePrice: any = 0;
  public productVarObj: any = {
			"attribute_arr": [],
			"selected_attribute_id": [],
      "selected_sub_attribute_id": [],
      "original_price_increase": 0,
      "listing_price_increase": 0,
      "is_published":true,
      "final_original_price": 0,
      "final_listing_price": 0,
      "existing_quantity": 0,
      "new_quantity": 0,
      "image_arr": [],
      "selected_image_id": []
  };
  public productVarArr: any = [
  ];

  constructor(private _modalService: BsModalService, @Inject(EventEmitter) private _emitter: EventEmitter, private _formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute, public appVariables: AppVariables, private _router: Router, private _varientService: VarientService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      // If '_activatedRoute' have edit id so it is editted
      this._activatedRoute.params.subscribe((params) => {
        this.vendorId = params.vendorId;
        if(typeof params.productId !== 'undefined' && params.productId != '' && params.productId != null) {
          this.getEditProductVarientgetData();
          this.productId = params.productId;
          this.createOrEdit = 1;
        }
        else {
          this.createOrEdit = 0;
          this.getCreateProductVarientData();
        }
      })
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }
  
  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getCreateProductVarientData() {
    this._emitter.emit('showLoader');
    this._varientService.createProductVarientData(this._userId, this._accessToken, this._csrfToken, this.productId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.productImage = resp.product_images.map(x => Object.assign({}, x));
        this.attributeData = resp.data.attributes_data.map(x => Object.assign({}, x));
        this.orginalBasePrice = resp.data.base_price_original;
        this.listingBasePrice = resp.data.base_price_listing
        this.productVarArr = [];
        
        let attributeData = this.attributeData.map(attr => {
          let subAttr = attr.sub_attribute_data.map(subAttr => Object.assign({}, subAttr));
          
          return {
            attribute_id: attr.attribute_id,
            attribute_name: attr.attribute_name,
            checked_status: false,
            sub_attribute_data: subAttr
          };
        });
        let productImage = this.productImage.map(img => {
          return {
            image_id: img.image_id,
            image_name: img.image_name,
            checked_status: false
          };
        });
        let productVarObj = Object.assign({}, this.productVarObj);
        productVarObj = {
          "attribute_arr": attributeData,
          "selected_attribute_id": [],
          "selected_sub_attribute_id": [],
          "original_price_increase": 0,
          "listing_price_increase": 0,
          "product_weight": 0,
          "is_published":true,
          "final_original_price": this.orginalBasePrice,
          "final_listing_price": this.listingBasePrice,
          "existing_quantity": 0,
          "new_quantity": 0,
          "image_arr": productImage,
          "selected_image_id": []
        };
        this.productVarArr.push(Object.assign({}, productVarObj));
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  // getEditProductVarientgetData() {
  //   this._emitter.emit('showLoader');
  //   this._varientService.editProductVarientgetData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
  //   .subscribe((resp) => {
  //     // console.log(resp);
  //     this._emitter.emit('hideLoader');

  //     if(resp.status == 401) {
  //       this._router.navigateByUrl('/login');
  //     }
  //     else if(resp.status == 200) {
  //       this.productImage = resp.product_images.map(x => Object.assign({}, x));
  //       this.attributeData = resp.data.attributes_data.map(x => Object.assign({}, x));
  //       this.orginalBasePrice = resp.data.base_price_original;
  //       this.listingBasePrice = resp.data.base_price_listing
  //       this.productVarArr = [];
  //       // let imageData = this.productImage.map(img => {
  //       //   return {
  //       //     image_id: img.image_id,
  //       //     image_name: img.image_name,
  //       //     checked_status: false
  //       //   };
  //       // });
  //       if(resp.data.product_attributes.length > 0) {
  //         for(let data of resp.data.product_attributes) {
  //           let attributeData = this.attributeData.map(attr => {
  //             let subAttr = attr.sub_attribute_data.map(subAttr => {
  //               return Object.assign({}, {
  //                 sub_attribute_id: subAttr.sub_attribute_id,
  //                 sub_attribute_value: subAttr.sub_attribute_value,
  //                 checked_status: data.sub_attribute_id.indexOf(subAttr.sub_attribute_id) >= 0
  //               })
  //             });
              
  //             return {
  //               attribute_id: attr.attribute_id,
  //               attribute_name: attr.attribute_name,
  //               checked_status: data.attribute_id.indexOf(attr.attribute_id) >= 0,
  //               sub_attribute_data: subAttr
  //             };
              
  //           });

  //           let image_list = [];
  //           if(data.image_list == null){
  //             image_list = [];
  //           }
  //           else{
  //             image_list = data.image_list
  //           }
  //           let productImage = this.productImage.map(img => {
  //             return Object.assign({}, {
  //               image_id: img.image_id,
  //               image_name: img.image_name,
  //               checked_status: image_list.indexOf(img.image_id) >= 0
  //             })
  //           });
  //           // for(let imgData of resp.product_images){
  //           //   let imageList = data.image_list.map(img => {
  //           //   return {
  //           //     image_id: img.image_id,
  //           //     image_name: img.image_name,
  //           //     checked_status: imgData.image_id.indexOf(imageList.image_id) >= 0
  //           //   };
  //           //   });


  //           let productVarObj = Object.assign({}, this.productVarObj);
  //           productVarObj = {
  //             "attribute_arr": attributeData,
  //             "selected_attribute_id": data.attribute_id,
  //             "selected_sub_attribute_id": data.attribute_id.map((elem, indx) => {
  //               return {
  //                 "attribute_id": elem,
  //                 "sub_attribute_id": data.sub_attribute_id[indx]
  //               };
  //             }),
  //             "original_price_increase": data.original_price_increase,
  //             "listing_price_increase": data.listing_price_increase,
  //             "is_published": data.is_published,
  //             "final_original_price": this.orginalBasePrice + data.original_price_increase,
  //             "final_listing_price": this.listingBasePrice + data.listing_price_increase,
  //             "existing_quantity": data.quantity,
  //             "new_quantity": 0,
  //             "image_arr": productImage,
  //             "selected_image_id": image_list
  //           };
          
  //           this.productVarArr.push(Object.assign({}, productVarObj));
  //         // }
  //         }
  //       }

  //       else {
  //         let attributeData = this.attributeData.map(attr => {
  //           let subAttr = attr.sub_attribute_data.map(subAttr => Object.assign({}, subAttr));
            
  //           return {
  //             attribute_id: attr.attribute_id,
  //             attribute_name: attr.attribute_name,
  //             checked_status: false,
  //             sub_attribute_data: subAttr
  //           };
  //         });
  //         let productImage = this.productImage.map(img => {
  //           return {
  //             image_id: img.image_id,
  //             image_name: img.image_name,
  //             checked_status: false
  //           };
  //         });
  //         let productVarObj = Object.assign({}, this.productVarObj);
  //         productVarObj = {
  //           "attribute_arr": attributeData,
  //           "selected_attribute_id": [],
  //           "selected_sub_attribute_id": [],
  //           "original_price_increase": 0,
  //           "listing_price_increase": 0,
  //           "is_published":true,
  //           "final_original_price": this.orginalBasePrice,
  //           "final_listing_price": this.listingBasePrice,
  //           "existing_quantity": 0,
  //           "new_quantity": 0,
  //           "image_arr": productImage,
  //           "selected_image_id": []
  //         };
  //         this.productVarArr.push(Object.assign({}, productVarObj));
  //       }
  //     }
  //     else {
  //       this._emitter.emit('showAlert', {
  //         type: 2,
  //         msg: this.appVariables.someErrorOccurred
  //       });
  //     }
  //   }, err => {
  //     if(err.status == 401) {
  //       this._router.navigateByUrl('/login');
  //     }
  //     this._emitter.emit('hideLoader');
  //     this._emitter.emit('showAlert', {
  //       type: 2,
  //       msg: this.appVariables.someErrorOccurred
  //     });
  //   });
  // }

  getEditProductVarientgetData() {
    this._emitter.emit('showLoader');
    this._varientService.editProductVarientgetData(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId)
    .subscribe((resp) => {
      console.log("variatn",resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.productImage = resp.product_images.map(x => Object.assign({}, x));
        this.attributeData = resp.data.attributes_data.map(x => Object.assign({}, x));
        this.orginalBasePrice = resp.data.base_price_original;
        this.listingBasePrice = resp.data.base_price_listing;
        this.productVarArr = [];
        // let imageData = this.productImage.map(img => {
        //   return {
        //     image_id: img.image_id,
        //     image_name: img.image_name,
        //     checked_status: false
        //   };
        // });
        if(resp.data.product_attributes.length > 0) {
          for(let data of resp.data.product_attributes) {
            let attributeData = this.attributeData.map(attr => {
              let subAttr = attr.sub_attribute_data.map(subAttr => {
                return Object.assign({}, {
                  sub_attribute_id: subAttr.sub_attribute_id,
                  sub_attribute_value: subAttr.sub_attribute_value,
                  checked_status: data.sub_attribute_id.indexOf(subAttr.sub_attribute_id) >= 0
                })
              });
              
              return {
                attribute_id: attr.attribute_id,
                attribute_name: attr.attribute_name,
                checked_status: data.attribute_id.indexOf(attr.attribute_id) >= 0,
                sub_attribute_data: subAttr
              };
            });
            let image_list = [];
            if(data.image_list == null){
              image_list = [];
            }
            else{
              image_list = data.image_list
            }
            let productImage = this.productImage.map(img => {
              return Object.assign({}, {
                image_id: img.image_id,
                image_name: img.image_name,
                checked_status: image_list.indexOf(img.image_id) >= 0
              })
            });
            let productVarObj = Object.assign({}, this.productVarObj);
            productVarObj = {
              "attribute_arr": attributeData,
              "selected_attribute_id": data.attribute_id,
              "selected_sub_attribute_id": data.attribute_id.map((elem, indx) => {
                return {
                  "attribute_id": elem,
                  "sub_attribute_id": data.sub_attribute_id[indx]
                };
              }),
              "product_weight": data.product_weight,
              "original_price_increase": data.original_price_increase,
              "listing_price_increase": data.listing_price_increase,
              "is_published": data.is_published,
              "final_original_price": this.orginalBasePrice + data.original_price_increase,
              "final_listing_price": this.listingBasePrice + data.listing_price_increase,
              "existing_quantity": data.quantity,
              "new_quantity": 0,
              "image_arr": productImage,
              "selected_image_id": image_list
            };
          
            this.productVarArr.push(Object.assign({}, productVarObj));
          }
        }
        else {
          let attributeData = this.attributeData.map(attr => {
            let subAttr = attr.sub_attribute_data.map(subAttr => Object.assign({}, subAttr));
            
            return {
              attribute_id: attr.attribute_id,
              attribute_name: attr.attribute_name,
              checked_status: false,
              sub_attribute_data: subAttr
            };
          });
          let productImage = this.productImage.map(img => {
            return {
              image_id: img.image_id,
              image_name: img.image_name,
              checked_status: false
            };
          });
          let productVarObj = Object.assign({}, this.productVarObj);
          productVarObj = {
            "attribute_arr": attributeData,
            "selected_attribute_id": [],
            "selected_sub_attribute_id": [],
            "product_weight": 0,
            "original_price_increase": 0,
            "listing_price_increase": 0,
            "is_published":true,
            "final_original_price": this.orginalBasePrice,
            "final_listing_price": this.listingBasePrice,
            "existing_quantity": 0,
            "new_quantity": 0,
            "image_arr": productImage,
            "selected_image_id": []
          };
          this.productVarArr.push(Object.assign({}, productVarObj));
        }
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  attributeChanged(evnt, variantObj, attrObj) {
    if(evnt.target.checked) {
      attrObj.checked_status = true;
      variantObj.selected_attribute_id.push(attrObj.attribute_id);
      for(let obj of attrObj.sub_attribute_data) {
        obj.checked_status = false;
      }
    }
    else {
      attrObj.checked_status = false;
      variantObj.selected_attribute_id.splice(variantObj.selected_attribute_id.indexOf(attrObj.attribute_id), 1);

      for(let obj of attrObj.sub_attribute_data) {
        obj.checked_status = false;

        for(let subIndx in variantObj.selected_sub_attribute_id) {
          if(variantObj.selected_sub_attribute_id[subIndx].sub_attribute_id == obj.sub_attribute_id) {
            variantObj.selected_sub_attribute_id.splice(subIndx, 1);
            break;
          }
        }
      }
    }
  }

  subAttributeClicked(variantObj, attrObj, subAttrObj) {
    setTimeout(() => {
      for(let obj of attrObj.sub_attribute_data) {
        obj.checked_status = false;

        for(let subIndx in variantObj.selected_sub_attribute_id) {
          if(variantObj.selected_sub_attribute_id[subIndx].sub_attribute_id == obj.sub_attribute_id) {
            variantObj.selected_sub_attribute_id.splice(subIndx, 1);
            break;
          }
        }
      }

      subAttrObj.checked_status = true;
      variantObj.selected_sub_attribute_id.push({
        "attribute_id": attrObj.attribute_id,
        "sub_attribute_id": subAttrObj.sub_attribute_id
      });
    }, 10);
  }

  imageClicked(evnt, variantObj, imageObj) {
    setTimeout(() => {
      if(evnt.target.checked) {
        imageObj.checked_status = true;
        variantObj.selected_image_id.push(imageObj.image_id);
        for(let obj of imageObj) {
          obj.checked_status = false;
        }
      }
      else {
        imageObj.checked_status = false;
        variantObj.selected_image_id.splice(variantObj.selected_image_id.indexOf(imageObj.image_id), 1);
        // imageObj.checked_status = true;
        // variantObj.selected_image_id.push({
        //   "image_id": imageObj.image_id,
        //   "image_name": imageObj.image_name
        // });

        // for(let obj of imageObj.sub_attribute_data) {
        //   obj.checked_status = false;
  
        //   for(let subIndx in variantObj.selected_sub_attribute_id) {
        //     if(variantObj.selected_sub_attribute_id[subIndx].sub_attribute_id == obj.sub_attribute_id) {
        //       variantObj.selected_sub_attribute_id.splice(subIndx, 1);
        //       break;
        //     }
        //   }
        // }
      }
    //   if(evnt.target.checked) {
    //     variantObj.checked_status = true;
    //     variantObj.selected_image_id.push(variantObj.image_id);
    //     for(let obj of variantObj.image_arr) {
    //       obj.checked_status = false;
    //     }
    //   }
    //   else {
    //   for(let obj of variantObj.image_arr) {
    //     obj.checked_status = false;
    //     for(let subIndx in variantObj.selected_image_id) {
    //       if(variantObj.selected_image_id[subIndx].image_id == obj.image_id) {
    //         variantObj.selected_image_id.splice(subIndx, 1);
    //         break;
    //       }
    //     }
    //   }
      
    // }
    // imageObj.checked_status = true;
    //   variantObj.selected_image_id.push({
    //     "image_id": imageObj.image_id,
    //     "image_name": imageObj.image_name
    //   });
    }, 10);
  }

  orginalPriceChanged(variantObj) {
    setTimeout(() => {
      if(variantObj.original_price_increase == '' || isNaN(variantObj.original_price_increase) || !/^[0-9]+$/.test(variantObj.original_price_increase) || variantObj.original_price_increase < 0) {
        variantObj.original_price_increase = 0;
      }
      variantObj.final_original_price = parseInt(variantObj.original_price_increase) + this.orginalBasePrice;
    }, 10);
  }

  listingPriceChanged(variantObj) {
    setTimeout(() => {
      if(variantObj.listing_price_increase == '' || isNaN(variantObj.listing_price_increase) || !/^[0-9]+$/.test(variantObj.listing_price_increase) || variantObj.listing_price_increase < 0) {
        variantObj.listing_price_increase = 0;
      }
      variantObj.final_listing_price = parseInt(variantObj.listing_price_increase) + this.listingBasePrice;
    }, 10)
  }

  weightChanged(variantObj) {
    if(variantObj.product_weight == '' || isNaN(variantObj.product_weight) || !/^[0-9]+$/.test(variantObj.product_weight) || parseInt(variantObj.product_weight) < 0) {
      setTimeout(() => {
        variantObj.product_weight = 0;
      }, 10);
    }
  }

  newQuantityChanged(variantObj) {
    if(variantObj.new_quantity == '' || isNaN(variantObj.new_quantity) || !/^[0-9]+$/.test(variantObj.new_quantity) || parseInt(variantObj.new_quantity) < 0) {
      setTimeout(() => {
        variantObj.new_quantity = 0;
      }, 10);
    }
  }

  productVarientFormSubmitted() {
    if(this.attributeData.length <= 0) {
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.productVarientAtLeastOneAttr
      });
      return false;
    }

    if(this.createOrEdit == 0) {
      this.createProductVarientFunc();
    }
    else {
      this.editProductVarientFunc();
    }
  }

  createProductVarientFunc() {
    // this.productVarArr.forEach(element => {
    //   delete element.sub_attribute_list;
    //   delete element.original_price;
    //   delete element.listing_price;
    // });
    let productVarArr = this.productVarArr.map(variant => {
      let attrArr = variant.selected_sub_attribute_id.map(obj => obj.attribute_id);
      attrArr = `{${attrArr.join(',')}}`;

      let subAttrArr = variant.selected_sub_attribute_id.map(obj => obj.sub_attribute_id);
      subAttrArr = `{${subAttrArr.join(',')}}`;

      let imgArr = variant.selected_image_id.map(obj => obj);
      imgArr = `{${imgArr.join(',')}}`;

      return {
        attribute_id: attrArr,
        sub_attribute_id: subAttrArr,
        listing_price_increase: variant.listing_price_increase,
        original_price_increase: variant.original_price_increase,
        weight: variant.product_weight,
        new_quantity: variant.new_quantity,
        is_published: variant.is_published,
        image_id: imgArr
      };
    });
    console.log(productVarArr);
    // return false;

    this._emitter.emit('showLoader');
    this._varientService.createProductVarient(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, productVarArr)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productVarientCreateSuccess
        });
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.productVarientAtLeastOneAttr
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  editProductVarientFunc() {
    let productVarArr = this.productVarArr.map(variant => {
      let attrArr = variant.selected_sub_attribute_id.map(obj => obj.attribute_id);
      attrArr = `{${attrArr.join(',')}}`;

      let subAttrArr = variant.selected_sub_attribute_id.map(obj => obj.sub_attribute_id);
      subAttrArr = `{${subAttrArr.join(',')}}`;

      console.log("variant-->",variant);
      // let imgArr = this.productImage.map(obj => obj.image_id);
      // imgArr = `{${imgArr.join(',')}}`;
      let imgArr = variant.selected_image_id.map(obj => obj);
      imgArr = `{${imgArr.join(',')}}`;
      let existingQuantity = isNaN(variant.existing_quantity) || variant.existing_quantity == null ? 0 : variant.existing_quantity,
      newQuantity = isNaN(variant.new_quantity) || variant.new_quantity == null ? 0 : variant.new_quantity;
      console.log(existingQuantity,newQuantity)
      return {
        attribute_id: attrArr,
        sub_attribute_id: subAttrArr,
        products_attributes_id: variant.products_attributes_id,
        listing_price_increase: variant.listing_price_increase,
        original_price_increase: variant.original_price_increase,
        weight: variant.product_weight,
        new_quantity: parseInt(newQuantity) + parseInt(existingQuantity),
        is_published: variant.is_published,
        image_id: imgArr
      };
    });
    console.log(productVarArr);
  
    // if(productVarArr.attribute_id =="{}" || productVarArr.sub_attribute_id =="{}"){
    //   console.log("At least 1 attribute and option need");
    // }
    // return false;

    this._emitter.emit('showLoader');
    this._varientService.editProductVarient(this._userId, this._accessToken, this._csrfToken, this.vendorId, this.productId, productVarArr)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.productVarientEditSuccess
        });
        resp.product_id = this.productId;
        this.responseOutputObj.emit(resp);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.productVarientAtLeastOneAttr
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }



  // click to input field auto added
  // addProductVarientClicked() {
  //   let attributeData = this.attributeData.map(attr => {
  //     let subAttr = attr.sub_attribute_data.map(subAttr => Object.assign({}, subAttr));
      
  //     return {
  //       attribute_id: attr.attribute_id,
  //       attribute_name: attr.attribute_name,
  //       checked_status: false,
  //       sub_attribute_data: subAttr
  //     };
  //   });
  //   let productImage = this.productImage.map(img => {
  //     return {
  //       image_id: img.image_id,
  //       image_name: img.image_name,
  //       checked_status: false
  //     };
  //   });
  //   let productVarObj = Object.assign({}, this.productVarObj);
  //   productVarObj = {
  //       "attribute_arr": attributeData,
  //       "selected_attribute_id": [],
  //       "selected_sub_attribute_id": [],
  //       "original_price_increase": 0,
  //       "listing_price_increase": 0,
  //       "is_published":true,
  //       "final_original_price": this.orginalBasePrice,
  //       "final_listing_price": this.listingBasePrice,
  //       "existing_quantity": 0,
  //       "new_quantity": 0,
  //       "selected_image_id": []
  //   };
  //   this.productVarArr.push(Object.assign({}, productVarObj));
  // }

  // click to input field auto added
  addProductVarientClicked() {
    let attributeData = this.attributeData.map(attr => {
      let subAttr = attr.sub_attribute_data.map(subAttr => Object.assign({}, subAttr));
      
      return {
        attribute_id: attr.attribute_id,
        attribute_name: attr.attribute_name,
        checked_status: false,
        sub_attribute_data: subAttr
      };
    });
    let productImage = this.productImage.map(img => {
      return {
        image_id: img.image_id,
        image_name: img.image_name,
        checked_status: false
      };
    });
    let productVarObj = Object.assign({}, this.productVarObj);
    productVarObj = {
        "attribute_arr": attributeData,
        "selected_attribute_id": [],
        "selected_sub_attribute_id": [],
        "product_weight": 0,
        "original_price_increase": 0,
        "listing_price_increase": 0,
        "is_published":true,
        "final_original_price": this.orginalBasePrice,
        "final_listing_price": this.listingBasePrice,
        "existing_quantity": 0,
        "new_quantity": 0,
        "image_arr": productImage,
        "selected_image_id": []
    };
    this.productVarArr.push(Object.assign({}, productVarObj));
  }

  
  removeProductVarientClicked(index) {
    this.productVarArr.splice(index, 1);
  }

}
