import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { EventEmitter } from 'events';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `
    <router-outlet></router-outlet>
    <alert class="global-alert" [type]="alert.type" [dismissible]="true" dismissOnTimeout="5000" *ngIf="!!alert.show">{{ alert.msg }}</alert>
    <div [class]="'loader' + (!showLoader ? '' : ' show')">
      <h2>
        <span class="spinner-grow spinner-grow-lg"></span>
      </h2>
    </div>
  `
})
export class AppComponent implements OnInit {
  public alert = {
    type: '',
    msg: '',
    show: false
  };
  public showLoader = false;

  constructor(private router: Router, @Inject(EventEmitter) private _eventEmitter: EventEmitter) {
    _eventEmitter.on('showAlert', (data) => {
      this.alert.type = data.type == 1 ? 'success' : data.type == 2 ? 'danger' : 'warning';
      this.alert.msg = data.msg;
      setTimeout(() => {
        this.alert.show = true;
      }, 100);
      this.alert.show = false;
    });

    _eventEmitter.on('showLoader', ()=> {
      this.showLoader = true;
    });

    _eventEmitter.on('hideLoader', ()=> {
      this.showLoader = false;
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      
      setTimeout(() => {
        if(document.querySelectorAll('cui-breadcrumb .breadcrumb-item').length > 0) {
          let newA = document.createElement('a');
          newA.innerText = document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[0].innerText;
          newA.setAttribute('href', '#/dashboard');
          document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[0].parentNode.insertBefore(newA, document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[0]);
          document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[1].remove();
        }
      }, 1000);
    });
  }
}
