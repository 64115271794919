import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  faqListingsData(userId, accessToken, csrfToken, formData, pageNo) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", formData.searchText);
    fd.append("is_publish", formData.isPublish);
    fd.append("page_number", pageNo);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'faq-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  createFaq(userId, accessToken, csrfToken, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("faq_question", formData.faqTitle);
    fd.append("faq_answer", formData.faqDescription);
    fd.append("is_publish", formData.isPublish);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'create-faq', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editFaq(userId, accessToken, csrfToken, formData, faqId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("faq_id", faqId);
    fd.append("faq_question", formData.faqTitle);
    fd.append("faq_answer", formData.faqDescription);
    fd.append("is_publish", formData.isPublish);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'edit-faq', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteFaq(userId, accessToken, csrfToken, faqId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("faq_id", faqId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'delete-faq', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
