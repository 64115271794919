import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppVariables } from '../../app.variables';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { TrainerManagementService } from './trainer-management.service';
// import { AppConfigs } from '../../app.configs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-trainer-management',
  templateUrl: './trainer-management.component.html',
  styleUrls: ['./trainer-management.component.scss']
})
export class TrainerManagementComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';

  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;
  pageData: any = [];
  
  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'approveStatus': ['', [Validators.required]]
  });
  public editCustomerFormGroup: FormGroup = this._formBuilder.group({  
    'customerId': ['0', [Validators.required]],
    'approveStatus': ['0', [Validators.required]]
  });
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  public deleteId: string = '';

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _trainerManagementService: TrainerManagementService) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  deleteConfirmed() {
    this._emitter.emit('showLoader');
    this._trainerManagementService.delete(this._userId, this._accessToken, this._csrfToken, this.deleteId)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerDeletedSuccess
        });
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.trainerDeletedSuccess
        });
        this.pageData = resp.data;
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isActiveStatusChanged(customerId, activeStatus) {
    this._emitter.emit('showLoader');
    this._trainerManagementService.activeStatusEdit(this._userId, this._accessToken, this._csrfToken, customerId, activeStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.activeStatusChangedSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._trainerManagementService.getPageData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.searchFormGroup.controls['verifyStatus'].setValue('');
    this.searchFormGroup.controls['approveStatus'].setValue('');
    this.getListingsDataFunc();
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getListingsDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getListingsDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getListingsDataFunc();
  }

}
