import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../../../app.configs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BasicDetailsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  // get sub-category data 
  getSubCategoryData(userId, accessToken, categoryId, csrfToken){

    let fd = new FormData();
      fd.append("user_id", userId);
      fd.append("access_token", accessToken);
      fd.append("category_id", categoryId);
      
      let headers = new HttpHeaders()
      .set('Authorization', csrfToken);

      return this._http.post(this.apiBaseUrl + 'product-get-subcategory', fd, { headers: headers })
      .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  // create-page-data taken category & unitType
  basicDetailCreatePageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-basic-details-create-page-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  // Create data
  createBasicDetails(userId, accessToken, csrfToken, vendorId, subCategoryId, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("sub_category_id", subCategoryId);
    fd.append("product_name", formData.productName);
    fd.append("product_description", formData.productDescription);
    fd.append("gst_id", formData.gstSlab);
    fd.append("hsn_code", formData.hsnCode);
    fd.append("sac_code", formData.sacCode);
    fd.append("weight", formData.weight);
    fd.append("iscatalogue", formData.iscatalogue);
    fd.append("orginal_price", formData.orginalPrice);
    fd.append("listing_price", formData.listingPrice);
    fd.append("youtube_url", formData.youtubeUrl);
    fd.append("max_quantity", (formData.maxQuantity == undefined) ? 1 : formData.maxQuantity);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-basic-details-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  // Edit data
  basicDetailEditPageData(userId, accessToken, csrfToken, vendorId, productId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-basic-details-edit-page-get-data', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  editBasicDetails(userId, accessToken, csrfToken, vendorId, productId, formData) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", vendorId);
    fd.append("product_id", productId);
    fd.append("product_name", formData.productName);
    fd.append("sub_category_id", formData.subCategoryId);
    fd.append("product_description", formData.productDescription);
    fd.append("gst_id", formData.gstSlab);
    fd.append("hsn_code", formData.hsnCode);
    fd.append("sac_code", formData.sacCode);
    fd.append("weight", formData.weight);
    fd.append("iscatalogue", formData.iscatalogue);
    fd.append("orginal_price", formData.orginalPrice);
    fd.append("listing_price", formData.listingPrice);
    fd.append("youtube_url", formData.youtubeUrl);
    fd.append("max_quantity", (formData.maxQuantity == undefined) ? 1 : formData.maxQuantity);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'product-basic-details-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
