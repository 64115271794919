import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { AppVariables } from '../../app.variables';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public loginForm: FormGroup = this.formBuilder.group({
    'userEmail': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
    'userPassword': ['', Validators.required]
  });
  public messageType: number = 0;
  public loginMessage: string = '';

  constructor(public formBuilder: FormBuilder, private _loginService: LoginService, public appVariables: AppVariables, @Inject(EventEmitter) private _eventEmitter: EventEmitter, private _router: Router) { }

  loginSubmit(formData) {
    this._eventEmitter.emit('showLoader');
    this._loginService.postLogin(formData)
    .subscribe(resp => {
      // console.log(resp);
      this._eventEmitter.emit('hideLoader');
      if(resp.status == 200) {
        this._eventEmitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.loginSuccessMessage
        });

        localStorage.setItem("userDetails", JSON.stringify(resp.data));
        localStorage.setItem("userTitles", JSON.stringify(resp.user_title_listings));
        localStorage.setItem("_CSRF", resp.data.csrf_token);

        this.loginForm.controls['userEmail'].reset();
        this.loginForm.controls['userPassword'].reset();

        setTimeout(() => {
          this._router.navigateByUrl('/dashboard');
        }, 2000);
      }
      else {
        this._eventEmitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.loginErrorMessage
        });
        this.loginForm.controls['userPassword'].reset();
      }
    }, err => {
      this._eventEmitter.emit('hideLoader');
      this._eventEmitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
}
