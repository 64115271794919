import { Injectable } from '@angular/core';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getPageData(userId, accessToken, csrfToken) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'advertisement-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  create(userId, accessToken, csrfToken, formData, imgData) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("bannername", formData.bannername.trim());
    fd.append("alttext", formData.alttext.trim());
    fd.append("outputimagewidthpx", formData.outputimagewidthpx);
    fd.append("outputimageheightpx", formData.outputimageheightpx);
    fd.append("redirect_url", formData.redirectUrl);    
    fd.append("is_publish", formData.isPublished);
    fd.append("category_icon", JSON.stringify(imgData));
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);
    
    return this._http.post(this.apiBaseUrl + 'advertisement-create', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  edit(userId, accessToken, csrfToken, editId, formData, imgData) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("bannerkey", editId);
    fd.append("bannername", formData.bannername.trim());
    fd.append("alttext", formData.alttext.trim());
    fd.append("outputimagewidthpx", formData.outputimagewidthpx);
    fd.append("outputimageheightpx", formData.outputimageheightpx);
    fd.append("redirect_url", formData.redirectUrl); 
    fd.append("category_icon", JSON.stringify(imgData))
    fd.append("isPublished", formData.isPublished);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'advertisement-edit', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  delete(userId, accessToken, csrfToken, deleteId) {
    let fd = new FormData();
    fd.append("userkey", userId);
    fd.append("accesstoken", accessToken);
    fd.append("bannerkey", deleteId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'advertisement-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
}
