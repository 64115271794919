import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { AppConfigs } from '../../../app.configs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorListingsService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(private _http: HttpClient) { }

  getGymListings(userId, accessToken, csrfToken, searchFormData, pageNo) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("search_text", searchFormData.search);
    fd.append("verify_status", searchFormData.verifyStatus);
    fd.append("is_publish", searchFormData.publishStatus);
    fd.append("page_no", pageNo);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-listings', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  deleteGym(userId, accessToken, csrfToken, gymOwnerId) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", gymOwnerId);
    
    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-delete', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  updateVerificationStatus(userId, accessToken, csrfToken, gymOwnerId, verificationStatus) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", gymOwnerId);
    fd.append("is_verified", verificationStatus);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-update-verification-status', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  updatePublishStatus(userId, accessToken, csrfToken, gymOwnerId, publishStatus) {
    let fd = new FormData();
    
    fd.append("user_id", userId);
    fd.append("access_token", accessToken);
    fd.append("vendor_id", gymOwnerId);
    fd.append("is_publish", publishStatus);

    let headers = new HttpHeaders()
    .set('Authorization', csrfToken);

    return this._http.post(this.apiBaseUrl + 'vendor-update-publish-status', fd, { headers: headers })
    .pipe(map(res => JSON.parse(JSON.stringify(res))));
  }
 
}
