import { Injectable } from '@angular/core';
// import { AppConfigs } from './app.configs';
import { environment } from '../environments/environment';

@Injectable()
export class AppVariables {
  constructor() { }
  baseUrl = environment.baseUrl;

  monthLongArr: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  monthShortArr: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // unescapeChars = (str: any) => {
  //   return unescape(str);
  // };
  uploadImageBasePath : string = this.baseUrl + "images/uploads/";
  noImageUrl: string = this.baseUrl + 'images/no-image-500x500.jpeg';  
  // noImageUrl: string = this.baseUrl + 'images/noimage.jpg';  
  defaultSignatureUrl: string = this.baseUrl + 'images/signature.jpg';
  defaultBannerImageUrl: string = this.baseUrl + "images/defaultbanner.jpg";

  // =========== Different Messages For Different Pages Starts =========== //

  // Common Messages Starts

  // video Subscription package Starts
  trainerSubscriptionCreateSuccess: string = 'Package has been created successfully!';
  trainerSubscriptionEditedSuccess: string = 'Package has been modified successfully!';
  trainerSubscriptionDeletedSuccess: string = 'Package has been deleted successfully!';
  trainerSubscriptionDeleteMessage: string = 'Are you sure you want to delete this package? Deletion is permanent and can\'t be reverted.';
  // video Subscription package Ends

  // video Subscription package Starts
  subscriptionCreateSuccess: string = 'Subscription package has been created successfully!';
  subscriptionEditedSuccess: string = 'Subscription package has been modified successfully!';
  subscriptionDeletedSuccess: string = 'Subscription package has been deleted successfully!';
  subscriptionDeleteMessage: string = 'Are you sure you want to delete this subscription package? Deletion is permanent and can\'t be reverted.';
  // video Subscription package Ends

  // video Sub Category Starts
  videoSubCategoryCreateSuccess: string = 'Sub category has been created successfully!';
  videoSubCategoryEditedSuccess: string = 'Sub category has been modified successfully!';
  videoSubCategoryDeletedSuccess: string = 'Sub category has been deleted successfully!';
  videoSubCategoryDeleteMessage: string = 'Are you sure you want to delete this Sub category? Deletion is permanent and can\'t be reverted.';
  // video Sub Category Ends

  // Video Category Starts
  videoCategoryCreateSuccess: string = 'Category has been created successfully!';
  videoCategotyExists: string = 'Category already exists!! Please add another';
  videoCategoryDeleteMessage: string = 'Are you sure you want to delete this category? Deletion is permanent and can\'t be reverted.';
  videoCategoryEditedSuccess: string = 'Category has been updated successfully!';
  videoCategoryDeletedSuccess: string = 'Category has been deleted successfully!';
  // Video Category Ends

  advertisementCreateSuccess: string = 'Advertisement has been created successfully!';
  advertisementEditedSuccess: string = 'Advertisement has been updated successfully!';
  advertisementDeletedSuccess: string = 'Advertisement has been deleted successfully!';
  
  productRelatedFeaturesCreateSuccess: string = 'Product Related Features has been created successfully!';

  
  // Trainer Video listing starts
  trainerVideoDeleteMessage: string = 'Are you sure you want to delete this video? Deletion is permanent and can\'t be reverted'
  trainerVideoDeleteSuccess: string = 'Video has been deleted successfully!'

  // Trainer Video Listing Ends

  // Complain Listing
  editComplain: string = 'Remark Added Successfully!'

  //Start Trainer Management 
  trainerDeletedConfirm: string = 'Are you sure you want to delete this trainer? Deletion is permanent and can\'t be reverted.';
  trainerDeletedSuccess: string = 'The trainer has been deleted successfully!';
  activeStatusChangedSuccess: string = "Active status has been updated successfully!";
  //End Trainer Management

  orderCancellationStatusChangedSuccess = "Cancellation Status Changed Successfully";
  // currencySymbolFaIcon: string = '<i class="fa fa-inr"></i>';
  currencySymbolFaIcon: string = 'Rs.';
  someErrorOccurred: string = 'Oops! Some error occurred. Please try after sometime.';
  requiredErrorOccurred: string = 'Required field empty. Please enter require field.';
  videoDurationError: string = 'Oops! You cannot choose this slot!!';
  noDataFound: String = 'No Data Found.';
  invalidImageFormat: String = 'Invalid Image Format.';
  // Common Messages Ends

// Competition Category Starts
  competitionCreateSuccess: string = 'Competition has been created successfully!';
  competitionEditedSuccess: string = 'Competition has been modified successfully!';
  competitionDeletedSuccess: string = 'Competition has been deleted successfully!';
  competitionDeleteMessage: string = 'Are you sure you want to delete this competition? Deletion is permanent and can\'t be reverted.';
// Competition Category Ends

  // Competition Category Starts
  competitionCategoryCreateSuccess: string = 'Competition category has been created successfully!';
  competitionCategoryEditedSuccess: string = 'Competition category has been modified successfully!';
  competitionCategoryDeletedSuccess: string = 'Competition category has been deleted successfully!';
  competitionCategoryDeleteMessage: string = 'Are you sure you want to delete this competition category? Deletion is permanent and can\'t be reverted.';
  // Competition Category Ends

  //Order History page
  orderHistoryEditedSuccess = "Order details has been updated successfully!";  
  //Order History page
  
  //Start refarrel code management page
  productTaxCodeCreateSametime = "Already a product tax code is exists!";
  productTaxCodeCreateSuccess = "Product tax code has been created successfully!";
  productTaxCodeEditedSuccess = "Product tax code has been updated successfully!";
  productTaxCodeDeleteSuccess = "Product tax code has been deleted successfully!";
  productTaxCodeDeleteMessage: string = 'Are you sure you want to delete this product tax code? Deletion is permanent and can\'t be reverted.';
  //End  refarrel code management page

  //Start refarrel code management page
  referralCodeCreateSametime = "Already a referral code is exists in this time!";
  referralCodeCreateSuccess = "Referral Code has been created successfully!";
  referralCodeEditedSuccess = "Referral Code has been updated successfully!";
  referralCodeDeleteSuccess = "Referral Code has been deleted successfully!";
  referralCodeDeleteMessage: string = 'Are you sure you want to delete this referral code? Deletion is permanent and can\'t be reverted.';
  //End  refarrel code management page

  //Start discount code management page
  discountCodeCreateSametime = "Already a discount code is exists in this time!";
  discountCodeCreateSuccess = "Discount Code has been created successfully!";
  discountCodeEditedSuccess = "Discount Code has been updated successfully!";
  discountCodeDeleteSuccess= "Discount Code has been deleted successfully!";
  discountCodeDeleteMessage: string = 'Are you sure you want to delete this discount code? Deletion is permanent and can\'t be reverted.';
  //End  discount code management page

  //Fitcoin Purchase code management page
  fitcoinPurchaseCreateSametime = "Same Fitcoin purchase package already exist!";
  fitcoinPurchaseCreateSuccess = "Fitcoin purchase package has been created successfully!";
  fitcoinPurchaseEditedSuccess = "Fitcoin purchase package has been updated successfully!";
  fitcoinPurchaseDeleteSuccess= "Fitcoin purchase package has been deleted successfully!";
  fitcoinPurchaseDeleteMessage: string = 'Are you sure you want to delete this fitcoin purchase package? Deletion is permanent and can\'t be reverted.';
  //Fitcoin Purchase code management page

  //Start account management page
  paymentStatusChangedSuccess ="Payment status changed successfully!";
  paymentStatusChangedError ="Oops! Some error occurred. Please try after sometime.";
  //End account management page
  //Start Customer Management 
  customerDeletedConfirm: string = 'Are you sure you want to delete this customer? Deletion is permanent and can\'t be reverted.';
  trainerVideoDeletedConfirm: string = 'Are you sure you want to delete this Video? Deletion is permanent and can\'t be reverted.'
  customerDeletedSuccess: string = 'The Customer has been deleted successfully!';
  //End Customer Management
  // Login Page Starts
  loginErrorMessage: string = 'Invalid email or password!';
  loginSuccessMessage: string = 'Login successfully! Redirecting to Dashboard page.';
  // Login Page Ends

  // Create / Edit CMS Page Starts
  cmsPageCreateSuccess: string = 'Page has been created successfully!';
  cmsPageUpdatedSuccess: string = 'Page has been updated successfully!';
  cmsPageCreateImageAspectRatioError: string = 'Image aspect ratio should be of 12:5 e.g. 1920X800.';
  // Create / Edit CMS Page Ends

  // CMS Page Listings Starts
  cmsPageListingsDeleteMessage: string = 'Are you sure you want to delete this page? Deletion is permanent and can\'t be reverted.';
  cmsPageListingsDeletedMessage: string = 'The page has been deleted successfully!';
  // CMS Page Listings Ends

  // Site Settings Starts
  siteSettingsCreateSuccess: string = 'Setting has been created successfully!';
  siteSettingDeleteMessage: string = 'Are you sure you want to delete this setting? Deletion is permanent and can\'t be reverted.';
  siteSettingsEditedSuccess: string = 'Setting has been updated successfully!';
  // Site Settings Ends
  
  // Package Management Starts
  packageManagementCreateSuccess: string = 'Package has been created successfully!';
  packageManagementDeleteMessage: string = 'Are you sure you want to delete this package? Deletion is permanent and can\'t be reverted.';
  packageManagementEditedSuccess: string = 'Package has been modified successfully!';
  packageManagementDeletedSuccess: string = 'Package has been deleted successfully!';
  // Package Settings Ends

  // Security Settings Starts
  securitySettingsInvalidOldEmail: string = 'Invalid old email!';
  securitySettingsEmailEditSuccess: string = 'The login email has been updated successfully!';
  securitySettingsInvalidOldPassword: string = 'Invalid old password!';
  securitySettingsPasswordEditSuccess: string = 'The login password has been updated successfully!';
  // Security Settings Ends

  // Create / Edit Testimonial Page Starts
  testimonialCreateSuccess: string = 'Testimonial has been created successfully!';
  testimonialUpdatedSuccess: string = 'Testimonial has been updated successfully!';
  testimonialEditSuccess: string = 'Testimonial has been modified successfully!';
  // Create / Edit Testimonial Page Ends

  // Testimonial Listings Page Starts
  testimonialListingsActiveStatusChangedSuccess: string = 'Active status has been updated successfully!';
  testimonialDeleteMessage: string = 'Are you sure you want to delete this testimonial? Deletion is permanent and can\'t be reverted.';
  testimonialListingsDeletedSuccess: string = 'Testimonial has been deleted successfully!';
  // Testimonial Listings Page Ends

  // Gym Review Listing Page Starts
  gymReviewListingsDeleteMessage: string = 'Are you sure you want to delete this review? Deletion is permanent and can\'t be reverted.';
  gymReviewPublishStatusUpdateSuccess: string = 'Publish status has been updated successfully!';
  gymReviewDeleteSuccessMessage: string = 'Review has been delete successfully!';
  // Gym Review Listing Page Ends

  // Create Gym Owner Starts
  /* ****** Need to delete ****** */
  gymCreateEditSliderImageBlankUrl: string = 'assets/img/no-image-200-112.jpg';
  gymCreateBannerImageBlankUrl: string = 'assets/img/no-image-500-128.jpg';
  gymCreateSuccess: string = 'Branch has been created successfully!';
  gymEditSuccess: string = 'Branch has been modified successfully!';
  gymCreateDuplicateEmailError: string = 'This email id already exists!';
  gymCreateProfilePicErrorText: string = 'Please upload your profile image!';
  gymCreateBannerPicErrorText: string = 'Please upload your banner image!';
  gymCreateSliderImageAtLeastOneImageText: string = 'At least 1 image need to be uploaded!';
  gymCreateSliderImageAtLeastOneImageAlertText: string = 'At least 1 slider image need to be uploaded!';
  gymCreateAmenitiesAtLeastOneAmenityText: string = 'At least 1 amenity need to be checked!';
  gymCreateServicesAtLeastOneAmenityText: string = 'If any';
  /* ****** Need to delete ****** */
  vendorAdminDeductionRateUpdateSuccess: string = 'Commission rate has been updated successfully!';
  vendorAdminVerificationStatusUpdateSuccess: string = 'Verification status has been updated successfully!';
  vendorAdminPublishStatusUpdateSuccess: string = 'Publish status has been updated successfully!';
  vendorCreatePersonalDetailsDuplicateEmailError: string = 'This email id already exists!';
  vendorCreatePersonalDetailsSuccess: string = 'Personal details for vendor has been created successfully!';
  vendorCreateCompanyDetailsSuccess: string = 'Company details for vendor has been updated successfully!';
  vendorCreateProfileImageSuccess: string = 'Profile image for vendor has been updated successfully!';
  vendorCreateBankDetailsSuccess: string = 'Bank details for vendor has been updated successfully!';
  vendorUpdatePersonalDetailsSuccess: string = 'Personal details for vendor has been updated successfully!';
  vendorUpdateCompanyDetailsSuccess: string = 'Company details for vendor has been updated successfully!';
  vendorUpdateProfileImageSuccess: string = 'Profile image for vendor has been updated successfully!';
  vendorUpdateBankDetailsSuccess: string = 'Bank details for vendor has been updated successfully!';
  // Create Gym Owner Ends

  // Create Gym Branch Starts
  gymBranchAdminVerificationStatusUpdateSuccess: string = 'Verification status has been updated successfully!';
  gymBranchAdminPublishStatusUpdateSuccess: string = 'Publish status has been updated successfully!';
  gymBranchCreateBasicDetailsSuccess: string = 'Basic details for new branch has been created successfully!';
  gymBranchCreateAddressDetailsSuccess: string = 'Address details for new branch has been created successfully!';
  gymBranchCreateBannerImageSuccess: string = 'Banner Image for new branch has been uploaded successfully!';
  gymBranchSliderImageAtLeastOneImageAlertText: string = 'At least 1 slider image need to be uploaded!';
  gymBranchCreateSliderImagesSuccess: string = 'Slider Images for new branch has been uploaded successfully!';
  gymBranchPrimaryServiceErrorMessage: string = 'Please select a primary service!';
  gymBranchAdditionalServiceAtLeastOneServiceText: string = 'At least 1 additional service need to be checked!';
  gymBranchAmenitiesAtLeastOneAmenityText: string = 'At least 1 amenity need to be checked!';
  gymBranchCreateAmenitiesServicesSuccess: string = 'Services and amenities for new branch has been created successfully!';
  gymBranchCreateServicePackageSuccess: string = 'Service Package for new branch has been created successfully!';
  // Create Gym Branch Ends

  // Gym Branch Listing Page Starts
  gymBranchListingsDeleteMessage: string = 'Are you sure you want to delete this gym branch? Deletion is permanent and can\'t be reverted.';
  gymBranchListingsDeleteSuccess: string = 'Branch has been delete successfully!';
  // Gym Branch Listing Page Ends

  // Edit Gym Branch Starts
  gymBranchEditBasicDetailsSuccess: string = 'Basic details has been updated successfully!';
  gymBranchEditAddressDetailsSuccess: string = 'Address details has been updated successfully!';
  gymBranchEditBannerImageSuccess: string = 'Banner Image has been uploaded successfully!';
  gymBranchEditSliderImagesSuccess: string = 'Slider Images has been uploaded successfully!';
  gymBranchPrimaryServiceEditChangeMessage: string = 'Are you sure you want to change the primary service? If you change primary service then the packages(If you have already added) will be deleted and can\'t be reverted.';
  gymBranchAdditionalServiceEditChangeMessage: string = 'Are you sure you want to change the additional service? If you change additional service then the packages(If you have already added) will be deleted and can\'t be reverted.';
  gymBranchEditAmenitiesServicesSuccess: string = 'Services and amenities has been updated successfully!';
  gymBranchEditServicePackageSuccess: string = 'Service Package has been updated successfully!';
  gymBranchDeleteServicePackageMessage: string = 'Are you sure you want to delete this package? Deletion is permanent and can\'t be reverted.';
  gymBranchDeleteServicePackageSuccess: string = 'Service Package has been deleted successfully!';
  gymBranchEditServicePackageActiveStatusSuccess: string = 'Service Package has been edited successfully!';
  // Edit Gym Branch Ends

  // Gym Amenities Starts
  gymAmenitiesCreateSuccess: string = 'Amenity has been created successfully!';
  gymAmenitiesEditedSuccess: string = 'Amenity has been modified successfully!';
  gymAmenitiesDeletedSuccess: string = 'Amenity has been deleted successfully!';
  gymAmenitiesDeleteMessage: string = 'Are you sure you want to delete this amenity? Deletion is permanent and can\'t be reverted.';
  // Gym Amenities Ends

  // Gym Services Starts
  gymServicesCreateSuccess: string = 'Service has been created successfully!';
  gymServicesEditedSuccess: string = 'Service has been modified successfully!';
  gymServicesDeletedSuccess: string = 'Service has been deleted successfully!';
  gymServicesDeleteMessage: string = 'Are you sure you want to delete this service? Deletion is permanent and can\'t be reverted.';
  gymServiceTypeArray = [
    {
      "text": "Primary Service",
      "value": "1"
    },
    {
      "text": "Additional Service",
      "value": "2"
    },
    {
      "text": "Primary And Additional Both",
      "value": "3"
    },
  ]

  // Gym Services Ends
  
  // Feedback / Enquiry To Admin Listings Starts
  feedbackEnquiryToVendorComposeMailSentSuccess: string = 'Reply sent to vendor successfully!';
  // Feedback / Enquiry To Admin Listings Ends

    // 
    composeMailSentSuccess: string = 'Mail sent successfully!';
    // 
  
  // Gym Owner Service Package Managements Starts
  vendorServicePackageManagementsCreateSuccess: string = 'Package has been created successfully!';
  packageConflictsWithOtherPackages: string = 'Price range for new package conflicts with existing package!';
  vendorServicePackageManagementsInvalidPackage: string = 'Package does not exists!';
  vendorServicePackageManagementsEditSuccess: string = 'Package has been edited successfully!';
  vendorServicePackageManagementsDeleteSuccess: string = 'Package has been delete successfully!';
  // Gym Owner Service Package Managements Ends
  // ============ Different Messages For Different Pages Ends ============ //

  
  // Manage City Starts
  cityEditedSuccess: string = 'City has been modified successfully!';
  cityDeletedSuccess: string = 'City has been deleted successfully!';
  cityDeleteMessage: string = 'Are you sure you want to delete this City? Deletion is permanent and can\'t be reverted.';
  // Manage City Starts

  
  // Manage FAQs Starts
  faqCreateSuccess: string = 'FAQ has been created successfully!';
  faqEditedSuccess: string = 'FAQ has been modified successfully!';
  faqDeleteMessage: string = 'Are you sure you want to delete this FAQ? Deletion is permanent and can\'t be reverted.';
  faqDeletedSuccess: string = 'FAQ has been deleted successfully!';
  invalidFaqId: string = 'The FAQ ID does not exists!';
  // Manage FAQs Starts

  // Manage Product Starts
  productImagesCreateEditBlankUrl: string = 'assets/img/no-image-500x500.jpeg';
  productAdminPublishStatusUpdateSuccess: string = 'Product publish status has been updated successfully!';
  productCreateSuccess: string = 'Product has been created successfully!';
  productEditedSuccess: string = 'Product has been modified successfully!';
  productDeleteMessage: string = 'Are you sure you want to delete this Product? Deletion is permanent and can\'t be reverted.';
  productDeletedSuccess: string = 'Product has been deleted successfully!';
  invalidProductId: string = 'The Product ID does not exists!';
  productImagesEditSuccess: string = 'Product Images has been uploaded successfully!';
  productImagesAtLeastOneImageAlertText: string = 'At least 1 Product image need to be uploaded!';
  productImagesCreateSuccess: string = 'Product Images for new branch has been uploaded successfully!';
  productVarientEditSuccess: string = 'Product Variant has been updated successfully!';
  productVarientCreateSuccess: string = 'Product Variant has been created successfully!';
  productFeaturesEditSuccess: string = 'Product Features has been updated successfully!';
  productFeaturesCreateSuccess: string = 'Product Features has been created successfully!';
  productFeaturesAtLeastOneFeatureText: string = 'At least 1 feature need to be checked!';
  productVarientAtLeastOneAttr: string = 'At least 1 attribute and option need to be uploaded!';

  // Vendor Listings Starts
  vendorListingsDeleteMessage: string = 'Are you sure you want to delete this vendor? Deletion is permanent and can\'t be reverted.';
  vendorListingsDeletedSuccess: string = 'Vendor has been deleted successfully!';
  // Vendor Listings Ends

  // Product Related Features Starts
  productRelatedFeaturesDeleteMessage: string = 'Are you sure you want to delete this product related features? Deletion is permanent and can\'t be reverted.';
  productRelatedFeaturesDeletedSuccess: string = 'Product Related Feature has been deleted successfully!';
  // Product Related Features Ends

  // Product Listing Page Starts
  productListingsDeleteMessage: string = 'Are you sure you want to delete this Product? Deletion is permanent and can\'t be reverted.';
  productListingsDeleteSuccess: string = 'Product has been delete successfully!';
  // Product Listing Page Ends

   // gst Page Starts
   gstCreateSuccess: string = 'Gst has been created successfully!';
   gstDeleteMessage: string = 'Are you sure you want to delete this gst? Deletion is permanent and can\'t be reverted.';
   gstEditedSuccess: string = 'Gst has been updated successfully!';
   gstDeletedSuccess: string = 'Gst has been deleted successfully!';
   // gst Page Ends

  //  Product Attribute Page Starts
  attributeCreateSuccess: string = 'Attribute has been created successfully!';
  attributeEditSuccess: string = 'Attribute has been edited successfully!';
  attributeDeleteSuccess: string = 'Attribute has been deleted successfully!';
  attributeDeleteMessage: string = 'Are you sure you want to delete this attribute? Deletion is permanent and can\'t be reverted.';
  //  Product Attribute Page Ends

  couponCreateSuccess: string = 'Coupon has been created successfully!';
  couponEditedSuccess: string = 'Coupon has been updated successfully!';
  couponDeletedSuccess: string = 'Coupon has been deleted successfully!';
  couponDeleteMessage: string = 'Are you sure you want to delete this coupon? Deletion is permanent and can\'t be reverted.';

  // product deals
  dealDeleteSuccessMessage: string = 'Product Deal Deleted Successfully!';
  deleteConfirmMessage: string = 'Are you sure you want to delete this Product deal? Deletion is permanent and can\'t be reverted.';

}