import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVariables } from '../../app.variables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FullGymListingsService } from './full-gym-listings.service';

@Component({
  selector: 'app-full-gym-listings',
  templateUrl: './full-gym-listings.component.html',
  styleUrls: ['./full-gym-listings.component.scss']
})
export class FullGymListingsComponent implements OnInit {
  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public gymListingsData: any = [];
  public currentPageNumber: number = 1;
  public dataPerPage: number = 20;
  public vendorId: string = '';
  public gymDeleteId: string = '';
  public vendorDeleteId: string = '';

  public searchFormGroup: FormGroup = this.formBuilder.group({
    'search': ['', []],
    'verifyStatus': ['0', [Validators.required]],
    'publishStatus': ['0', [Validators.required]]
  });
  public numberOfPages: number = 0;
  public paginationArr: any = [];
  public searchCriteriaChanged: boolean = false;

  constructor(private _modalService: BsModalService, private _fullGymListingsService: FullGymListingsService, @Inject(EventEmitter) private _emitter: EventEmitter, private _router: Router, public appVariables: AppVariables,public formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute) { }
  
  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      
      this.getListingsDataFunc();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.searchFormGroup.controls['verifyStatus'].setValue('0');
    this.searchFormGroup.controls['publishStatus'].setValue('0');
    this.getListingsDataFunc();
  }
  
  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber - 1;
    this.getListingsDataFunc();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getListingsDataFunc();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getListingsDataFunc();
  }

  getListingsDataFunc() {
    this._emitter.emit('showLoader');
    this._fullGymListingsService.getListingData(this._userId, this._accessToken, this._csrfToken, this.searchFormGroup.value.search, this.searchFormGroup.value.verifyStatus, this.searchFormGroup.value.publishStatus, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp.data);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this.gymListingsData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);        
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isVerifiedChanged(vendorId, gymBranchId, verificationStatus) {
    this._emitter.emit('showLoader');
    this._fullGymListingsService.editVerificationStatus(this._userId, this._accessToken, this._csrfToken, vendorId, gymBranchId, verificationStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymBranchAdminVerificationStatusUpdateSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  isActiveStatusChanged(vendorId, gymBranchId, activeStatus) {
    this._emitter.emit('showLoader');
    this._fullGymListingsService.editPublishStatus(this._userId, this._accessToken, this._csrfToken, vendorId, gymBranchId, activeStatus)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymBranchAdminPublishStatusUpdateSuccess
        });
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  deleteListingsDataFunc() {
    if(this.gymDeleteId == '' || this.vendorDeleteId == '') {
      return false;
    }

    this._emitter.emit('showLoader');
    this._fullGymListingsService.deleteBranch(this._userId, this._accessToken, this._csrfToken, this.vendorDeleteId, this.gymDeleteId, this.searchFormGroup.value.search, this.searchFormGroup.value.verifyStatus, this.searchFormGroup.value.publishStatus, this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      this.modalRef.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.gymBranchListingsDeleteSuccess
        });

        this.gymListingsData = resp.data;

        this.dataPerPage = resp.data_per_page;

        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;

        this.paginationArr = this.generatePaginationArr(this.numberOfPages);        
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      this.modalRef.hide();
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

}
