import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AdminVideoUploadService } from './admin-video-upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from 'events';
import { AppVariables } from '../../../app.variables';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';

import {HttpEventType, HttpResponse} from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-admin-video-upload',
  templateUrl: './admin-video-upload.component.html',
  styleUrls: ['./admin-video-upload.component.scss']
})
export class AdminVideoUploadComponent implements OnInit {

  private data: any;
  public uploadPercent;
  // Track upload status by tracking code
  // 0 - Not started
  // 1 - File chosen
  // 2 - Wrong file type
  // 3 - Uploading
  // 4 - Upload error
  // 5 - Upload complete
  public uploadStatus: Number = 0;

  
  private vimeoAPI: string = '930133a6f51c20bf41eed8ac57cf7897';
  // private vimeoVideoName: string = 'TEST'; 

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public currentPageNumber: number = 1;
  public dataPerPage: number = 50;
  public config: any = {
    height: 150
  };
  public pageData: any = [];
  public categoryListing: any = [];
  public subCategoryListing: any = [];
  public detailsModalData: any = {};
  public videoFile;
  // public editId: string = '';
  // public deleteId: string = '';

  public maxUploadSizeInMb: number = 0;
  public maxUploadFiles: number = 0;

  public membershipPackageLimitReached: boolean = false;
  public uploadedUrl: string = '';

  public videoNameErrorShow: boolean = false;
  public videoDescriptionErrorShow: boolean = false;

  public videoName:string = '';
  public videoId:string = '';
  public videoThumnail = this.appVariables.noImageUrl;
  public uploadedVideoUrl:any;


  public videoFormGroup: FormGroup = this._formBuilder.group({
    'vimeoAPI': ['', []],
    'vimeoVideoName': ['', [Validators.required]],
    'vimeoVideoDescription': ['', [Validators.required]],
    'category': ['', [Validators.required]],
    'subCategory': ['', [Validators.required]],
    'isFree': [false, [Validators.required]],
    'activeStatus': [true, [Validators.required]]
  });

  constructor(private _modalService: BsModalService, private _formBuilder: FormBuilder, public appVariables: AppVariables, private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter, private _uploadVideoService: AdminVideoUploadService, private _sanitize: DomSanitizer,private _activateRoute:ActivatedRoute) { }

  ngOnInit() {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");      
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
    // Init Vimeo Data Form
    // this.initVimeoForm();
    if(typeof this._activateRoute.snapshot.params.videoId != 'undefined') {
      this.getEditPageData();
      this.videoId = this._activateRoute.snapshot.params.videoId;
    } else {
      this.getPageDataFunc();
    }
    this.videoFormGroup.controls['vimeoAPI'].setValue(this.vimeoAPI);
    // Return Vimeo Link from API response
    this._uploadVideoService.vimeoLinkObs.subscribe(
      data => {
        console.log('Video Url: ' + data);
        this.uploadedUrl = data;
      }, error => {
        throw new Error(error);
      }
    );    
  }

  getPageDataFunc() {
    this._emitter.emit('showLoader');
    this._uploadVideoService.getPageData(this._userId, this._accessToken, this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }      
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.noDataFound
        });        
      }
      else if(resp.status == 200 && resp.data != '') {
        this.pageData = resp.data;
        this.categoryListing = resp.data.category_listings;
        this.videoFormGroup.controls['category'].setValue(resp.data.category_listings[0].video_category_id);
        if(resp.data.category_listings[0].video_category_id != ''){
          this.onCategoryChanged();
        }
        this.maxUploadSizeInMb = this.pageData.max_upload_size_in_mb - this.pageData.upload_video_size_in_mb;
        this.maxUploadFiles = this.pageData.max_upload_number - this.pageData.total_uploaded_videos;

        // if(this.maxUploadSizeInMb <= 0 || this.maxUploadFiles <= 0) {
        //   alert('You have reached to the limit of your membership package.');
        //   this.membershipPackageLimitReached = true;
        // }
      }      
    }, (err) => {
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }else if(err.status == 400){
        this._emitter.emit('showAlert', {
          type: 2,
          msg: err.error.message
        });
      }else{        
        this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
      }
      
    });
  }

  getEditPageData() {
    this._emitter.emit('showLoader');
    this._uploadVideoService.getEditPageData(this._userId,this._accessToken,this._csrfToken,this._activateRoute.snapshot.params.videoId).subscribe(resp=>{
      this._emitter.emit('hideLoader');
      if(resp.status == 200) {
        this.categoryListing = resp.data.category_listings;
        this.videoFormGroup.controls['category'].setValue(resp.data.video_details.video_category_id);
        this.videoFormGroup.controls['vimeoVideoName'].setValue(resp.data.video_details.video_name);
        this.videoFormGroup.controls['vimeoVideoDescription'].setValue(resp.data.video_details.video_description);
        this.videoFormGroup.controls['isFree'].setValue(resp.data.video_details.is_free);
        this.videoFormGroup.controls['activeStatus'].setValue(resp.data.video_details.active_status);
        this.uploadedUrl = resp.data.video_details.video_url;
        if(resp.data.category_listings[0].video_category_id != ''){
          this.onCategoryChanged();
        }
        this._uploadVideoService.getVideoData(this.uploadedUrl.split('/')[3]).subscribe(data=>{
          this.videoThumnail = data[0].thumbnail_small;
        });
        this.uploadedVideoUrl = this._sanitize.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.uploadedUrl.split('/')[3]);
        this.videoName = resp.data.video_details.video_name;
      } else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    },err=>{
      this._emitter.emit('hideLoader');
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }else if(err.status == 400){
        this._emitter.emit('showAlert', {
          type: 2,
          msg: err.error.message
        });
      }else{        
        this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
      }
    })
  }

  onCategoryChanged() {
    this._emitter.emit('showLoader');
    this._uploadVideoService.getVideoSubCategoryByCategory(this.videoFormGroup.value.category,this._csrfToken)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 200) {
        this.subCategoryListing = resp.data.subcategory_listings;
        console.log("subCategoryListing",this.subCategoryListing);
        this.videoFormGroup.controls['subCategory'].setValue(this.subCategoryListing[0].sub_category_id);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
    });
  }

  selectFile(event): void {

    this.videoName = event.target.files[0].name;
    let file = event.target.files[0];
    this.videoFile = event.target.files;
    // console.log(file.size,this.maxUploadSizeInMb * 1024 * 1024);
    if (file.size > this.maxUploadSizeInMb * 1024 * 1024) {      
      // alert('max file size exceeded!');
      file = null;
      return;
    }  
  }

  uploadVimeoVideo(files: FileList): void {
    
    if(!this.videoFormGroup.controls['vimeoVideoName'].valid) {
      this.videoNameErrorShow = true;
    }
    if(!this.videoFormGroup.controls['vimeoVideoDescription'].valid) {
      this.videoDescriptionErrorShow = true;
    }
    
    if(this.videoFormGroup.controls['vimeoVideoName'].invalid) {
      this._emitter.emit('showAlert', {
        type: 2,
        msg: 'Video Name field is mandatory.'
      });
      return;
    }
    if(this.videoFormGroup.controls['vimeoVideoDescription'].invalid) {
      this._emitter.emit('showAlert', {
        type: 2,
        msg: 'Video Description field is mandatory.'
      });
      return;
    }
    this.uploadStatus = 1;

     
    // if (this.videoId == '') {
    //   alert('No file selected!');
    //   this.uploadStatus = 0;
    //   return;
    // }
    if(this.videoId != '' ) {
      this._emitter.emit('showLoader');
      this._uploadVideoService.editVideoData(this._userId,this._accessToken,this._csrfToken,this.videoId,this.videoFormGroup.value).subscribe(resp=>{
        this._emitter.emit('hideLoader');
        if(resp.status == 200) {
          this.videoFormGroup.controls['vimeoVideoName'].setValue('');
          this.videoFormGroup.controls['vimeoVideoDescription'].setValue('');
          this.uploadedUrl ='';
          this.uploadStatus = 0;
          this.videoId = '';
          this._emitter.emit('showAlert', {
            type: 1,
            msg: 'Your video Infomation has been updated successfully.'
          });
          setTimeout(() => {
            this._router.navigateByUrl('/video-management/admin-video-listing');
          }, 2000);
        }
      },err=>{

      })
      return;
    }

    const file: File = files[0];
    // console.log(file);
    console.log(this.videoFormGroup.value);

    // if (file.size > this.maxUploadSizeInMb * 1024 * 1024) {
      
    //   alert('max file size exceeded!');
    //   return;
    // }

    const isAccepted = this.checkAllowedType(file.type);
    if (isAccepted) {
      this.uploadStatus = 1;
      const options = {
        // token : this.getFormValue('vimeoAPI'),
        // url : 'https://api.vimeo.com/me/videos',
        // videoName: this.getFormValue('vimeoVideoName'),
        // videoDescription: this.getFormValue('vimeoVideoDescription')
        token : this.vimeoAPI,
        url : 'https://api.vimeo.com/me/videos',
        videoName: this.getFormValue('vimeoVideoName'),
        videoDescription: this.getFormValue('vimeoVideoDescription')        
// vimeoVideoName
      };
      
      this._emitter.emit('showLoader');

      this._uploadVideoService.createVimeo(options, file.size)
        .pipe(
          map(data => this.data = data),
          switchMap(
            () => {
              this._uploadVideoService.updateVimeoLink(this.data.link);
              if (this.data.upload.size === file.size) {
                return this._uploadVideoService.vimeoUpload(this.data.upload.upload_link, file);
              } else {
                this.uploadStatus = 4;
              }
            }
          )
        ).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadPercent = Math.round(100 * event.loaded / event.total);
            this.uploadStatus = 3;
          } else if (event instanceof HttpResponse) {
            this.uploadStatus = 5;
            setTimeout(() => {
              this.uploadStatus = 0;
            }, 5000);
          }
        },
        (error) => {
          console.log('Upload Error:', error);
          this._emitter.emit('hideLoader');
          this.uploadStatus = 4;
        }, () => {
          console.log('Upload done');
          // call api 

          this._uploadVideoService.videoUploadDB(this._userId, this._accessToken, this._csrfToken,this.videoFormGroup.value,((file.size / 1024) / 1024).toFixed(3), this.uploadedUrl).subscribe(res=>{
            this._emitter.emit('hideLoader');
            console.log(res);
            if(res.status == 200) {
              this.videoFormGroup.controls['vimeoVideoName'].setValue(null);
              this.videoFormGroup.controls['vimeoVideoDescription'].setValue(null);
              this._emitter.emit('showAlert', {
                type: 1,
                msg: 'Your video has been uploaded successfully.'
              });
              setTimeout(() => {
                this._router.navigateByUrl('/video-management/admin-video-listing');
              }, 2000);
            }
          });
        }
      );
    
      this.videoName = '';
    } else {
      this.uploadStatus = 2;
    }
  }

  initVimeoForm() {
    this.videoFormGroup = new FormGroup(
      {
        // vimeoAPI: new FormControl('', [Validators.required]),
        vimeoVideoName: new FormControl('', [Validators.required]),
        vimeoVideoDescription: new FormControl('', [Validators.required]),
        category: new FormControl('', [Validators.required]),
        subCategory: new FormControl('', [Validators.required]),
        isFree: new FormControl(true, [Validators.required])
      }
    );
  }

  // HELPERS
  allowUpload(): void {
    this.uploadStatus = 0;
  }

  checkAllowedType(filetype: string): boolean {
    const allowed = ['mov', 'wmv', 'avi', 'flv', 'mp4'];
    const videoType = filetype.split('/').pop();
    return allowed.includes(videoType);
  }

  getFormValue(selector: string) {
    return this.videoFormGroup.get(selector).value;
  }


}
