import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppVariables } from '../../../app.variables';
import { ComplainVideosServiceService } from './complain-videos-service.service';


@Component({
  selector: 'app-complain-videos',
  templateUrl: './complain-videos.component.html',
  styleUrls: ['./complain-videos.component.css']
})
export class ComplainVideosComponent implements OnInit {

  modalRef: BsModalRef;
  private _userId: string = '';
  private _accessToken: string = '';
  private _csrfToken: string = '';
  public pageData: any =[];
  public numberOfPages: number = 0;
  public deleteTranerVideoId: string = '';
  public complainId: string = '';
  public actionRemark: string = '';
  public paginationArr: any = [];
  public currentPageNumber: number = 1;
  public dataPerPage: number = 10;
  public searchText: string = '';
  public detailsModalData: any = {};
  public detailsModalDataVideoUrl: any;
  public videoName = '';
  public videoThumnail = this.appVariables.noImageUrl;
  public searchFormGroup: FormGroup = this._formBuilder.group({
    'search': ['', []],
  });
  public config: any = {
    height: 200,
    disableResizeEditor: true,
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
    ]
  };

  constructor(private _trainerService:ComplainVideosServiceService,private _formBuilder: FormBuilder,private _router: Router, @Inject(EventEmitter) private _emitter: EventEmitter,private _modalService: BsModalService,public appVariables: AppVariables,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    try {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this._userId = userDetails.user_id;
      this._accessToken = userDetails.access_token;
      this._csrfToken = localStorage.getItem("_CSRF");
      this.getComplainVideoListData();
    } catch (error) {
      this._router.navigateByUrl('/login');
      this._emitter.emit('hideLoader');
    }
  }

  confirmDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }
  resetSearchAndSubmitClicked() {
    this.searchFormGroup.controls['search'].setValue('');
    this.getComplainVideoListData();
  }
  toFixed(value: string, upto = 2) {
    return parseFloat(value).toFixed(upto);
  }
  getComplainVideoListData() {
    this._emitter.emit('showLoader');
    this._trainerService.getComplainVideosList(this._userId, this._accessToken, this._csrfToken,this.searchFormGroup.value.search,this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      if(typeof this.modalRef !== 'undefined') {
        this.modalRef.hide();
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  } 

  editModal(modal) {
    modal.show();
  }

  editConfirmed(remark) {
    this._emitter.emit('showLoader');
    this._trainerService.editComplainVideo(this._userId, this._accessToken, this._csrfToken, this.complainId, this.actionRemark, this.searchFormGroup.value.search,this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');
      remark.hide();

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.editComplain
        });
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.editComplain
        });
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }

  detailsPage(videoId) {
    this._router.navigateByUrl('/live-videos/trainer-videos?video_name='+videoId);
  }

  isActiveStatusChanged(videoId, activeStatus) {
    this._emitter.emit('showLoader');
    this._trainerService.activeStatusEdit(this._userId, this._accessToken, this._csrfToken, videoId, activeStatus,this.searchText,this.dataPerPage,this.currentPageNumber)
    .subscribe((resp) => {
      // console.log(resp);
      this._emitter.emit('hideLoader');

      if(resp.status == 401) {
        this._router.navigateByUrl('/login');
      }
      else if(resp.status == 201) {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
      else if(resp.status == 204) {
        this.pageData = [];
      }
      else if(resp.status == 200) {
        this._emitter.emit('showAlert', {
          type: 1,
          msg: this.appVariables.activeStatusChangedSuccess
        });
        this.pageData = resp.data;
        this.dataPerPage = resp.data_per_page;
        this.numberOfPages = (resp.total % resp.data_per_page != 0) ? parseInt((resp.total / resp.data_per_page).toString()) + 1 : resp.total / resp.data_per_page;
        this.paginationArr = this.generatePaginationArr(this.numberOfPages);
      }
      else {
        this._emitter.emit('showAlert', {
          type: 2,
          msg: this.appVariables.someErrorOccurred
        });
      }
    }, err => {
      if(err.status == 401) {
        this._router.navigateByUrl('/login');
      }
      this._emitter.emit('hideLoader');
      this._emitter.emit('showAlert', {
        type: 2,
        msg: this.appVariables.someErrorOccurred
      });
    });
  }
  generatePaginationArr(maxLimit) {
    let arr = [];
    for(let i=1; i<=maxLimit; i++) {
      arr.push(i);
    }
    return arr;
  }
  detailsModal(modal) {
    modal.show();
  }

  paginationPrev() {
    if(this.currentPageNumber == 1) {
      return false;
    }
    this.currentPageNumber = this.currentPageNumber - 1;
    this.getComplainVideoListData();
  }

  paginationClicked(pageNum: number) {
    if(this.currentPageNumber == pageNum) {
      return false;
    }

    this.currentPageNumber = pageNum;
    this.getComplainVideoListData();
  }

  paginationNext() {
    if(this.currentPageNumber == this.numberOfPages) {
      return false;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getComplainVideoListData();
  }
}
