import { Component, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnDestroy {

  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public currentYear: number = new Date().getFullYear();

  constructor(private _router: Router, @Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngAfterViewInit(): void {
    let newA = document.createElement('a');
    newA.innerText = document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[0].innerText;
    newA.setAttribute('href', '#/dashboard');
    document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[0].parentNode.insertBefore(newA, document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[0]);
    document.querySelectorAll('cui-breadcrumb .breadcrumb-item')[0].querySelectorAll('a')[1].remove();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logoutClicked() {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("_CSRF");
    localStorage.removeItem("userTitles");
    this._router.navigateByUrl('/login');
    // window.location.href = environment.siteProfileBaseUrl
  }
}
