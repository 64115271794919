import { Component, Inject, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
declare var $: any;

@Component({
  selector: 'ngx-msd-image-cropper',
  templateUrl: './msd-image-cropper.component.html',
  styleUrls: ['./msd-image-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgxMsdImageCropperComponent implements OnInit {

  @Input() ratio: number;   // Width / Height
  @Input() resultType: string = 'jpeg';   // jpeg or png
  @Input() cropSelectedArea: any = [];   // jpeg or png
  @Input() cropResultQuality: number = 0.8;   // Quality of the cropped image
  @Input() sourceResultQuality: number = 0.8;   // Quality of the Source image
  @Input() resultWidth: number;   // Cropped Image width
  @Input() resultHeight: number;   // Cropped Image height
  @Output() croppedOutput = new EventEmitter<any>();   // Emit the cropped and original Images Base64 URL

  @ViewChild('msdCropper') msdCropper: ElementRef;
  // @ViewChild('croppingImg') croppingImg: ElementRef;

  public imageSrc: string = '';
  private _cropperWidth: number;
  private _cropperHeight: number;
  private _cropperInitWidthHeight: number;
  private _outputImages: any = {
    croppedImage: '',
    sourceImage: '',
    croppedData: {}
  };
  private _inputOutputImageRatio: number;
  private _croppingCanvas = document.createElement('canvas');
  private _croppingCtx = this._croppingCanvas.getContext("2d");
  private _sourceCanvas = document.createElement('canvas');
  private _sourceCtx = this._sourceCanvas.getContext("2d");
  private _msdImageCropperContainerWidth: number;
  private _croppingImg: any;
  private _loader: any;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.resetRectBound();

      this._croppingImg.onload = () => {
        this.onImageLoad();
      };
    }, 200);
  }

  resetRectBound() {
    this._msdImageCropperContainerWidth = this.msdCropper.nativeElement.offsetWidth;
    this._croppingImg = this.msdCropper.nativeElement.children[0];
    this._loader = this.msdCropper.nativeElement.children[1];
    this._cropperHeight = this._msdImageCropperContainerWidth / this.ratio;
    this.msdCropper.nativeElement.style.height = this._cropperHeight + 'px';
  
    this._croppingCanvas.width = this.resultWidth;
    this._croppingCanvas.height = this.resultHeight;

    if(this.resultType.toLowerCase() != 'png') {
      this._croppingCtx.fillStyle = "#FFFFFF";
    }
  }

  @Input() set imageChangedEvent(src) {
    if($(this._croppingImg).data('Jcrop')) {
      $(this._croppingImg).data('Jcrop').destroy();
      $(this._croppingImg).attr('style', '');
    }
    
    if(src != '') {
      this.resetRectBound();
      this.imageSrc = src;
      if(typeof this._loader !== 'undefined') {
        this._loader.classList.remove('cropper-display-none');
      }
    }
    else {
      if(typeof this._loader !== 'undefined') {
        this._loader.classList.add('cropper-display-none');
      }
      this.imageSrc = '';
    }
  }

  generateSourceImage() {
    this._sourceCanvas.width = this._croppingImg.naturalWidth;
    this._sourceCanvas.height = this._croppingImg.naturalHeight;

    this._croppingImg.crossOrigin = "anonymous";
    
    this._sourceCtx.clearRect(0, 0, this._sourceCanvas.width, this._sourceCanvas.height);
    if(this.resultType.toLowerCase() != 'png') {
      this._sourceCtx.fillRect(0, 0, this._sourceCanvas.width, this._sourceCanvas.height);
    }
    this._sourceCtx.drawImage(
      this._croppingImg,
      0,
      0,
      this._sourceCanvas.width,
      this._sourceCanvas.height
    );
    this._outputImages.sourceImage = this._sourceCanvas.toDataURL(`image/${this.resultType.toLowerCase()}`, this.sourceResultQuality);
  }

  onImageLoad() {
    this.generateSourceImage();
    
    this._msdImageCropperContainerWidth = this.msdCropper.nativeElement.offsetWidth;

    if($(this._croppingImg).width() > $(this._croppingImg).height()) {
      $(this._croppingImg).css({
        'width': this._msdImageCropperContainerWidth,
        'height': 'auto'
      });
      this._cropperInitWidthHeight = this._cropperHeight;
    }
    else if($(this._croppingImg).width() < $(this._croppingImg).height()) {
      $(this._croppingImg).css({
        'width': 'auto',
        'height': this._cropperHeight
      });
      this._cropperInitWidthHeight = this._msdImageCropperContainerWidth;
    }
    else {
      $(this._croppingImg).css({
        'width': this._msdImageCropperContainerWidth,
        'height': 'auto'
      });
      this._cropperInitWidthHeight = this._cropperHeight;
    }

    setTimeout(() => {
      this.initJcrop();
    });
  }

  initJcrop() {
    let _that = this;
    $(this._croppingImg).Jcrop({
      boxWidth: this._msdImageCropperContainerWidth,
      boxHeight: this._cropperHeight,
      aspectRatio: this.ratio,
      allowSelect: false,
      bgColor: '#ffffff',
      onSelect: (e) => { this.onChangeOrSelect(e) },
      onChange: (e) => { this.onChangeOrSelect(e) }
    }, function() {
      setTimeout(() => {
        if(typeof _that.cropSelectedArea === 'undefined' || _that.cropSelectedArea.length < 4 || _that.cropSelectedArea[2] == 0 || _that.cropSelectedArea[3] == 0 || _that.cropSelectedArea[0] === '' || _that.cropSelectedArea[1] === '' || _that.cropSelectedArea[2] === '' || _that.cropSelectedArea[3] === ''
        ) {
          this.setSelect([15, 15, _that._cropperInitWidthHeight - 15, _that._cropperInitWidthHeight - 15]);
        }
        else {
          this.setSelect([
            _that.getValueFromPercentage(_that.cropSelectedArea[0]),
            _that.getValueFromPercentage(_that.cropSelectedArea[1]), 
            _that.getValueFromPercentage(_that.cropSelectedArea[2]) + _that.getValueFromPercentage(_that.cropSelectedArea[0]),
            _that.getValueFromPercentage(_that.cropSelectedArea[3]) + _that.getValueFromPercentage(_that.cropSelectedArea[1])
          ]);
        }
      });

      _that._cropperWidth = $(_that._croppingImg).width();
      _that._inputOutputImageRatio = _that._croppingImg.width / _that._cropperWidth;
      _that._loader.classList.add('cropper-display-none');
    });
  }

  onChangeOrSelect(c) {
    this._croppingCtx.clearRect(0, 0, this._croppingCanvas.width, this._croppingCanvas.height);
    if(this.resultType.toLowerCase() != 'png') {
      this._croppingCtx.fillRect(0, 0, this._croppingCanvas.width, this._croppingCanvas.height);
    }
    
    let x = c.x * this._inputOutputImageRatio,
        y = c.y * this._inputOutputImageRatio,
        w = c.w * this._inputOutputImageRatio,
        h = c.h * this._inputOutputImageRatio;

    this._croppingCtx.drawImage(
      this._croppingImg,
      x,
      y,
      w,
      h,
      0,
      0,
      this._croppingCanvas.width,
      this._croppingCanvas.height
    );
    this._outputImages.croppedImage = this._croppingCanvas.toDataURL(`image/${this.resultType.toLowerCase()}`, this.cropResultQuality);
    this._outputImages.croppedData = {
      x: this.getPercentageFromValue(c.x),
      y: this.getPercentageFromValue(c.y),
      w: this.getPercentageFromValue(c.w),
      h: this.getPercentageFromValue(c.h)
    };

    this.croppedOutput.emit(this._outputImages);
  }

  getPercentageFromValue(val) {
    return (val / this._msdImageCropperContainerWidth);
  }

  getValueFromPercentage(percentageVal) {
    return (this._msdImageCropperContainerWidth * percentageVal);
  }

}
